import {Component, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {MessageService} from './shared/messages/message.service';
import {Title} from '@angular/platform-browser';
import {PestDiseaseWeedService} from './shared/services/pest-disease-weed.service';
import {GlobalSearchService} from './shared/services/global-search.service';
import {TooltipPosition} from '@angular/material/tooltip';
import {CoreService} from './shared/services/core.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public messageService: MessageService,
    private globalSearchService: GlobalSearchService,
    private coreService: CoreService
  ) { }
  title = 'safeinputs';
  year = new Date().getFullYear();
  searchValue = new FormControl('');
  panelOpenState = false;

  ngOnInit(): void {
    // this.coreService.authenticate().subscribe(
    //   response => {
    //     const code = response.state;
    //   },
    //   error1 => {
    //     this.messageService.showMessage('', 'Error', error1);
    //   }
    // );
  }

  public searchGlobal(){
    if (this.searchValue.value !== ''){
      this.router.navigate([`/search/${this.searchValue.value}`]);
    }
  }
}
