import { Component, OnInit } from '@angular/core';
import {Agrochem} from '../../shared/models/agrochem';
import {ActivatedRoute} from '@angular/router';
import {AgrochemProductsService} from '../../shared/services/agrochem-products.service';
import {Title} from '@angular/platform-browser';
import {AppSettings} from '../../app.settings';
import {MessageService} from '../../shared/messages/message.service';
import {environment} from '../../../environments/environment';
import {CommercialOrganic} from '../../shared/models/commercial-organic';
import {PestsDiseaseWeed} from '../../shared/models/pests-disease-weed';


@Component({
  selector: 'app-agrochem-products-details',
  templateUrl: './agrochem-products-details.component.html',
  styleUrls: ['./agrochem-products-details.component.css']
})
export class AgrochemProductsDetailsComponent implements OnInit {

  baseUrl = environment.baseUrl;
  defaultImage = environment.defaultImage;
  id: any;
  loading = true;
  loaded = false;
  error = false;
  agrochem: Agrochem;
  commercialOrganicPdw: PestsDiseaseWeed[];
  commercialOrganicTotal = 0;
  gapPdw: PestsDiseaseWeed[];
  gapTotal = 0;
  homemadeOrganicPdw: PestsDiseaseWeed[];
  homemadeOrganicTotal = 0;
  constructor(
    private titleService: Title,
    private route: ActivatedRoute,
    private agrochemService: AgrochemProductsService,
    public messageService: MessageService,
  ) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.params.id;
    this.getItem();
  }

  private getItem() {
    // Gets agrochem produuct based on id
    this.agrochemService.findById(this.id).subscribe(response => {
        this.agrochem = response.data;
        this.loading = false;
        this.loaded = true;
        this.titleService.setTitle(this.agrochem.product_name);
        this.error = false;

        // Get pest, disease or weed's commercial organic products
        this.agrochemService.getCommercialOrganic(this.id).subscribe(responseCommercialOrganic => {
          this.commercialOrganicTotal = responseCommercialOrganic.total;
          this.commercialOrganicPdw = responseCommercialOrganic.data;
        });
        // Get pest, disease or weed's homemade organic products
        this.agrochemService.getHomemadeOrganic(this.id).subscribe(responseHomemadeOrganic => {
          this.homemadeOrganicTotal = responseHomemadeOrganic.total;
          this.homemadeOrganicPdw = responseHomemadeOrganic.data;
        });
        // Get pest, disease or weed's GAP items
        this.agrochemService.getGap(this.id).subscribe(responseGap => {
          this.gapTotal = responseGap.total;
          this.gapPdw = responseGap.data;
        });
      },
      error => {
        // Handle error if no agrochem product is found
        this.messageService.showMessage('info', 'Not found', 'Agrochem product not found');
        this.loading = false;
        this.error = true;
      });
  }
}
