import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-download-details',
  templateUrl: './download-details.component.html',
  styleUrls: ['./download-details.component.css']
})
export class DownloadDetailsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
