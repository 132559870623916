<div class="d-flex justify-content-center vh-100" *ngIf="loading">
  <div class="flex-center">
    <div class="spinner-grow text-light" style="width: 6rem; height: 6rem;"  role="status">
      <span class="sr-only">Loading...</span>
    </div>
    <span class="component-title orb">Loading...</span>
  </div>
</div>

<app-not-found *ngIf="error"></app-not-found>

<div *ngIf="loaded">
  <mdb-breadcrumb>
    <mdb-breadcrumb-item routerLink="/" class="blue-text">Home</mdb-breadcrumb-item>
    <mdb-breadcrumb-item routerLink="/active-ingredients" class="blue-text">Active Ingredients</mdb-breadcrumb-item>
    <mdb-breadcrumb-item class="active">{{activeIngredient.name}}</mdb-breadcrumb-item>
  </mdb-breadcrumb>

  <div class="container">

    <section class="component-content">
      <div class="row mb-1">
        <div class="col-sm  text-center">
          <span class="component-title">
          </span>
        </div>
      </div>


      <div class="p-2 px-md-5 pt-md-5 pb-md-1">
        <!-- Grid row -->
        <div class="row">
          <!-- Grid column -->
          <div class="col-md-12 display-5 mb-3">
              <span class="h1-responsive montserrat-font font-weight-bold">
                {{activeIngredient.name}}
              </span><br>
          </div>
          <!-- Grid column -->

          <!-- Grid column -->
          <div class="col-lg-6 col-md-12">
            <!-- Image -->
            <div class="mb-4">
              <div class="view overlay rounded z-depth-1-half mb-4">
<!--                <img class="img-fluid" src="{{baseUrl}}image/600/400/{{activeIngredient.image? activeIngredient.image: 'default.png'}}" alt="Sample image">-->
                <img class="details-image img-fluid"
                     width="100%"
                     height="auto"
                     [defaultImage]="defaultImage"
                     [errorImage]="errorImage"
                     offset="1500"
                     [lazyLoad]="baseUrl+'image/600/400/'+activeIngredient.image">
                <a>
                  <div class="mask rgba-white-slight waves-light" mdbWavesEffect></div>
                </a>
              </div>
            </div>
            <!-- Image -->
          </div>
          <!-- Grid column -->

          <!-- Grid column -->
<!--          <div class="col-lg-6 col-md-12">-->
<!--            <div class="">-->

<!--              &lt;!&ndash; Grid row &ndash;&gt;-->
<!--              <div class="row mb-3" *ngIf="pestsDiseaseWeed.local_names?.length > 0">-->
<!--                <div class="col-md-11 col-10">-->
<!--                  <h4 class="small-heading mb-1">Local Names</h4>-->
<!--                  <ul class="list-inline">-->
<!--                    <li class="list-inline-item dark-grey-text" *ngFor="let lcl_name of pestsDiseaseWeed.local_names">-->
<!--                      {{lcl_name.local_name}}-->
<!--                    </li>-->
<!--                  </ul>-->
<!--                </div>-->
<!--              </div>-->
<!--              &lt;!&ndash; Grid row &ndash;&gt;-->

<!--              &lt;!&ndash; Grid row &ndash;&gt;-->
<!--              <div class="row mb-3" *ngIf="pestsDiseaseWeed.crops?.length > 0">-->
<!--                <div class="col-md-11 col-10">-->
<!--                  <h4 class="small-heading mb-1">Crops Affected</h4>-->
<!--                  <ul class="list-inline">-->
<!--                    <li class="list-inline-item dark-grey-text" *ngFor="let crop of pestsDiseaseWeed.crops">-->
<!--                      <a routerLink="/crops/{{crop.id}}">{{crop.name}}</a>-->
<!--                    </li>-->
<!--                  </ul>-->
<!--                </div>-->
<!--              </div>-->
<!--              &lt;!&ndash; Grid row &ndash;&gt;-->

<!--              &lt;!&ndash; Grid row &ndash;&gt;-->
<!--              <div class="row mb-3" *ngIf="pestsDiseaseWeed.agrochem_products?.length > 0">-->
<!--                <div class="col-md-11 col-10">-->
<!--                  <h4 class="small-heading mb-1">Agrochemicals Used</h4>-->
<!--                  <ul class="list-inline">-->
<!--                    <li class="list-inline-item dark-grey-text" *ngFor="let agro_chem of pestsDiseaseWeed.agrochem_products">-->
<!--                      <a routerLink="/crops/{{agro_chem.id}}">{{agro_chem.product_name}}</a>-->
<!--                    </li>-->
<!--                  </ul>-->
<!--                </div>-->
<!--              </div>-->
<!--              &lt;!&ndash; Grid row &ndash;&gt;-->

<!--            </div>-->
<!--          </div>-->
          <!-- Grid column -->

          <!-- Grid Column -->


        </div>
        <!-- Grid row -->
      </div>

      <!-- Grid row -->
      <div class="row">
        <!-- Grid Column -->
        <div class="col-12">
          <table mdbTable>
            <thead>
            </thead>
            <tbody>
            <tr mdbTableCol *ngIf="activeIngredient.ache">
              <th><strong>Ache</strong></th>
              <td>{{activeIngredient.ache}}</td>
            </tr>
            <tr mdbTableCol *ngIf="activeIngredient.who_classification">
              <th><strong>WHO Classification</strong></th>
              <td>
                <mdb-badge color="{{activeIngredient.who_classification | whoClassColor}}">
                  <span class="font-weight-600">{{activeIngredient.who_classification | whoClassification}}</span>
                </mdb-badge></td>
            </tr>
            </tbody>
          </table>
        </div>
        <!-- Grid Column -->

        <!-- Grid Column -->
        <div class="col-12 description" *ngIf="activeIngredient.carcinogenicity">
          <div class="soft-shadow-upper-2">
            <div class="soft-shadow-lower-2">
              <div class="item-section">
                <!-- Title -->
                <div class="item-section-title">
                  <h2 class="category-title mt-5 mb-1"><strong class="">Carcinogenicity</strong></h2>
                </div>
                <!-- Title -->
                <div class="item-section-content">
                  <!-- Content -->
                  <div class="ck-content dark-grey-text ">
                    <blockquote>This is a substance that promotes the formation of cancer</blockquote>
                  </div>
                  <div class="description-paragraph dark-grey-text ">
                    <mdb-badge color="{{activeIngredient.carcinogenicity | carcinogenicityColor}}">
                      <span class="font-weight-600 {{activeIngredient.carcinogenicity | carcinogenicityColor}}">{{activeIngredient.carcinogenicity}}</span>
                    </mdb-badge>
                  </div>
                  <div class="ck-content dark-grey-text " [innerHTML]="activeIngredient.carcinogenicity | carcinogenicity"></div>
                  <!-- Content -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Grid Column -->

        <!-- Grid Column -->
        <div class="col-12 description" *ngIf="activeIngredient.mutagenicity">
          <div class="soft-shadow-upper-2">
            <div class="soft-shadow-lower-2">
              <div class="item-section">
                <!-- Title -->
                <div class="item-section-title">
                  <h2 class="category-title mt-5 mb-1"><strong class="">Mutagenicity</strong></h2>
                </div>
                <!-- Title -->
                <div class="item-section-content">
                  <!-- Content -->
                  <div class="ck-content dark-grey-text ">
                    <blockquote>Ability to damage the genetic information within a cell causing mutations.</blockquote>
                  </div>
                  <div class="description-paragraph dark-grey-text ">
                    <mdb-badge color="{{activeIngredient.mutagenicity| mutagenicityColor}}">
                      <span class="font-weight-600 {{activeIngredient.mutagenicity| mutagenicityColor}}">{{activeIngredient.mutagenicity}}</span>
                    </mdb-badge>
                  </div>
                  <div class="ck-content dark-grey-text " [innerHTML]="activeIngredient.mutagenicity | mutagenicity"></div>
                  <!-- Content -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Grid Column -->

        <!-- Grid Column -->
        <div class="col-12 description" *ngIf="activeIngredient.edc">
          <div class="soft-shadow-upper-2">
            <div class="soft-shadow-lower-2">
              <div class="item-section">
                <!-- Title -->
                <div class="item-section-title">
                  <h2 class="category-title mt-5 mb-1"><strong class="">EDC(Endocrine Disrupting Chemical)</strong></h2>
                </div>
                <!-- Title -->
                <div class="item-section-content">
                  <!-- Content -->
                  <div class="ck-content dark-grey-text ">
                    <blockquote>This is a substance that interferes with the normal function of your body’s endocrine/ hormone system. EDCs have been suspected to be associated with altered reproductive function in men and women, increased incidences of breast cancer, abnormal growth patterns and neurodevelopmental delays in children as well as changes in immune function.</blockquote>
                  </div>
                  <div class="description-paragraph dark-grey-text ">
                    <mdb-badge color="{{activeIngredient.edc| edcColor}}">
                      <span class="font-weight-600 {{activeIngredient.edc| edcColor}}">{{activeIngredient.edc}}</span>
                    </mdb-badge>
                  </div>
                  <div class="ck-content dark-grey-text " [innerHTML]="activeIngredient.edc| edc"></div>
                  <!-- Content -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Grid Column -->

        <!-- Grid Column -->
        <div class="col-12 description" *ngIf="activeIngredient.reproduction">
          <div class="soft-shadow-upper-2">
            <div class="soft-shadow-lower-2">
              <div class="item-section">
                <!-- Title -->
                <div class="item-section-title">
                  <h2 class="category-title mt-5 mb-1"><strong class="">Reproduction</strong></h2>
                </div>
                <!-- Title -->
                <div class="item-section-content">
                  <!-- Content -->
                  <div class="ck-content dark-grey-text ">
                    <blockquote>Affecting the reproductive system. Reduces the ability to conceive and bear children.</blockquote>
                  </div>
                  <div class="description-paragraph dark-grey-text ">
                    <mdb-badge color="{{activeIngredient.reproduction| reproductionColor}}">
                      <span class="font-weight-600 {{activeIngredient.reproduction| reproductionColor}}">{{activeIngredient.reproduction}}</span>
                    </mdb-badge>
                  </div>
                  <div class="ck-content dark-grey-text " [innerHTML]="activeIngredient.reproduction | reproduction"></div>
                  <!-- Content -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Grid Column -->

        <!-- Grid Column -->
        <div class="col-12 description" *ngIf="activeIngredient.neurotoxicant">
          <div class="soft-shadow-upper-2">
            <div class="soft-shadow-lower-2">
              <div class="item-section">
                <!-- Title -->
                <div class="item-section-title">
                  <h2 class="category-title mt-5 mb-1"><strong class="">Neurotoxicant</strong></h2>
                </div>
                <!-- Title -->
                <div class="item-section-content">
                  <!-- Content -->
                  <div class="ck-content dark-grey-text ">
                    <blockquote>Poisonous substance that acts on the nervous system and disrupts the normal function of nerve cells.</blockquote>
                  </div>
                  <div class="description-paragraph dark-grey-text ">
                    <mdb-badge color="{{activeIngredient.neurotoxicant| neurotoxicantColor}}">
                      <span class="font-weight-600 {{activeIngredient.neurotoxicant| neurotoxicantColor}}">{{activeIngredient.neurotoxicant}}</span>
                    </mdb-badge>
                  </div>
                  <div class="ck-content dark-grey-text " [innerHTML]="activeIngredient.neurotoxicant | neutoroxicant"></div>
                  <!-- Content -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Grid Column -->

        <!-- Grid Column -->
        <div class="col-12 description" *ngIf="activeIngredient.eu_approved">
          <div class="soft-shadow-upper-2">
            <div class="soft-shadow-lower-2">
              <div class="item-section">
                <!-- Title -->
                <div class="item-section-title">
                  <h2 class="category-title mt-5 mb-1"><strong class="">EU Approved</strong></h2>
                </div>
                <!-- Title -->
                <div class="item-section-content">
                  <!-- Content -->
                  <div class="description-paragraph dark-grey-text ">
                    <mdb-badge color="{{activeIngredient.eu_approved| euApprovedColor}}">
                      <span class="font-weight-600 {{activeIngredient.eu_approved| euApprovedColor}}">{{activeIngredient.eu_approved}}</span>
                    </mdb-badge>
                  </div>
                  <div class="ck-content dark-grey-text " [innerHTML]="activeIngredient.eu_approved | euApproved"></div>
                  <!-- Content -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Grid Column -->

        <!-- Grid Column -->
        <div class="col-12 effect-on-crops" *ngIf="activeIngredient.potential_harm">
          <div class="soft-shadow-upper-2">
            <div class="soft-shadow-lower-2">
              <div class="item-section">
                <!-- Title -->
                <div class="item-section-title">
                  <h2 class="category-title mt-5 mb-1"><strong class="">Potential Harm</strong></h2>
                </div>
                <!-- Title -->
                <div class="item-section-content">
                  <!-- Content -->
                  <div class="ck-content dark-grey-text " [innerHTML]="activeIngredient.potential_harm | unescape"></div>
                  <!-- Content -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Grid Column -->

        <!-- Grid Column -->
        <div class="col-12 effect-on-crops" *ngIf="activeIngredient.aquatic || activeIngredient.bees || activeIngredient.earthworm || activeIngredient.birds || activeIngredient.leachability">
          <div class="soft-shadow-upper-2">
            <div class="soft-shadow-lower-2">
              <div class="item-section">
                <!-- Title -->
                <div class="item-section-title">
                  <h2 class="category-title mt-5 mb-1"><strong class="">Effects</strong></h2>
                </div>
                <!-- Title -->
                <div class="item-section-content">
                  <!-- Content -->
                  <!-- Description -->
                  <div *ngIf="activeIngredient.aquatic">
                    <div class="description-paragraph dark-grey-text font-weight-700 ">
                        <span class="description-sub-heading-2">
                          On Aquatic Organisms
                        </span>
                    </div>
                    <div class="description-paragraph dark-grey-text " *ngIf="activeIngredient.aquatic">
                      <mdb-badge color="{{activeIngredient.aquatic | aquaticColor}}">
                        <span class="font-weight-600">{{activeIngredient.aquatic}} mg/l</span>
                      </mdb-badge>
                    </div>
                    <div *ngIf="activeIngredient.aquatic_desc">
                      <div class="ck-content dark-grey-text" [innerHTML]="activeIngredient.aquatic_desc | unescape"></div>
                    </div>
                    <br>
                    <br>
                  </div>
                  <!-- Description -->
                  <!-- Description -->
                  <div *ngIf="activeIngredient.bees">
                    <div class="description-paragraph dark-grey-text font-weight-700 ">
                        <span class="description-sub-heading-2">
                          On Bees
                        </span>
                    </div>
                    <div class="description-paragraph dark-grey-text " *ngIf="activeIngredient.bees">
                      <mdb-badge color="{{activeIngredient.bees | beesColor}}">
                        <span class="font-weight-600">{{activeIngredient.bees}} yg/bee</span>
                      </mdb-badge>
                    </div>
                    <div *ngIf="activeIngredient.bees_desc">
                      <div class="ck-content dark-grey-text" [innerHTML]="activeIngredient.bees_desc| unescape"></div>
                    </div>
                    <br>
                    <br>
                  </div>
                  <!-- Description -->
                  <!-- Description -->
                  <div *ngIf="activeIngredient.earthworm">
                    <div class="description-paragraph dark-grey-text font-weight-700 ">
                        <span class="description-sub-heading-2">
                          On Earthworms
                        </span>
                    </div>
                    <div class="description-paragraph dark-grey-text " *ngIf="activeIngredient.earthworm">
                      <mdb-badge color="{{activeIngredient.earthworm | earthwormColor}}">
                        <span class="font-weight-600">{{activeIngredient.earthworm}} mg/kg</span>
                      </mdb-badge>
                    </div>
                    <div *ngIf="activeIngredient.earthworm_desc">
                      <div class="ck-content dark-grey-text" [innerHTML]="activeIngredient.earthworm_desc| unescape"></div>
                    </div>
                    <br>
                    <br>
                  </div>
                  <!-- Description -->
                  <!-- Description -->
                  <div *ngIf="activeIngredient.birds">
                    <div class="description-paragraph dark-grey-text font-weight-700 ">
                        <span class="description-sub-heading-2">
                          On Birds
                        </span>
                    </div>
                    <div class="description-paragraph dark-grey-text " *ngIf="activeIngredient.birds">
                      <mdb-badge color="{{activeIngredient.birds | birdsColor}}">
                        <span class="font-weight-600">{{activeIngredient.birds}} mg/kg</span>
                      </mdb-badge>
                    </div>
                    <div *ngIf="activeIngredient.birds_desc">
                      <div class="ck-content dark-grey-text" [innerHTML]="activeIngredient.birds_desc| unescape"></div>
                    </div>
                    <br>
                    <br>
                  </div>
                  <!-- Description -->
                  <!-- Description -->
                  <div *ngIf="activeIngredient.leachability">
                    <div class="description-paragraph dark-grey-text font-weight-700 ">
                        <span class="description-sub-heading-2">
                          Leachability
                        </span>
                    </div>
                    <div class="description-paragraph dark-grey-text " *ngIf="activeIngredient.leachability">
                      <mdb-badge color="{{activeIngredient.leachability | leachabilityColor}}">
                        <span class="font-weight-600">{{activeIngredient.leachability}}</span>
                      </mdb-badge>
                    </div>
                    <div *ngIf="activeIngredient.leachability_desc">
                      <div class="ck-content dark-grey-text" [innerHTML]="activeIngredient.leachability_desc| unescape"></div>
                    </div>
                    <br>
                    <br>
                  </div>
                  <!-- Description -->
                  <!-- Content -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Grid Column -->

        <!-- Grid Column -->
        <div class="col-12 gap" *ngIf="gapTotal > 0">
          <div class="soft-shadow-upper-2">
            <div class="soft-shadow-lower-2">
              <div class="item-section">
                <!-- Title -->
                <div class="item-section-title">
                  <h2 class="category-title mt-5 mb-1"><strong class="">Alternative Good Agricultural Practices</strong></h2>
                </div>
                <!-- Title -->
                <div class="item-section-content">
                  <!-- Content -->
                  <div style="display: -webkit-box; display: flex">
                    <div style="-webkit-box-flex:1; flex: 1">
                      <div class="accordion-tabs" *ngIf="gapPdw?.length > 0">
                        <div class="accordion-tab" *ngFor="let item of gapPdw">
                          <div *ngIf="item.gap?.length > 0">
                            <input type="checkbox" id="gap{{item.id}}"/>
                            <label class="accordion-tab-label" for="gap{{item.id}}">For {{item.name}}</label>
                            <div class="accordion-tab-content">
                              <ul class="list-inline">
                                <li class="list-inline-item dark-grey-text minor-items-content" *ngFor="let gap of item.gap">
                                  <div class="accordion-list-item-wrapper">
                                    <a routerLink="/gap/details/{{gap.id}}">{{gap.name}}</a>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Content -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Grid Column -->

        <!-- Grid Column -->
        <div class="col-12 homemade-organic" *ngIf="homemadeOrganicTotal > 0">
          <div class="soft-shadow-upper-2">
            <div class="soft-shadow-lower-2">
              <div class="item-section">
                <!-- Title -->
                <div class="item-section-title">
                  <h2 class="category-title mt-5 mb-1"><strong class="">Alternative Homemade Solutions</strong></h2>
                </div>
                <!-- Title -->
                <div class="item-section-content">
                  <!-- Content -->
                  <div style="display: -webkit-box; display: flex">
                    <div style="-webkit-box-flex:1; flex: 1">
                      <div class="accordion-tabs" *ngIf="homemadeOrganicPdw?.length > 0">
                        <div class="accordion-tab" *ngFor="let item of homemadeOrganicPdw">
                          <div *ngIf="item.homemade_organic?.length > 0">
                            <input type="checkbox" id="hmo{{item.id}}"/>
                            <label class="accordion-tab-label" for="hmo{{item.id}}">For {{item.name}}</label>
                            <div class="accordion-tab-content">
                              <ul class="list-inline">
                                <li class="list-inline-item dark-grey-text minor-items-content" *ngFor="let homemadeOrganic of item.homemade_organic">
                                  <div class="accordion-list-item-wrapper">
                                    <a routerLink="/homemade-organic/details/{{homemadeOrganic.id}}">{{homemadeOrganic.name}}</a>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Content -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Grid Column -->

        <!-- Grid Column -->
        <div class="col-12 commercial-organic" *ngIf="commercialOrganicTotal > 0">
          <div class="soft-shadow-upper-2">
            <div class="soft-shadow-lower-2">
              <div class="item-section">
                <!-- Title -->
                <div class="item-section-title">
                  <h2 class="category-title mt-5 mb-1"><strong class="">Alternative Offshelf Organic Products</strong></h2>
                </div>
                <!-- Title -->
                <div class="item-section-content">
                  <!-- Content -->
                  <div style="display: -webkit-box; display: flex">
                    <div style="-webkit-box-flex:1; flex: 1">
                      <div class="accordion-tabs" *ngIf="commercialOrganicPdw?.length > 0">
                        <div class="accordion-tab" *ngFor="let item of commercialOrganicPdw">
                          <div *ngIf="item.commercial_organic?.length > 0">
                            <input type="checkbox" id="cmo{{item.id}}"/>
                            <label class="accordion-tab-label" for="cmo{{item.id}}">For {{item.name}}</label>
                            <div class="accordion-tab-content">
                              <ul class="list-inline">
                                <li class="list-inline-item dark-grey-text minor-items-content" *ngFor="let commercialOrganic of item.commercial_organic">
                                  <div class="accordion-list-item-wrapper">
                                    <a routerLink="/commercial-organic/details/{{commercialOrganic.id}}">{{commercialOrganic.name}}</a>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Content -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Grid Column -->

        <!-- Grid Column -->
<!--        <div class="col-12 less-toxic-agrochem" *ngIf="nonToxicAgrochems?.length > 0; else noLessTOxicAgrochems">-->
<!--          <div class="soft-shadow-upper">-->
<!--            <div class="soft-shadow-lower">-->
<!--              <div class="item-section">-->
<!--                &lt;!&ndash; Title &ndash;&gt;-->
<!--                <div class="item-section-title">-->
<!--                  <h2 class="category-title mt-5 mb-1"><strong class="">Less Toxic Alternative Agrochemicals</strong></h2>-->
<!--                </div>-->
<!--                &lt;!&ndash; Title &ndash;&gt;-->
<!--                <div class="item-section-content">-->
<!--                  &lt;!&ndash; Content &ndash;&gt;-->
<!--                  <div class="row">-->
<!--                    &lt;!&ndash;        Layyout for small&ndash;&gt;-->
<!--                    <div class=" d-block d-md-none col-sm-12 mx-3 my-1 p-2 view" *ngFor="let item of nonToxicAgrochems">-->
<!--                      <div class="media item" routerLink="/agrochem/details/{{item.id}}">-->
<!--                        <div class="card-img-container">-->
<!--                          <img class="details-image img-fluid"-->
<!--                               width="70px"-->
<!--                               height="70px"-->
<!--                               [defaultImage]="defaultImage"-->
<!--                               [lazyLoad]="baseUrl+'image/70/70/'+item.image">-->
<!--                        </div>-->
<!--                        &lt;!&ndash;            <img src="{{baseUrl}}image/100/67/{{item.image? item.image: 'default.png'}}" class="mr-3" alt="...">&ndash;&gt;-->
<!--                        <div class="media-body d-flex align-items-center" style="height: 70px; margin-left:5px">-->
<!--                          <div class="card-title-container"><a class="card-title" routerLink="/agrochem/details/{{item.id}}">{{item.product_name}}</a></div>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                    &lt;!&ndash;        Layyout for small&ndash;&gt;-->
<!--                    &lt;!&ndash;        Layout for medium and Larger&ndash;&gt;-->
<!--                    <div class="d-none d-md-block col-xs-6 col-sm-6 col-md-4 col-lg-3 pt-md-3 " *ngFor="let item of nonToxicAgrochems">-->
<!--                      <div  routerLink="/agrochem/details/{{item.id}}" class="item">-->
<!--                        <div class="view overlay zoom">-->
<!--                          <mdb-card class="shadow-sm">-->
<!--                            &lt;!&ndash;Card image&ndash;&gt;-->
<!--                            <div class="card-img-container">-->
<!--                              <img class="details-image img-fluid"-->
<!--                                   width="100%"-->
<!--                                   height="auto"-->
<!--                                   [defaultImage]="defaultImage"-->
<!--                                   [lazyLoad]="baseUrl+'image/245/160/'+item.image"-->
<!--                                   alt="Sample image"/>-->
<!--                            </div>-->
<!--                            &lt;!&ndash;                <mdb-card-img src="{{baseUrl}}image/245/160/{{item.image? item.image: 'default.png'}}" alt="Image Not Found"></mdb-card-img>&ndash;&gt;-->
<!--                            &lt;!&ndash;Card content&ndash;&gt;-->
<!--                            &lt;!&ndash;                                  <mdb-card-body>&ndash;&gt;-->
<!--                            &lt;!&ndash;Title&ndash;&gt;-->
<!--                            <mdb-card-title>-->
<!--                              <div class="card-title-container"><a class="card-title" routerLink="/agrochem/details/{{item.id}}">{{item.product_name}}</a></div>-->
<!--                            </mdb-card-title>-->
<!--                            &lt;!&ndash;                                  </mdb-card-body>&ndash;&gt;-->
<!--                          </mdb-card>-->
<!--                          <div class="mask flex-center rgba-red-light" *ngIf="item.toxic"></div>-->
<!--                          <div class="mask flex-center rgba-red-slight" *ngIf="!item.toxic"></div>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                    &lt;!&ndash;        Layout for medium and Larger&ndash;&gt;-->
<!--                  </div>-->
<!--                  &lt;!&ndash; Content &ndash;&gt;-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
        <!-- Description -->
<!--        <div class="col-12">-->
<!--          <ng-template #noLessTOxicAgrochems>-->
<!--            <div class="col-12 default">-->
<!--              <div class="soft-shadow-upper">-->
<!--                <div class="soft-shadow-lower">-->
<!--                  <div class="item-section-title">-->
<!--                    <h2 class="category-title mt-5 mb-1">-->
<!--                      <strong>-->
<!--                        No Lesser toxic options have been listed for {{activeIngredient.name}}-->
<!--                      </strong>-->
<!--                    </h2>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </ng-template>-->
<!--        </div>-->
        <!-- Grid Column -->

        <!-- Grid Column -->
<!--        <div class="col-12 agrochem" *ngIf="toxicAgrochems?.length > 0">-->
<!--          <div class="soft-shadow-upper">-->
<!--            <div class="soft-shadow-lower">-->
<!--              <div class="item-section">-->
<!--                &lt;!&ndash; Title &ndash;&gt;-->
<!--                <div class="item-section-title">-->
<!--                  <h2 class="category-title mt-5 mb-1"><strong>Agrochemicals Containing {{activeIngredient.name}}</strong></h2>-->
<!--                </div>-->
<!--                &lt;!&ndash; Title &ndash;&gt;-->
<!--                <div class="item-section-content">-->
<!--                  &lt;!&ndash; Content &ndash;&gt;-->
<!--                  <div class="row">-->
<!--                    &lt;!&ndash;        Layyout for small&ndash;&gt;-->
<!--                    <div class=" d-block d-md-none col-sm-12 mx-3 my-1 p-2 view" *ngFor="let item of toxicAgrochems">-->
<!--                      <div class="media item" routerLink="/agrochem/details/{{item.id}}">-->
<!--                        <div class="card-img-container">-->
<!--                          <img class="details-image img-fluid"-->
<!--                               width="70px"-->
<!--                               height="70px"-->
<!--                               [defaultImage]="defaultImage"-->
<!--                               [lazyLoad]="baseUrl+'image/70/70/'+item.image">-->
<!--                        </div>-->
<!--                        &lt;!&ndash;            <img src="{{baseUrl}}image/100/67/{{item.image? item.image: 'default.png'}}" class="mr-3" alt="...">&ndash;&gt;-->
<!--                        <div class="media-body d-flex align-items-center" style="height: 70px; margin-left:5px">-->
<!--                          <div class="card-title-container"><a class="card-title" routerLink="/agrochem/details/{{item.id}}">{{item.product_name}}</a></div>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                    &lt;!&ndash;        Layyout for small&ndash;&gt;-->
<!--                    &lt;!&ndash;        Layout for medium and Larger&ndash;&gt;-->
<!--                    <div class="d-none d-md-block col-xs-6 col-sm-6 col-md-4 col-lg-3 pt-md-3 " *ngFor="let item of toxicAgrochems">-->
<!--                      <div  routerLink="/agrochem/details/{{item.id}}" class="item">-->
<!--                        <div class="view overlay zoom">-->
<!--                          <mdb-card class="shadow-sm">-->
<!--                            &lt;!&ndash;Card image&ndash;&gt;-->
<!--                            <div class="card-img-container">-->
<!--                              <img class="details-image img-fluid"-->
<!--                                   width="100%"-->
<!--                                   height="auto"-->
<!--                                   [defaultImage]="defaultImage"-->
<!--                                   [lazyLoad]="baseUrl+'image/245/160/'+item.image"-->
<!--                                   alt="Sample image"/>-->
<!--                            </div>-->
<!--                            &lt;!&ndash;                <mdb-card-img src="{{baseUrl}}image/245/160/{{item.image? item.image: 'default.png'}}" alt="Image Not Found"></mdb-card-img>&ndash;&gt;-->
<!--                            &lt;!&ndash;Card content&ndash;&gt;-->
<!--                            &lt;!&ndash;                                  <mdb-card-body>&ndash;&gt;-->
<!--                            &lt;!&ndash;Title&ndash;&gt;-->
<!--                            <mdb-card-title>-->
<!--                              <div class="card-title-container"><a class="card-title" routerLink="/agrochem/details/{{item.id}}">{{item.product_name}}</a></div>-->
<!--                            </mdb-card-title>-->
<!--                            &lt;!&ndash;                                  </mdb-card-body>&ndash;&gt;-->
<!--                          </mdb-card>-->
<!--                          <div class="mask flex-center rgba-red-light" *ngIf="item.toxic"></div>-->
<!--                          <div class="mask flex-center rgba-red-slight" *ngIf="!item.toxic"></div>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                    &lt;!&ndash;        Layout for medium and Larger&ndash;&gt;-->
<!--                  </div>-->
<!--                  &lt;!&ndash; Content &ndash;&gt;-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
        <!-- Grid Column -->

      </div>
      <!-- Grid row -->

    </section>
  </div>
</div>

