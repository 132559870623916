import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'neutoroxicant'
})
export class NeutoroxicantPipe implements PipeTransform {

  transform(value: string): string {
    switch (value){
      case 'Yes':
        return 'Studies have linked this product with neurological disruption.';
      case 'No':
        return 'No studies have linked this substance with neurological disruption.';
      case 'Possible':
        return 'Although no direct causation neurological disruption has been established , it cannot be ruled out either. Exercise caution.';
      case 'No Data':
        return 'No studies exist on this substance in relation to neurological disruption. Precautionary principle applies. Please avoid using if in doubt.';
      default:
        return '';
    }
  }

}
