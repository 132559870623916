import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {catchError, retry} from 'rxjs/operators';
import {throwError} from 'rxjs';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'text/html',
    'Access-Control-Allow-Origin': '*'
  })
};

@Injectable({
  providedIn: 'root'
})
export class SummaryService {

  constructor(private http: HttpClient) { }

  // Get total of agrochem products that match an active ingredient criteria
  getSummaryCountAgrochemByActiveIngredient(category = '', value = ''){
    const params = new HttpParams()
      .set(category, value)
    ;
    const url = `${environment.baseUrl}agrochem/summary/count/active_ingredients`;
    return this.http.get<any>(url, {params})
      .pipe(
        retry(3), // retry a failed request up to 3 times
        catchError(this.handleError)
      );
  }

  // Get total of active ingredient items
  getSummaryCountActiveIngredients(searchValue = ''){
    const params = new HttpParams();
    const url = `${environment.baseUrl}active_ingredients/summary/count/${searchValue}`;
    return this.http.get<any>(url, {params})
      .pipe(
        retry(3), // retry a failed request up to 3 times
        catchError(this.handleError)
      );
  }

  // Get total of agrochem items
  getSummaryCountAgrochem(searchValue = ''){
    const params = new HttpParams();
    const url = `${environment.baseUrl}agrochem/summary/count/${searchValue}`;
    return this.http.get<any>(url, {params})
      .pipe(
        retry(3), // retry a failed request up to 3 times
        catchError(this.handleError)
      );
  }

  // Get total of commercial organic items
  getSummaryCountCommercialOrganic(searchValue = ''){
    const params = new HttpParams();
    const url = `${environment.baseUrl}commercial_organic/summary/count/${searchValue}`;
    return this.http.get<any>(url, {params})
      .pipe(
        retry(3), // retry a failed request up to 3 times
        catchError(this.handleError)
      );
  }

  // Get total of commercial organic items
  getSummaryCountControlMethods(searchValue = ''){
    const params = new HttpParams();
    const url = `${environment.baseUrl}control_methods/summary/count/${searchValue}`;
    return this.http.get<any>(url, {params})
      .pipe(
        retry(3), // retry a failed request up to 3 times
        catchError(this.handleError)
      );
  }

  // Get total of crops items
  getSummaryCountCrops(searchValue = ''){
    const params = new HttpParams();
    const url = `${environment.baseUrl}crops/summary/count/${searchValue}`;
    return this.http.get<any>(url, {params})
      .pipe(
        retry(3), // retry a failed request up to 3 times
        catchError(this.handleError)
      );
  }

  // Get total of gap items
  getSummaryCountGap(searchValue = ''){
    const params = new HttpParams();
    const url = `${environment.baseUrl}gap/summary/count/${searchValue}`;
    return this.http.get<any>(url, {params})
      .pipe(
        retry(3), // retry a failed request up to 3 times
        catchError(this.handleError)
      );
  }

  // Get total of homemade organic items
  getSummaryCountHomemadeOrganic(searchValue = ''){
    const params = new HttpParams();
    const url = `${environment.baseUrl}homemade_organic/summary/count/${searchValue}`;
    return this.http.get<any>(url, {params})
      .pipe(
        retry(3), // retry a failed request up to 3 times
        catchError(this.handleError)
      );
  }

  // Get total of local name items
  getSummaryCountLocalNames(searchValue = ''){
    const params = new HttpParams();
    const url = `${environment.baseUrl}local_names/summary/count/${searchValue}`;
    return this.http.get<any>(url, {params})
      .pipe(
        retry(3), // retry a failed request up to 3 times
        catchError(this.handleError)
      );
  }

  // Get total of pest disease weed items
  getSummaryCountPestsDiseaseWeed(searchValue = ''){
    const params = new HttpParams();
    const url = `${environment.baseUrl}pdw/summary/count/${searchValue}`;
    return this.http.get<any>(url, {params})
      .pipe(
        retry(3), // retry a failed request up to 3 times
        catchError(this.handleError)
      );
  }

  // Error handling
  private handleError(error: HttpErrorResponse): any {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error.message}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  }
}
