import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isEmpty'
})
export class IsEmptyPipe implements PipeTransform {

  transform(value: any): boolean {
    if (value instanceof  Array){
      if (value.length > 0){
        return true;
      }
    }else if (
      value === null ||
      value === '' ||
      value === ' '
    ) {
      return true;
    }else{
      return false;
    }
  }

}
