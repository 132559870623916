<section class="text-center" id="">

  <div class="container">

    <div class="error-404">



      <h1 class="emoticon" data-aos="fade-right" data-aos-once="true"  data-aos-duration="1000" >{{emote}}</h1>

      <h3 class="mb-5" data-aos="fade-right" data-aos-once="true"  data-aos-duration="1000">The item(s) you requested cannot be found</h3>



      <a routerLink="/home">Back To Home</a>





    </div>

    <div class="row">



    </div>

  </div>

</section>
