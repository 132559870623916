<div>
  <mdb-breadcrumb>
    <mdb-breadcrumb-item routerLink="/" class="blue-text">Home</mdb-breadcrumb-item>
    <mdb-breadcrumb-item class="active">{{title}}</mdb-breadcrumb-item>
  </mdb-breadcrumb>
  <div class="container">
    <div class="row mb-1">
      <div class="col-sm">
        <span class="component-title">
          {{title}}
        </span>
      </div>
    </div>
    <div class="row mb-1">
      <div class="col-sm-11">
        <span class="content-1 text-justify" *ngIf="category == 'cultural'">
          Cultural pest control methods involve the use of various methodologies or techniques aimed at providing an unfavorable condition for the pests. It involves simple modifications of a pest's environment or habitat and the manipulation of the agroecosystem in order to make the cropping system less friendly to the establishment and proliferation of pest populations. The practices lead to reduced pest establishment, reproduction, dispersal, and survival. The practices include; use of resistant varieties, crop rotation, crop refuse destruction, variation in time of planting or harvesting, pruning or thinning and proper spacing, crop sanitation and Water management planting of trap crops
          <br><br>
        </span>
        <span class="content-1 text-justify" *ngIf="category == 'physical'">
          Physical Pest Control methods involve modification of physical factors in the environment to minimize or prevent pest problems. The method involves getting rid of insects and small rodents by killing, removing, or setting up barriers that will prevent further destruction of one's plants. It also involves physical detection of the pest presence and using different ways to evacuate them from the farm. The methods comprises of techniques that limit pest access to the crop/commodity, induce behavioral changes, or cause direct pest damage/death Methods of physical and mechanical pest control include: hand picking and destruction, exclusion by barriers, use of traps methods, application of heat - Hot water treatment, exposing of infested grain to sun, manipulation of moisture e.g. reduction of moisture content of grains helps to prevent from the attack of stored grain pests and use of light traps.
          <br><br>
        </span>
        <span class="content-1 text-justify" *ngIf="category == 'prevention'">
          Prevention methods involve deterrence and management techniques that make pests not to come to the farm, store or home compound. These involves removing the elements that pests need to survive some of this practices include crop rotation, use of resistant varieties, proper drainage, developing healthy soil and suitable irrigation regime among others.
          <br><br>
        </span>
      </div>
    </div>
    <div class="component-content shadow-md">
      <form class="">
        <div class="row">
          <div class="col-md-12">
            <div class="input-group">
              <input type="text" class=" col-sm-2 col-md-8 mb-2" placeholder="Filter" style="font-size:12px; border-bottom-left-radius:0; border-left:none; border-right:none; border-top:none; border-bottom:1px solid rgba(79,79,79,0.2);" aria-label="Filter" (keyup.enter)="search()" [(ngModel)]="searchValue" [ngModelOptions]="{standalone: true}">
              <div class="input-group-append">
                <div class="input-group">
                  <select class="browser-default custom-select mb-2" style="font-size:12px; border-bottom-left-radius:0; border-top-left-radius:0; border-left:1px solid rgba(79,79,79,0.2); border-right:1px solid rgba(79,79,79,0.2); border-top:1px solid rgba(79,79,79,0.2);" (change)="itemsPerPageChanged()" [(ngModel)]="perPage" [ngModelOptions]="{standalone: true}">
                    <option value="" selected><strong>Items Per Page</strong></option>
                    <option value="4">4</option>
                    <option value="12">12</option>
                    <option value="20">20</option>
                    <option value="48">48</option>
                    <option value="100">100</option>
                  </select>
                  <select class="browser-default custom-select mb-2" style="font-size:12px; border-bottom-left-radius:0; border-bottom-right-radius:0; border-left:1px solid rgba(79,79,79,0.2); border-right:1px solid rgba(79,79,79,0.2); border-top:1px solid rgba(79,79,79,0.2);" (change)="sortOrderChanged()" [(ngModel)]="ordering" [ngModelOptions]="{standalone: true}">
                    <option value="#" selected><strong>Order By</strong></option>
                    <option value="name">Name - Asc</option>
                    <option value="!name">Name - Desc</option>
                    <option value="!id">Most Recent</option>
                    <option value="id">Earliest</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
        </div>
      </form>



      <!--      Loader-->
      <div class="d-flex justify-content-center vh-70" *ngIf="loading">
        <div class="flex-center">
          <div class="spinner-grow text-light" style="width: 6rem; height: 6rem;"  role="status">
            <span class="sr-only">Loading...</span>
          </div>
          <span class="component-title orb">Loading...</span>
        </div>
      </div>


      <app-not-found *ngIf="error"></app-not-found>

      <div *ngIf="loaded">

        <!--  RESULTS COUNT-->
        <div class="row mt-1 mb-1">
          <div class="col-12">
            <span class="results-count" *ngIf="totalRecords"><span class="font-weight-800">{{totalRecords}}</span> Found</span>
          </div>
        </div>
        <!--  RESULTS COUNT-->

        <!--    PAGINATION-->
        <div class="row mt-3 mb-1">
          <div class="col-sm-12 flex-center">
            <nav aria-label="Page navigation example">
              <ul class="pagination pg-blue">
                <li class="page-item" [class.disabled]="currentPage == 1">
              <span class="page-link">
                <a (click)="goToPage(1)">
                  First
                </a>
              </span>
                </li>
                <li class="page-item" [class.disabled]="currentPage == 1">
              <span class="page-link">
                <a (click)="goToPage(currentPage - 1)">
                  Previous
                </a>
              </span>
                </li>
                <li class="page-item" *ngIf="currentPage - 10 > 0">
                  <a class="page-link" mdbWavesEffect (click)="goToPage(currentPage - 10)">
                    {{currentPage - 10}}
                  </a>
                </li>
                <li class="page-item" *ngIf="currentPage - 9 > 0">
                  <a class="page-link" mdbWavesEffect (click)="goToPage(currentPage - 9)">
                    {{currentPage - 9}}
                  </a>
                </li>
                <li class="page-item" *ngIf="currentPage - 8 > 0">
                  <a class="page-link" mdbWavesEffect (click)="goToPage(currentPage - 8)">
                    {{currentPage - 8}}
                  </a>
                </li>
                <li class="page-item" *ngIf="currentPage - 7 > 0">
                  <a class="page-link" mdbWavesEffect (click)="goToPage(currentPage - 7)">
                    {{currentPage - 7}}
                  </a>
                </li>
                <li class="page-item" *ngIf="currentPage - 6 > 0">
                  <a class="page-link" mdbWavesEffect (click)="goToPage(currentPage - 6)">
                    {{currentPage - 6}}
                  </a>
                </li>
                <li class="page-item" *ngIf="currentPage - 5 > 0">
                  <a class="page-link" mdbWavesEffect (click)="goToPage(currentPage - 5)">
                    {{currentPage - 5}}
                  </a>
                </li>
                <li class="page-item" *ngIf="currentPage - 4 > 0">
                  <a class="page-link" mdbWavesEffect (click)="goToPage(currentPage - 4)">
                    {{currentPage - 4}}
                  </a>
                </li>
                <li class="page-item" *ngIf="currentPage - 3 > 0">
                  <a class="page-link" mdbWavesEffect (click)="goToPage(currentPage - 3)">
                    {{currentPage - 3}}
                  </a>
                </li>
                <li class="page-item" *ngIf="currentPage - 2 > 0">
                  <a class="page-link" mdbWavesEffect (click)="goToPage(currentPage - 2)">
                    {{currentPage - 2}}
                  </a>
                </li>
                <li class="page-item" *ngIf="currentPage - 1 > 0">
                  <a class="page-link" mdbWavesEffect (click)="goToPage(currentPage - 1)">
                    {{currentPage - 1}}
                  </a>
                </li>
                <li class="page-item active">
              <span class="page-link" mdbWavesEffect>
                {{currentPage}}
                <span class="sr-only">(current)</span>
              </span>
                </li>
                <li class="page-item" *ngIf="currentPage + 1 <= lastPage">
                  <a class="page-link" mdbWavesEffect (click)="goToPage(currentPage + 1)">
                    {{currentPage + 1}}
                  </a>
                </li>
                <li class="page-item" *ngIf="currentPage + 2 <= lastPage">
                  <a class="page-link" mdbWavesEffect (click)="goToPage(currentPage + 2)">
                    {{currentPage + 2}}
                  </a>
                </li>
                <li class="page-item" *ngIf="currentPage + 3 <= lastPage">
                  <a class="page-link" mdbWavesEffect (click)="goToPage(currentPage + 3)">
                    {{currentPage + 3}}
                  </a>
                </li>
                <li class="page-item" *ngIf="currentPage + 4 <= lastPage">
                  <a class="page-link" mdbWavesEffect (click)="goToPage(currentPage + 4)">
                    {{currentPage + 4}}
                  </a>
                </li>
                <li class="page-item" *ngIf="currentPage + 5 <= lastPage">
                  <a class="page-link" mdbWavesEffect (click)="goToPage(currentPage + 5)">
                    {{currentPage + 5}}
                  </a>
                </li>
                <li class="page-item" *ngIf="currentPage + 6 <= lastPage">
                  <a class="page-link" mdbWavesEffect (click)="goToPage(currentPage + 6)">
                    {{currentPage + 6}}
                  </a>
                </li>
                <li class="page-item" *ngIf="currentPage + 7 <= lastPage">
                  <a class="page-link" mdbWavesEffect (click)="goToPage(currentPage + 7)">
                    {{currentPage + 7}}
                  </a>
                </li>
                <li class="page-item" *ngIf="currentPage + 8 <= lastPage">
                  <a class="page-link" mdbWavesEffect (click)="goToPage(currentPage + 8)">
                    {{currentPage + 8}}
                  </a>
                </li>
                <li class="page-item" *ngIf="currentPage + 9 <= lastPage">
                  <a class="page-link" mdbWavesEffect (click)="goToPage(currentPage + 9)">
                    {{currentPage + 9}}
                  </a>
                </li>
                <li class="page-item" *ngIf="currentPage + 10 <= lastPage">
                  <a class="page-link" mdbWavesEffect (click)="goToPage(currentPage + 10)">
                    {{currentPage + 10}}
                  </a>
                </li>
                <li class="page-item" [class.disabled]="currentPage == lastPage">
                  <a class="page-link" mdbWavesEffect (click)="goToPage(currentPage + 1)">
                    Next
                  </a>
                </li>
                <li class="page-item" [class.disabled]="currentPage == lastPage">
                  <a class="page-link" mdbWavesEffect (click)="goToPage(lastPage)">Last</a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <!--    --/PAGINATOR-->

        <div class="row">
          <div  routerLink="/gap/details/{{item.id}}" class=" col-lg-6 col-md-6  pt-md-3 item" *ngFor="let item of gap">
            <div class="view overlay zoom">
              <div class="p-2 card shadow-sm">
                <div class="media">
                  <div class="">
                    <img class="details-image img-fluid"
                         width="70px"
                         height="70px"
                         [defaultImage]="defaultImage"
                         [errorImage]="errorImage"
                         offset="1500"
                         [lazyLoad]="baseUrl+'image/70/70/'+item.image">
                  </div>
                  <!--                <img class="d-flex mr-3" src="{{baseUrl}}image/70/70/{{item.image? item.image: 'default.png'}}" alt="Generic placeholder image">-->
                  <div class="media-body d-flex align-items-center" style="height: 70px; margin-left:5px">
                    <div  class="card-title-container" *ngIf="item.name | isEmpty; else noGapName">
                      <a  class="card-title" routerLink="/gap/details/{{item.id}}">Practices for <span class="comma" *ngFor="let pwd of item.pests_diseases_weeds">{{pwd.name}}</span></a>
                    </div>
                    <ng-template #noGapName>
                      <div class="card-title-container">
                        <a class="card-title" routerLink="/gap/details/{{item.id}}">{{item.name}}</a>
                      </div>
                    </ng-template>
                  </div>
                </div>
              </div>
              <!--          <mdb-card class="shadow-sm">-->
              <!--            &lt;!&ndash;Card image&ndash;&gt;-->
              <!--            <mdb-card-img src="https://mdbootstrap.com/img/Photos/Lightbox/Thumbnail/img%20(97).jpg" alt="Image Not Found"></mdb-card-img>-->
              <!--            &lt;!&ndash;Card content&ndash;&gt;-->
              <!--            <mdb-card-body>-->
              <!--              &lt;!&ndash;Title&ndash;&gt;-->
              <!--              <mdb-card-title>-->
              <!--                <h4 class="flex-center"><a routerLink="">-->
              <!--                  Practices for <span *ngFor="let pwd of item.pests_diseases_weeds">{{pwd.name}}, </span>-->
              <!--                </a></h4>-->
              <!--              </mdb-card-title>-->
              <!--            </mdb-card-body>-->
              <!--          </mdb-card>-->
              <div class="mask flex-center rgba-cyan-slight">
                <!--            <p class="white-text card-overlay-text">Details</p>-->
              </div>
            </div>
          </div>
        </div>

        <!--    PAGINATION-->
        <div class="row mt-3 mb-1">
          <div class="col-sm-12 flex-center">
            <nav aria-label="Page navigation example">
              <ul class="pagination pg-blue">
                <li class="page-item" [class.disabled]="currentPage == 1">
              <span class="page-link">
                <a (click)="goToPage(1)">
                  First
                </a>
              </span>
                </li>
                <li class="page-item" [class.disabled]="currentPage == 1">
              <span class="page-link">
                <a (click)="goToPage(currentPage - 1)">
                  Previous
                </a>
              </span>
                </li>
                <li class="page-item" *ngIf="currentPage - 10 > 0">
                  <a class="page-link" mdbWavesEffect (click)="goToPage(currentPage - 10)">
                    {{currentPage - 10}}
                  </a>
                </li>
                <li class="page-item" *ngIf="currentPage - 9 > 0">
                  <a class="page-link" mdbWavesEffect (click)="goToPage(currentPage - 9)">
                    {{currentPage - 9}}
                  </a>
                </li>
                <li class="page-item" *ngIf="currentPage - 8 > 0">
                  <a class="page-link" mdbWavesEffect (click)="goToPage(currentPage - 8)">
                    {{currentPage - 8}}
                  </a>
                </li>
                <li class="page-item" *ngIf="currentPage - 7 > 0">
                  <a class="page-link" mdbWavesEffect (click)="goToPage(currentPage - 7)">
                    {{currentPage - 7}}
                  </a>
                </li>
                <li class="page-item" *ngIf="currentPage - 6 > 0">
                  <a class="page-link" mdbWavesEffect (click)="goToPage(currentPage - 6)">
                    {{currentPage - 6}}
                  </a>
                </li>
                <li class="page-item" *ngIf="currentPage - 5 > 0">
                  <a class="page-link" mdbWavesEffect (click)="goToPage(currentPage - 5)">
                    {{currentPage - 5}}
                  </a>
                </li>
                <li class="page-item" *ngIf="currentPage - 4 > 0">
                  <a class="page-link" mdbWavesEffect (click)="goToPage(currentPage - 4)">
                    {{currentPage - 4}}
                  </a>
                </li>
                <li class="page-item" *ngIf="currentPage - 3 > 0">
                  <a class="page-link" mdbWavesEffect (click)="goToPage(currentPage - 3)">
                    {{currentPage - 3}}
                  </a>
                </li>
                <li class="page-item" *ngIf="currentPage - 2 > 0">
                  <a class="page-link" mdbWavesEffect (click)="goToPage(currentPage - 2)">
                    {{currentPage - 2}}
                  </a>
                </li>
                <li class="page-item" *ngIf="currentPage - 1 > 0">
                  <a class="page-link" mdbWavesEffect (click)="goToPage(currentPage - 1)">
                    {{currentPage - 1}}
                  </a>
                </li>
                <li class="page-item active">
              <span class="page-link" mdbWavesEffect>
                {{currentPage}}
                <span class="sr-only">(current)</span>
              </span>
                </li>
                <li class="page-item" *ngIf="currentPage + 1 <= lastPage">
                  <a class="page-link" mdbWavesEffect (click)="goToPage(currentPage + 1)">
                    {{currentPage + 1}}
                  </a>
                </li>
                <li class="page-item" *ngIf="currentPage + 2 <= lastPage">
                  <a class="page-link" mdbWavesEffect (click)="goToPage(currentPage + 2)">
                    {{currentPage + 2}}
                  </a>
                </li>
                <li class="page-item" *ngIf="currentPage + 3 <= lastPage">
                  <a class="page-link" mdbWavesEffect (click)="goToPage(currentPage + 3)">
                    {{currentPage + 3}}
                  </a>
                </li>
                <li class="page-item" *ngIf="currentPage + 4 <= lastPage">
                  <a class="page-link" mdbWavesEffect (click)="goToPage(currentPage + 4)">
                    {{currentPage + 4}}
                  </a>
                </li>
                <li class="page-item" *ngIf="currentPage + 5 <= lastPage">
                  <a class="page-link" mdbWavesEffect (click)="goToPage(currentPage + 5)">
                    {{currentPage + 5}}
                  </a>
                </li>
                <li class="page-item" *ngIf="currentPage + 6 <= lastPage">
                  <a class="page-link" mdbWavesEffect (click)="goToPage(currentPage + 6)">
                    {{currentPage + 6}}
                  </a>
                </li>
                <li class="page-item" *ngIf="currentPage + 7 <= lastPage">
                  <a class="page-link" mdbWavesEffect (click)="goToPage(currentPage + 7)">
                    {{currentPage + 7}}
                  </a>
                </li>
                <li class="page-item" *ngIf="currentPage + 8 <= lastPage">
                  <a class="page-link" mdbWavesEffect (click)="goToPage(currentPage + 8)">
                    {{currentPage + 8}}
                  </a>
                </li>
                <li class="page-item" *ngIf="currentPage + 9 <= lastPage">
                  <a class="page-link" mdbWavesEffect (click)="goToPage(currentPage + 9)">
                    {{currentPage + 9}}
                  </a>
                </li>
                <li class="page-item" *ngIf="currentPage + 10 <= lastPage">
                  <a class="page-link" mdbWavesEffect (click)="goToPage(currentPage + 10)">
                    {{currentPage + 10}}
                  </a>
                </li>
                <li class="page-item" [class.disabled]="currentPage == lastPage">
                  <a class="page-link" mdbWavesEffect (click)="goToPage(currentPage + 1)">
                    Next
                  </a>
                </li>
                <li class="page-item" [class.disabled]="currentPage == lastPage">
                  <a class="page-link" mdbWavesEffect (click)="goToPage(lastPage)">Last</a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <!--    --/PAGINATOR-->

      </div>
    </div>
  </div>
</div>

