import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mutagenicity'
})
export class MutagenicityPipe implements PipeTransform {

  transform(value: string): string {
    switch (value){
      case 'Yes':
        return 'Scientific studies have shown that this product has the capacity to induce mutations.';
      case 'No':
        return 'No scientific studies have shown that this product does not induce mutations.';
      case 'Possible':
        return 'Although it has not been conclusively determined; this product may induce mutations.';
      case 'No Data':
        return 'There is no data on this product related to mutagenicity. Precautionary principle applies – please avoid using if in doubt.';
      default:
        return '';
    }
  }

}
