import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams} from '@angular/common/http';
import {AppSettings} from '../../app.settings';
import {catchError, retry} from 'rxjs/operators';
import {throwError} from 'rxjs';
import {environment} from '../../../environments/environment';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'text/html',
    'Access-Control-Allow-Origin': '*'
  })
};

@Injectable({
  providedIn: 'root'
})
export class GlobalSearchService {

  constructor(private http: HttpClient) { }

  // Perform search implemented by the Spatie/Search API
  searchAlt(searchValue: string, page: number){
    const  params = new  HttpParams()
      .set('page', page + '');
    // if (page > 0){
    //   params.set('page', page + '');
    // }
      // .set('toxic', toxic)
      // .set('limit', '100')
    // ;
    const url = `${environment.baseUrl}search_alt/${searchValue}`;
    return this.http.get<any>(url, {params})
      .pipe(
        retry(1), // retry a failed request up to 3 times
        catchError(this.handleError)
      );
  }

  // Perform search implemented by laravel/scout-extended
  search(searchValue: string, page: number){
    const  params = new  HttpParams()
      .set('page', page + '')
      .set('query', searchValue + '');
    // if (page > 0){
    //   params.set('page', page + '');
    // }
      // .set('toxic', toxic)
      // .set('limit', '100')
    // ;
    const url = `${environment.baseUrl}search/`;
    return this.http.get<any>(url, {params})
      .pipe(
        retry(1), // retry a failed request up to 3 times
        catchError(this.handleError)
      );
  }


  // Error handling
  private handleError(error: HttpErrorResponse): any {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error.message}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  }
}
