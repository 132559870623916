import { Component, OnInit } from '@angular/core';
import {ControlMethods} from '../../shared/models/control_methods';
import {ControlMethodsService} from '../../shared/services/control-methods.service';
import {MessageService} from '../../shared/messages/message.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-control-methods-dashboard',
  templateUrl: './control-methods-dashboard.component.html',
  styleUrls: ['./control-methods-dashboard.component.css']
})
export class ControlMethodsDashboardComponent implements OnInit {


  totalRecords: number;
  to: number;
  from: number;
  currentPage: number;
  lastPage: number;
  perPage = '20';
  perPageChanged = false;
  page: string;
  orderColumn = 'id';
  orderDirection = 'desc';
  orderChanged = false;
  ordering = '';
  searchValue = '';


  baseUrl = environment.baseUrl;
  defaultImage = environment.defaultImage;
  errorImage = environment.errorImage;
  loading = true;
  loaded = false;
  error = false;
  controlMethods: ControlMethods[];
  title: string;
  category: string;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private titleService: Title,
    public messageService: MessageService,
    private controlMethodsService: ControlMethodsService,
  ) { }

  ngOnInit(): void {
    const currentUrl = this.route.routeConfig.path;
    switch (currentUrl){
      case 'control-methods/biocontrol':
        this.route.params.subscribe(routeParams => {
          // Get page query param
          this.route.queryParams.subscribe(params => {
            // Defaults to 0 if no query param provided.
            this.page = params.page || '1';
            this.searchValue = params.q || '';
            this.perPage = params.limit || '20';
            this.ordering = params.ordering || '#';
            this.setOrdering(this.ordering);
            this.loading = true;
            this.loaded = false;
            this.category = 'biocontrol';
            this.getNamesByCategory('Biocontrol Control Methods', this.category);
          });
        });
        break;
      case 'control-methods/biopesticide':
        this.route.params.subscribe(routeParams => {
          // Get page query param
          this.route.queryParams.subscribe(params => {
            // Defaults to 0 if no query param provided.
            this.page = params.page || '1';
            this.searchValue = params.q || '';
            this.perPage = params.limit || '20';
            this.ordering = params.ordering || '#';
            this.setOrdering(this.ordering);
            this.loading = true;
            this.loaded = false;
            this.category = 'biopesticide';
            this.getNamesByCategory('Biopesticide Control Methods', this.category);
          });
        });
        break;
      case 'control-methods':
        // Get page query param
        this.route.queryParams.subscribe(params => {
          // Defaults to 0 if no query param provided.
          this.page = params.page || '1';
          this.searchValue = params.q || '';
          this.perPage = params.limit || '20';
          this.ordering = params.ordering || '#';
          this.setOrdering(this.ordering);
          this.loading = true;
          this.loaded = false;
          this.category = '';
          this.getNamesByCategory('Organic Control Methods');
        });
        break;
      default:
        this.router.navigateByUrl('/404');
        break;
    }
  }

  private getAll(title) {
    this.titleService.setTitle(title);
    this.title = title;
    // Fetch all control methods
    this.controlMethodsService.getAll(this.page, this.perPage, this.orderColumn, this.orderDirection).subscribe(response => {
        this.controlMethods = response.data;
        this.page = response.meta.page;
        this.currentPage = response.meta.current_page;
        this.lastPage = response.meta.last_page;
        this.from = response.meta.from;
        this.to = response.meta.to;
        this.loading = false;
        this.loaded = true;
        this.error = false;
      },
      error => {
        // Handle error if no control methods are found
        this.messageService.showMessage('info', 'Not found', 'No control methods found');
        this.loading = false;
        this.error = true;
      });
  }

  // Fetch control methods by category
  private getByCategory(title: string, category: string) {
    this.titleService.setTitle(this.capitalize(category));
    this.title = title;
    this.controlMethodsService.getByCategory(category, this.page, this.perPage, this.orderColumn, this.orderDirection).subscribe(response => {
        this.controlMethods = response.data;
        this.totalRecords = response.meta.total;
        this.page = response.meta.page;
        this.currentPage = response.meta.current_page;
        this.lastPage = response.meta.last_page;
        this.from = response.meta.from;
        this.to = response.meta.to;
        this.loading = false;
        this.loaded = true;
        this.error = false;
      },
      error => {
        this.messageService.showMessage('info', 'Not found', category + ' control methods not found');
        this.loading = false;
        this.error = true;
      });
  }

  private getNamesByCategory(title: string, category = '') {
    this.category = category;
    this.title = title;
    this.titleService.setTitle(title);
    // Fetch pests, diseases or weeds based on the type
    this.filter(this.category, this.page, this.perPage, this.orderColumn, this.orderDirection, this.searchValue);
  }

  private filter(category = '', page = '', perPage = '', orderColumn = '', orderDirection = '', searchValue = '') {
    this.controlMethodsService.getNamesByCategory(category, page, perPage, orderColumn, orderDirection, searchValue).subscribe(response => {
        this.controlMethods = response.data;
        this.totalRecords = response.total;
        this.page = response.page;
        this.currentPage = response.current_page;
        this.lastPage = response.last_page;
        this.from = response.from;
        this.to = response.to;
        this.loading = false;
        this.loaded = true;
        this.error = false;
      },
      error => {
        // Error handling
        this.messageService.showMessage('info', 'Not found', this.title + 's not found');
        this.loading = false;
        this.error = true;
      }
    );
  }

// Capitalize the first character of a string
  private capitalize(word: string)  {
    return word.charAt(0).toUpperCase() + word.slice(1);
  }

  public goToPage(page){
    this.loading = true;
    this.loaded = false;
    this.page = page;
    // Load in same page
    // this.filter(this.type, this.page, this.perPage, this.orderColumn, this.orderDirection, this.searchValue);
    // Navigate to page
    this.router.navigateByUrl(`/control-methods${this.category ? '/' + this.category : ''}?page=${page}${this.orderChanged ? '&ordering=' + this.ordering : ''}${this.searchValue !== '' ? '&q=' + this.searchValue : ''}${this.perPage !== '20' ? '&limit=' + this.perPage : ''}`);

  }
  // Handles an item search based on search value
  public search(){
    this.loading = true;
    this.loaded = false;
    this.page = '1';
    this.filter(this.category, this.page, this.perPage, this.orderColumn, this.orderDirection, this.searchValue);
  }
  // Handle change in items per pagge to be displayed
  public itemsPerPageChanged(){
    this.loading = true;
    this.loaded = false;
    this.page = '1';
    this.perPageChanged = true;
    this.filter(this.category, this.page, this.perPage, this.orderColumn, this.orderDirection, this.searchValue);
  }
  // Handle change in sort order and field
  public sortOrderChanged() {
    const value = this.ordering;
    this.setOrdering(value);
    this.loading = true;
    this.loaded = false;
    this.page = '1';

    this.filter(this.category, this.page, this.perPage, this.orderColumn, this.orderDirection, this.searchValue);
  }
  private setOrdering(value){
    if (value.indexOf('!') === 0) {
      this.orderColumn = value.substring(1, value.length);
      this.orderDirection = 'desc';
      this.orderChanged = true;
    } else if (value === '#'){
      // Default Ordering
      this.orderColumn = 'id';
      this.orderDirection = 'desc';
      this.orderChanged = false;
    } else {
      this.orderColumn = value;
      this.orderDirection = 'asc';
      this.orderChanged = true;
    }
  }
}
