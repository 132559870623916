<div class="d-flex justify-content-center vh-100" *ngIf="loading">
  <div class="flex-center">
    <div class="spinner-grow text-light" style="width: 6rem; height: 6rem;"  role="status">
      <span class="sr-only">Loading...</span>
    </div>
    <span class="component-title orb">Loading...</span>
  </div>
</div>

<app-not-found *ngIf="error"></app-not-found>

<div *ngIf="loaded">
  <mdb-breadcrumb>
    <mdb-breadcrumb-item routerLink="/" class="blue-text">Home</mdb-breadcrumb-item>
    <mdb-breadcrumb-item routerLink="/pdw" class="blue-text">Pests,Diseases,Weeds</mdb-breadcrumb-item>
    <mdb-breadcrumb-item class="active">{{pestsDiseaseWeed.name}}</mdb-breadcrumb-item>
  </mdb-breadcrumb>

  <div class="container">

    <section class="component-content">
      <div class="row mb-1">
      </div>


      <div class="p-2 px-md-5 pt-md-5 pb-md-1">
        <div class="col-sm  text-center">
          <span class="component-title">
            {{pestsDiseaseWeed.name}}
          </span>
        </div>
        <!-- Section heading -->
<!--        <h2 class="h1-responsive small-heading text-center my-3">{{pestsDiseaseWeed.type}}</h2>-->
        <!-- Section description -->
        <p class="text-center dark-grey-text w-responsive mx-auto mb-5"><em>{{pestsDiseaseWeed.scientific_name}}</em></p>

        <!-- Grid row -->
        <div class="row">
          <!-- Grid column -->
          <div class="col-lg-6 col-md-12">
            <!-- Image -->
            <div class="mb-4">
              <div class="view overlay rounded z-depth-1-half mb-4">
<!--                <img class="details-image img-fluid" width="100%" height="auto" src="{{baseUrl}}image/600/400/{{pestsDiseaseWeed.image? pestsDiseaseWeed.image: 'default.png'}}" alt="Sample image">-->
                <img class="details-image img-fluid"
                     width="100%"
                     height="auto"
                     [defaultImage]="defaultImage"
                     [errorImage]="errorImage"
                     offset="1500"
                     [lazyLoad]="baseUrl+'image/600/400/'+pestsDiseaseWeed.image">
                <a>
                  <div class="mask rgba-white-slight waves-light" mdbWavesEffect></div>
                </a>
              </div>
            </div>
            <!-- Image -->
          </div>
          <!-- Grid column -->

          <!-- Grid column -->
          <div class="col-lg-6 col-md-12">
            <div class="">
              <!-- Grid row -->
              <div class="row mb-3" *ngIf="pestsDiseaseWeed.local_names?.length > 0">
                <div class="col-md-11 col-10">
                  <h4 class="small-heading mb-1 minor-items-title">Local Names</h4>
                  <ul class="list-inline">
                    <li class="list-inline-item dark-grey-text minor-items-content" *ngFor="let lcl_name of pestsDiseaseWeed.local_names">
                      {{lcl_name.local_name}} - {{lcl_name.language_ethnic_group}}: {{lcl_name.region}} <br>
                    </li>
                  </ul>
                </div>
              </div>
              <!-- Grid row -->

              <!-- Grid row -->
              <div class="row mb-3" *ngIf="pestsDiseaseWeed.crops?.length > 0">
                <div class="col-md-11 col-10">
                  <h4 class="small-heading mb-1 minor-items-title">Crops Affected</h4>
                  <ul class="list-inline">
                    <li class="list-inline-item minor-items-content" *ngFor="let crop of pestsDiseaseWeed.crops">
                      <mdb-badge>
                        <a routerLink="/crops/details/{{crop.id}}">{{crop.name}}</a>
                      </mdb-badge>
                    </li>
                  </ul>
                </div>
              </div>
              <!-- Grid row -->

              <!-- Grid row -->
              <div class="row mb-3" *ngIf="pestsDiseaseWeed.commercial_organic?.length > 0">
                <div class="col-md-11 col-10">
                  <h4 class="small-heading mb-1 minor-items-title">Offshelf Organic Solutions</h4>
                  <ul class="list-inline">
                    <li class="list-inline-item minor-items-content" *ngFor="let cmr_item of pestsDiseaseWeed.commercial_organic">
                      <mdb-badge>
                        <a routerLink="/commercial-organic/details/{{cmr_item.id}}">{{cmr_item.name}}</a>
                      </mdb-badge>
                    </li>
                  </ul>
                </div>
              </div>
              <!-- Grid row -->
            </div>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-12" *ngIf="pestsDiseaseWeed.control_methods?.length > 0 || pestsDiseaseWeed.homemade_organic?.length > 0 || pestsDiseaseWeed.gap?.length > 0">
            <div class="solutions">
              <!-- Grid row -->
              <div class="row mb-3" *ngIf="pestsDiseaseWeed.gap?.length > 0">
                <div class="col-md-12 col-12">
                  <h4 class="small-heading mb-1">Good agricultural practices to control {{pestsDiseaseWeed.name}}</h4>
                  <ul class="list-inline">
                    <li class="dark-grey-text" *ngFor="let gap of pestsDiseaseWeed.gap">
                      <div *ngIf="gap.name | isEmpty; else noGapName">
                        <a [routerLink]='"."' [fragment]="'gap' + gap.id">Practices for <span class="comma" *ngFor="let pwd of gap.pests_diseases_weeds">{{pwd.name}}</span></a><br>
                        <!--                        <a routerLink="/gap/details/{{gap.id}}">Practices for <span class="comma" *ngFor="let pwd of gap.pests_diseases_weeds">{{pwd.name}}</span></a><br>-->
                      </div>
                      <ng-template #noGapName>
                        <a [routerLink]='"."' [fragment]="'gap' + gap.id">{{gap.name}}</a><br>
                        <!--                        <a routerLink="/gap/details/{{gap.id}}">{{gap.name}}</a><br>-->
                      </ng-template>
                    </li>
                  </ul>
                </div>
              </div>
              <!-- Grid row -->
              <!-- Grid row -->
              <div class="row mb-3" *ngIf="pestsDiseaseWeed.homemade_organic?.length > 0">
                <div class="col-md-12 col-12">
                  <h4 class="small-heading mb-1">Here are some homemade solutions for {{pestsDiseaseWeed.name}}</h4>
                  <ul class="list-inline">
                    <li class="dark-grey-text" *ngFor="let hmo of pestsDiseaseWeed.homemade_organic">
                      <div *ngIf="hmo.name | isEmpty; else noHmoName">
                        <a [routerLink]='"."' [fragment]="'homemadeOrganic' + hmo.id">Practices for <span class="comma" *ngFor="let pwd of hmo.pests_diseases_weeds">{{pwd.name}}</span></a><br>
                        <!--                        <a routerLink="/homemade-organic/details/{{hmo.id}}">Practices for <span class="comma" *ngFor="let pwd of hmo.pests_diseases_weeds">{{pwd.name}}</span></a><br>-->
                      </div>
                      <ng-template #noHmoName>
                        <a [routerLink]='"."' [fragment]="'homemadeOrganic' + hmo.id">{{hmo.name}}</a><br>
                        <!--                        <a routerLink="/homemade-organic/details/{{hmo.id}}">{{hmo.name}}</a><br>-->
                      </ng-template>
                    </li>
                  </ul>
                </div>
              </div>
              <!-- Grid row -->
              <!-- Grid row -->
              <div class="row mb-3" *ngIf="pestsDiseaseWeed.control_methods?.length > 0">
                <div class="col-md-12 col-12">
                  <h4 class="small-heading mb-1">Here are the organic control methods for {{pestsDiseaseWeed.name}}</h4>
                  <ul class="list-inline">
                    <li class="dark-grey-text" *ngFor="let cmo of pestsDiseaseWeed.control_methods">
                      <div *ngIf="cmo.name | isEmpty; else noCmoName">
<!--                        <a routerLink="/control-methods/details/{{cmo.id}}">Control methods for  <span class="comma" *ngFor="let pwd of cmo.pests_diseases_weeds">{{pwd.name}}</span></a><br>-->
                        <a [routerLink]='"."' [fragment]="'controlMethod' + cmo.id">Control methods for  <span class="comma" *ngFor="let pwd of cmo.pests_diseases_weeds">{{pwd.name}}</span></a><br>
                      </div>
                      <ng-template #noCmoName>
<!--                        <a routerLink="/control-methods/details/{{cmo.id}}">{{cmo.name}}</a><br>-->
                        <a [routerLink]='"."' [fragment]="'controlMethod' + cmo.id">{{cmo.name}}</a><br>
                      </ng-template>
                    </li>
                  </ul>
                </div>
              </div>
              <!-- Grid row -->
            </div>
          </div>
          <!-- Grid column -->
        </div>
        <!-- Grid row -->
      </div>

      <!-- Grid row -->
      <div class="row">
        <!-- Grid Column -->
        <div class="col-12 description" *ngIf="pestsDiseaseWeed.description_pest">
          <div class="soft-shadow-upper-2">
            <div class="soft-shadow-lower-2">
              <div class="item-section">
                <!-- Title -->
                <div class="item-section-title">
                  <h2 class="category-title mt-5 mb-1"><strong class="">Description</strong></h2>
                </div>
                <!-- Title -->
                <div class="item-section-content">
                  <!-- Content -->
                  <p class="ck-content dark-grey-text " [innerHTML]="pestsDiseaseWeed.description_pest | unescape">
                  </p>
                  <!-- Content -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Grid Column -->

        <!-- Grid Column -->
        <div class="col-12 effect-on-crops" *ngIf="pestsDiseaseWeed.description_impact">
          <div class="soft-shadow-upper-2">
            <div class="soft-shadow-lower-2">
              <div class="item-section">
                <!-- Title -->
                <div class="item-section-title">
                  <h2 class="category-title mt-5 mb-1"><strong class="">Effect On Crops</strong></h2>
                </div>
                <!-- Title -->
                <div class="item-section-content">
                  <!-- Content -->
                  <p class="ck-content dark-grey-text " [innerHTML]="pestsDiseaseWeed.description_impact | unescape">
                  </p>
                  <!-- Content -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Grid Column -->

        <!-- Grid Column -->
        <div class="col-12 gap" *ngIf="pestsDiseaseWeed.gap?.length > 0">
          <div class="soft-shadow-upper-2">
            <div class="soft-shadow-lower-2">
              <div class="item-section">
                <!-- Title -->
                <div class="item-section-title">
                  <h2 class="category-title mt-5 mb-1"><strong>GAP</strong></h2>
                </div>
                <!-- Title -->
                <div class="item-section-content">
                  <!-- Content -->
                  <div *ngFor="let gapItem of pestsDiseaseWeed.gap; let i = index">
                    <div id="gap{{gapItem.id}}">
                      <div class="description-paragraph dark-grey-text font-weight-700">
                        <a routerLink="/gap/details/{{gapItem.id}}">
                            <span class="description-sub-heading">
                              {{i+1}}.
                              <span *ngIf="gapItem.name | isEmpty; else noGapItemName">
                                Practices for <span class="comma" *ngFor="let pwd of gapItem.pests_diseases_weeds">{{pwd.name}}</span>
                              </span>
                              <ng-template #noGapItemName>
                                {{gapItem.name}}
                              </ng-template>
                            </span>
                        </a>
                      </div>
                      <div *ngIf="gapItem.practices?.length> 0">
                        <div class="description-paragraph dark-grey-text ">
                          <span class="description-sub-heading-2">Practices</span>
                        </div>
                        <div class="ck-content dark-grey-text" [innerHTML]="gapPractice | unescape" *ngFor="let gapPractice of gapItem.practices"></div>
                      </div>
                      <div *ngIf="gapItem.references?.length> 0">
                        <div class="description-paragraph dark-grey-text ">
                          <span class="description-sub-heading-2">External Links</span>
                        </div>
                        <div class="ck-content dark-grey-text" [innerHTML]="gapReference | unescape" *ngFor="let gapReference of gapItem.references"></div>
                      </div>
                    </div>
                  </div>
                  <!-- Content -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Grid Column -->

        <!-- Grid Column -->
        <div class="col-12 homemade-organic" *ngIf="pestsDiseaseWeed.homemade_organic?.length > 0">
          <div class="soft-shadow-upper-2">
            <div class="soft-shadow-lower-2">
              <div class="item-section">
                <!-- Title -->
                <div class="item-section-title">
                  <h2 class="category-title mt-5 mb-1"><strong>Homemade Organic Solutions</strong></h2>
                </div>
                <!-- Title -->
                <div class="item-section-content">
                  <!-- Content -->
                  <div *ngFor="let hmoItem of pestsDiseaseWeed.homemade_organic; let i = index">
                    <div id="homemadeOrganic{{hmoItem.id}}">
                      <div class="description-paragraph dark-grey-text font-weight-700">
                        <a routerLink="/homemade-organic/details/{{hmoItem.id}}">
                            <span class="description-sub-heading">{{i+1}}.
                            <span *ngIf="hmoItem.name | isEmpty; else noHmoItemName">
                              Practices for <span class="comma" *ngFor="let pwd of hmoItem.pests_diseases_weeds">{{pwd.name}}</span>
                            </span>
                            <ng-template #noHmoItemName>
                              {{hmoItem.name}}
                            </ng-template>
                          </span>
                        </a>
                      </div>
                      <div *ngIf="hmoItem.practices?.length> 0">
                        <div class="description-paragraph dark-grey-text ">
                          <span class="description-sub-heading-2">Practices</span>
                        </div>
                        <div class="ck-content dark-grey-text" [innerHTML]="hmoPractice | unescape" *ngFor="let hmoPractice of hmoItem.practices"></div>
                      </div>
                      <div class="mt-3" *ngIf="hmoItem.external_links?.length> 0">
                        <div class="description-paragraph dark-grey-text ">
                          <span class="description-sub-heading-2">External Links</span>
                        </div>
                        <div class="ck-content dark-grey-text" [innerHTML]="hmoLink | unescape" *ngFor="let hmoLink of hmoItem.external_links"></div>
                      </div>
                    </div>
                  </div>
                  <!-- Content -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Grid Column -->

        <!-- Grid Column -->
        <div class="col-12 control-methods" *ngIf="pestsDiseaseWeed.control_methods?.length > 0; else noControlMethods">
          <div class="soft-shadow-upper-2">
            <div class="soft-shadow-lower-2">
              <div class="item-section">
                <!-- Title -->
                <div class="item-section-title">
                  <h2 class="category-title mt-5 mb-1"><strong>Control Methods</strong></h2>
                </div>
                <!-- Title -->
                <div class="item-section-content">
                  <!-- Content -->
                  <div *ngFor="let controlMethod of pestsDiseaseWeed.control_methods; let i = index">
                    <div id="controlMethod{{controlMethod.id}}">
                      <div class="description-paragraph dark-grey-text font-weight-700">
                        <a routerLink="/control-methods/details/{{controlMethod.id}}">
                            <span class="description-sub-heading">{{i+1}}. {{controlMethod.category}}
                              <span  *ngIf="controlMethod.name | isEmpty; else noControlMethodName">
                                - Control methods for  <span class="comma" *ngFor="let pwd of controlMethod.pests_diseases_weeds">{{pwd.name}}</span>
                              </span>
                              <ng-template #noControlMethodName>
                                {{' - ' + controlMethod.name}}
                              </ng-template>
                            </span>
                        </a>
                      </div>
                      <div *ngIf="controlMethod.options?.length> 0">
                        <div class="description-paragraph dark-grey-text ">
                          <span class="description-sub-heading-2">Options</span>
                        </div>
                        <div class="ck-content dark-grey-text" [innerHTML]="option | unescape" *ngFor="let option of controlMethod.options"></div>
                      </div>
                      <div *ngIf="controlMethod.external_links?.length> 0">
                        <div class="description-paragraph dark-grey-text ">
                          <span class="description-sub-heading-2">External Links</span>
                        </div>
                        <div class="ck-content dark-grey-text" [innerHTML]="link | unescape" *ngFor="let link of controlMethod.external_links"></div>
                      </div>
                      <br>
                    </div>
                  </div>
                  <!-- Content -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <ng-template #noControlMethods>
          <div class="col-12 default">
            <div class="soft-shadow-upper">
              <div class="soft-shadow-lower">
                <div class="item-section-title">
                  <h2 class="category-title mt-5 mb-1"><strong>No control methods have been listed for the control of {{pestsDiseaseWeed.name}}</strong></h2>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
        <!-- Grid Column -->

        <!-- Grid Column -->
<!--        <div class="col-12 less-toxic-agrochem" *ngIf="nonToxicAgrochems?.length > 0; else noLessTOxicAgrochems">-->
<!--          <div class="soft-shadow-upper">-->
<!--            <div class="soft-shadow-lower">-->
<!--              <div class="item-section">-->
<!--                &lt;!&ndash; Title &ndash;&gt;-->
<!--                <div class="item-section-title">-->
<!--                  <h2 class="category-title mt-5 mb-1"><strong class="">Less Toxic Alternative Agrochemicals</strong></h2>-->
<!--                </div>-->
<!--                &lt;!&ndash; Title &ndash;&gt;-->
<!--                <div class="item-section-content">-->
<!--                  &lt;!&ndash; Content &ndash;&gt;-->
<!--                  <div class="row">-->
<!--                    &lt;!&ndash;        Layyout for small&ndash;&gt;-->
<!--                    <div class=" d-block d-md-none col-sm-12 mx-3 my-1 p-2 view" *ngFor="let item of nonToxicAgrochems">-->
<!--                      <div class="media item" routerLink="/agrochem/details/{{item.id}}">-->
<!--                        <div class="card-img-container">-->
<!--                          <img class="details-image img-fluid"-->
<!--                               width="70px"-->
<!--                               height="70px"-->
<!--                               [defaultImage]="defaultImage"-->
<!--                               [lazyLoad]="baseUrl+'image/70/70/'+item.image">-->
<!--                        </div>-->
<!--                        &lt;!&ndash;            <img src="{{baseUrl}}image/100/67/{{item.image? item.image: 'default.png'}}" class="mr-3" alt="...">&ndash;&gt;-->
<!--                        <div class="media-body d-flex align-items-center" style="height: 70px; margin-left:5px">-->
<!--                          <div class="card-title-container"><a class="card-title" routerLink="/agrochem/details/{{item.id}}">{{item.product_name}}</a></div>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                    &lt;!&ndash;        Layyout for small&ndash;&gt;-->
<!--                    &lt;!&ndash;        Layout for medium and Larger&ndash;&gt;-->
<!--                    <div class="d-none d-md-block col-xs-6 col-sm-6 col-md-4 col-lg-3 pt-md-3 " *ngFor="let item of nonToxicAgrochems">-->
<!--                      <div  routerLink="/agrochem/details/{{item.id}}" class="item">-->
<!--                            <div class="view overlay zoom">-->
<!--                              <mdb-card class="shadow-sm">-->
<!--                                &lt;!&ndash;Card image&ndash;&gt;-->
<!--                                <img class="details-image img-fluid"-->
<!--                                     width="100%"-->
<!--                                     height="auto"-->
<!--                                     [defaultImage]="defaultImage"-->
<!--                                     [lazyLoad]="baseUrl+'image/245/160/'+item.image"-->
<!--                                     alt="Sample image"/>-->
<!--                                &lt;!&ndash;                <mdb-card-img src="{{baseUrl}}image/245/160/{{item.image? item.image: 'default.png'}}" alt="Image Not Found"></mdb-card-img>&ndash;&gt;-->
<!--                                &lt;!&ndash;Card content&ndash;&gt;-->
<!--                                &lt;!&ndash;                                  <mdb-card-body>&ndash;&gt;-->
<!--                                &lt;!&ndash;Title&ndash;&gt;-->
<!--                                <mdb-card-title>-->
<!--                                  <div class="card-title-container"><a class="card-title" routerLink="/agrochem/details/{{item.id}}">{{item.product_name}}</a></div>-->
<!--                                </mdb-card-title>-->
<!--                                &lt;!&ndash;                                  </mdb-card-body>&ndash;&gt;-->
<!--                              </mdb-card>-->
<!--                              <div class="mask flex-center rgba-red-light" *ngIf="item.toxic"></div>-->
<!--                              <div class="mask flex-center rgba-red-slight" *ngIf="!item.toxic"></div>-->
<!--                            </div>-->
<!--                          </div>-->
<!--                    </div>-->
<!--                    &lt;!&ndash;        Layout for medium and Larger&ndash;&gt;-->
<!--                  </div>-->
<!--                  &lt;!&ndash; Content &ndash;&gt;-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
        <!-- Description -->
<!--        <div class="col-12">-->
<!--          <ng-template #noLessTOxicAgrochems>-->
<!--            <div class="col-12 default">-->
<!--              <div class="soft-shadow-upper">-->
<!--                <div class="soft-shadow-lower">-->
<!--                  <div class="item-section-title">-->
<!--                    <h2 class="category-title mt-5 mb-1">-->
<!--                      <strong>-->
<!--                        No Lesser toxic options have been listed for the control of {{pestsDiseaseWeed.name}}-->
<!--                      </strong>-->
<!--                    </h2>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </ng-template>-->
<!--        </div>-->
        <!-- Grid Column -->


        <!-- Grid Column -->
<!--        <div class="col-12 agrochem" *ngIf="toxicAgrochems?.length > 0">-->
<!--          <div class="soft-shadow-upper">-->
<!--            <div class="soft-shadow-lower">-->
<!--              <div class="item-section">-->
<!--                &lt;!&ndash; Title &ndash;&gt;-->
<!--                <div class="item-section-title">-->
<!--                  <h2 class="category-title mt-5 mb-1"><strong class="">Agrochemicals</strong></h2>-->
<!--                </div>-->
<!--                &lt;!&ndash; Title &ndash;&gt;-->
<!--                <div class="item-section-content">-->
<!--                  &lt;!&ndash; Content &ndash;&gt;-->
<!--                  <p>The following chemicals are used to control {{pestsDiseaseWeed.name}} and have negative effects on human beings and the environment.  </p>-->
<!--                  <div class="row">-->
<!--                    &lt;!&ndash;        Layyout for small&ndash;&gt;-->
<!--                    <div class=" d-block d-md-none col-sm-12 mx-3 my-1 p-2 view" *ngFor="let item of toxicAgrochems">-->
<!--                      <div class="media item" routerLink="/agrochem/details/{{item.id}}">-->
<!--                        <div class="card-img-container">-->
<!--                          <img class="details-image img-fluid"-->
<!--                               width="70px"-->
<!--                               height="70px"-->
<!--                               [defaultImage]="defaultImage"-->
<!--                               [lazyLoad]="baseUrl+'image/70/70/'+item.image">-->
<!--                        </div>-->
<!--                        &lt;!&ndash;            <img src="{{baseUrl}}image/100/67/{{item.image? item.image: 'default.png'}}" class="mr-3" alt="...">&ndash;&gt;-->
<!--                        <div class="media-body d-flex align-items-center" style="height: 70px; margin-left:5px">-->
<!--                          <div class="card-title-container"><a class="card-title" routerLink="/agrochem/details/{{item.id}}">{{item.product_name}}</a></div>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                    &lt;!&ndash;        Layyout for small&ndash;&gt;-->
<!--                    &lt;!&ndash;        Layout for medium and Larger&ndash;&gt;-->
<!--                    <div class="d-none d-md-block col-xs-6 col-sm-6 col-md-4 col-lg-3 pt-md-3 " *ngFor="let item of toxicAgrochems">-->
<!--                      <div  routerLink="/agrochem/details/{{item.id}}" class="item">-->
<!--                            <div class="view overlay zoom">-->
<!--                              <mdb-card class="shadow-sm">-->
<!--                                &lt;!&ndash;Card image&ndash;&gt;-->
<!--                                <img class="details-image img-fluid"-->
<!--                                     width="100%"-->
<!--                                     height="auto"-->
<!--                                     [defaultImage]="defaultImage"-->
<!--                                     [lazyLoad]="baseUrl+'image/245/160/'+item.image"-->
<!--                                     alt="Sample image"/>-->
<!--                                &lt;!&ndash;                <mdb-card-img src="{{baseUrl}}image/245/160/{{item.image? item.image: 'default.png'}}" alt="Image Not Found"></mdb-card-img>&ndash;&gt;-->
<!--                                &lt;!&ndash;Card content&ndash;&gt;-->
<!--                                &lt;!&ndash;                                  <mdb-card-body>&ndash;&gt;-->
<!--                                &lt;!&ndash;Title&ndash;&gt;-->
<!--                                <mdb-card-title>-->
<!--                                  <div class="card-title-container"><a class="card-title" routerLink="/agrochem/details/{{item.id}}">{{item.product_name}}</a></div>-->
<!--                                </mdb-card-title>-->
<!--                                &lt;!&ndash;                                  </mdb-card-body>&ndash;&gt;-->
<!--                              </mdb-card>-->
<!--                              <div class="mask flex-center rgba-red-light" *ngIf="item.toxic"></div>-->
<!--                              <div class="mask flex-center rgba-red-slight" *ngIf="!item.toxic"></div>-->
<!--                            </div>-->
<!--                          </div>-->
<!--                    </div>-->
<!--                    &lt;!&ndash;        Layout for medium and Larger&ndash;&gt;-->
<!--                  </div>-->
<!--                  &lt;!&ndash; Content &ndash;&gt;-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
        <!-- Grid Column -->

        <!-- Grid Column -->
        <div class="col-12 references"  *ngIf="pestsDiseaseWeed.references">
          <div class="soft-shadow-upper-2">
            <div class="soft-shadow-lower-2">
              <div class="item-section">
                <!-- Title -->
                <div class="item-section-title">
                  <h2 class="category-title mt-5 mb-1"><strong class="">References</strong></h2>
                </div>
                <!-- Title -->
                <div class="item-section-content">
                  <!-- Content -->
                  <div class="description-paragraph dark-grey-text ">
                    <div class="ck-content" [innerHTML]="pestsDiseaseWeed.references | unescape" ></div>
                  </div>
                  <!-- Content -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Grid Column -->

      </div>
      <!-- Grid row -->

    </section>
  </div>
</div>

