import { Component, OnInit } from '@angular/core';
import {environment} from '../../../../environments/environment';
import {HomemadeOrganic} from '../../../shared/models/homemade_organic';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute} from '@angular/router';
import {HomemadeOrganicService} from '../../../shared/services/homemade-organic.service';
import {MessageService} from '../../../shared/messages/message.service';
import {MediaService} from '../../../shared/services/media.service';
import {Media} from '../../../shared/models/media';

@Component({
  selector: 'app-media-details',
  templateUrl: './media-details.component.html',
  styleUrls: ['./media-details.component.css']
})
export class MediaDetailsComponent implements OnInit {

  baseUrl = environment.baseUrl;
  rootUrl = environment.rootUrl;
  defaultImage = environment.defaultImage;
  errorImage = environment.errorImage;
  id: any;
  loading = true;
  loaded = false;
  error = false;
  media: Media;

  constructor(
    private titleService: Title,
    private route: ActivatedRoute,
    private mediaService: MediaService,
    public messageService: MessageService,
  ) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.params.id;
    this.getItem();



  }

  private getItem() {
    // Get homemade organic item based on id
    this.mediaService.findById(this.id).subscribe(response => {
        this.media = response.data;
        this.loading = false;
        this.loaded = true;
        this.error = false;
        this.titleService.setTitle(this.media.name);
      },
      error => {
        // Error handling
        this.messageService.showMessage('info', 'Not found', 'Media item not found');
        this.loading = false;
        this.error = true;
      });
  }
}
