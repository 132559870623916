import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'reproduction'
})
export class ReproductionPipe implements PipeTransform {

  transform(value: string): string {
    switch (value){
      case 'Yes':
        return 'Studies have shown that this product causes reproductive impairment';
      case 'No':
        return 'No studies have linked this product to cases of reproductive impairment.';
      case 'Possible':
        return 'Although no direct causation has been proven, this product may cause reproductive impairment.';
      case 'No Data':
        return 'No data/ studies exist on this product in relation to reproductive impairment. the precautionary principle applies. Please avoid using if in doubt.';
      default:
        return '';
    }
  }

}
