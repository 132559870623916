<div class="d-flex justify-content-center vh-100" *ngIf="loading">
  <div class="flex-center">
    <div class="spinner-grow text-light" style="width: 6rem; height: 6rem;"  role="status">
      <span class="sr-only">Loading...</span>
    </div>
    <span class="component-title orb">Loading...</span>
  </div>
</div>

<app-not-found *ngIf="error"></app-not-found>

<div *ngIf="loaded">
  <mdb-breadcrumb>
    <mdb-breadcrumb-item routerLink="/" class="blue-text">Home</mdb-breadcrumb-item>
    <mdb-breadcrumb-item routerLink="/posts" class="blue-text">Posts</mdb-breadcrumb-item>
    <mdb-breadcrumb-item class="active">{{post.name}}</mdb-breadcrumb-item>
  </mdb-breadcrumb>

  <div class="container">

    <section class="component-content">
      <div class="row mb-1">
      </div>


      <div class="p-2 px-md-5 pt-md-5 pb-md-1">
        <div class="col-sm  text-center">
          <span class="component-title">
            {{post.name}}
          </span>
        </div>
        <!-- Section heading -->
<!--                <h2 class="h1-responsive small-heading text-center my-3">{{post.name}}</h2>-->
        <!-- Section description -->
<!--        <p class="text-center dark-grey-text w-responsive mx-auto mb-5"><em>{{post.name}}</em></p>-->

        <!-- Grid row -->
        <div class="row">
          <!-- Grid column -->
          <div class="col-lg-12 col-md-12">
            <!-- Image -->
            <div class="mb-4">
              <div class="view overlay rounded z-depth-1-half mb-4">
                <!--                <img class="details-image img-fluid" width="100%" height="auto" src="{{baseUrl}}image/600/400/{{pestsDiseaseWeed.image? pestsDiseaseWeed.image: 'default.png'}}" alt="Sample image">-->
                <img class="details-image img-fluid"
                     width="100%"
                     height="auto"
                     [defaultImage]="defaultImage"
                     [errorImage]="errorImage"
                     offset="1500"
                     [lazyLoad]="rootUrl+'media/'+post.image_token">
                <a>
                  <div class="mask rgba-white-slight waves-light" mdbWavesEffect></div>
                </a>
              </div>
            </div>
            <!-- Image -->
          </div>
          <!-- Grid column -->
          <div class="col-lg-12 col-md-12">
            <!-- Grid row -->
            <div class="row mb-3" *ngIf="post.tags?.length > 0">
              <div class="col-md-11 col-10">
                <h4 class="small-heading mb-1 minor-items-title">Tags</h4>
                <ul class="list-inline">
                  <li class="list-inline-item minor-items-content" *ngFor="let tag of post.tags">
                    <mdb-badge>
                      <a routerLink="/posts/tag/{{tag}}">{{tag}}</a>
                    </mdb-badge>
                  </li>
                </ul>
              </div>
            </div>
            <!-- Grid row -->
          </div>
          <!-- Grid column -->
        </div>
        <!-- Grid row -->
      </div>

      <!-- Grid row -->
      <div class="row">
        <!-- Grid Column -->
        <div class="col-12 default" *ngIf="post.content">
          <div class="soft-shadow-upper-2">
            <div class="soft-shadow-lower-2">
              <div class="item-section">
                <!-- Title -->
                <div class="item-section-title">
                  <h2 class="category-title mt-5 mb-1"><strong class=""></strong></h2>
                </div>
                <!-- Title -->
                <div class="item-section-content">
                  <!-- Content -->
                  <p class="ck-content dark-grey-text " [innerHTML]="post.content | unescape">
                  </p>
                  <!-- Content -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Grid Column -->
      </div>
      <!-- Grid row -->

      <!-- Grid row -->
      <div class="row">
        <!-- Grid Column -->
        <div class="col-12 default-two" *ngIf="post.downloads?.length < 0">
          <div class="">
            <div class="">
              <div class="item-section">
                <!-- Title -->
                <div class="item-section-title">
                  <h2 class="category-title mt-5 mb-1"><strong class="">Downloads</strong></h2>
                </div>
                <!-- Title -->
                <div class="item-section-content">
                  <!-- Content -->
                  <div class="row" *ngFor="let download of post.downloads">
                    <!--        Layout for Small Devices-->
                    <div class=" d-block d-md-none col-sm-12 mx-3 my-1 p-2">
                      <div class="view zoom">
                        <mdb-card class="shadow-none">
                          <!--Card image-->
                          <div class="card-img-container">
                            <img class="details-image img-fluid"
                                 width="100%"
                                 height="auto"
                                 [defaultImage]="defaultImage"
                                 [errorImage]="errorImage"
                                 offset="1500"
                                 [lazyLoad]="rootUrl+'dow/245/160/'+download.token"
                                 alt="{{download.name}}"/>
                          </div>
                          <mdb-card-title>
                            <div class="">
                              <div *ngIf="download.token | isEmpty; else hasDownloadToken">
                                <div class="card-body">
                                  <h5 class="card-title">{{download.name}}</h5>
                                  <p class="card-text">
                                    {{download.description}}
                                  </p>
                                  <a class="btn btn-sm btn-dark" href="{{download.url}}" target="_blank">
                                    <i class="fas fa-download"></i>
                                    Download
                                  </a>
                                </div>
                              </div>
                              <ng-template #hasDownloadToken>
                                <div class="card-body">
                                  <h5 class="card-title">{{download.name}}</h5>
                                  <p class="card-text">
                                    {{download.description}}
                                  </p>
                                  <a class="btn btn-sm btn-dark" href="{{rootUrl}}download/{{download.token}}">
                                    <i class="fas fa-download"></i>
                                    Download
                                  </a>
                                </div>
                              </ng-template>
                            </div>
                          </mdb-card-title>
                          <!--                      </mdb-card-body>-->
                        </mdb-card>
                      </div>
                    </div>
                    <!--        Layyout for small-->

                    <!--        Layout for medium and Larger-->
                    <div class="d-none d-md-block col-xs-12 col-sm-12 col-md-12 col-lg-12 pt-md-3 ">
                      <div  class="item">
                        <div class="zoom">
                          <div class="p-2 card shadow-none">
                            <div class="media">
                              <div class="">
                                <img class="details-image img-fluid"
                                     width="170px"
                                     height="170px"
                                     [defaultImage]="defaultImage"
                                     [errorImage]="errorImage"
                                     offset="1500"
                                     [lazyLoad]="rootUrl+'media/170/170/'+download.thumbnail">
                              </div>
                              <div class="media-body d-flex align-items-lg-start" style="margin-left:5px">
                                <div *ngIf="download.token | isEmpty; else hasDownloadToken">
                                  <div class="card-body">
                                    <h5 class="card-title">{{download.name}}</h5>
                                    <p class="card-text">
                                      {{download.description}}
                                    </p>
                                    <a class="btn btn-sm btn-dark" href="{{download.url}}" target="_blank">
                                      <i class="fas fa-download"></i>
                                      Download
                                    </a>
                                  </div>
                                </div>
                                <ng-template #hasDownloadToken>
                                  <div class="card-body">
                                    <h5 class="card-title">{{download.name}}</h5>
                                    <p class="card-text">
                                      {{download.description}}
                                    </p>
                                    <a class="btn btn-sm btn-dark" href="{{rootUrl}}download/{{download.token}}">
                                      <i class="fas fa-download"></i>
                                      Download
                                    </a>
                                  </div>
                                </ng-template>
                              </div>
                            </div>
                          </div>
                          <div class="mask flex-center rgba-cyan-slight">
                          </div>
                        </div>
                      </div>
                    </div>
                    <!--        Layout for medium and Larger-->
                  </div>
                  <!-- Content -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Grid Column -->
      </div>
      <!-- Grid row -->

      <!-- Grid row -->
      <div class="row">
        <!-- Grid Column -->
        <div class="col-12 default-two" *ngIf="post.media?.length < 0">>
          <div class="">
            <div class="">
              <div class="item-section">
                <!-- Title -->
                <div class="item-section-title">
                  <h2 class="category-title mt-5 mb-1"><strong class="">Media</strong></h2>
                </div>
                <!-- Title -->
                <div class="item-section-content">
                  <!-- Content -->
                  <div class="row" *ngFor="let item of post.media" routerLink="/media/details/{{item.id}}">
                    <!--        Layout for Small Devices-->
                    <div class=" d-block d-md-none col-sm-12 mx-3 my-1 p-2 border-item">
                      <div class="view zoom">
                        <mdb-card class="shadow-none">
                          <!--Card image-->
                          <div class="card-img-container">
                            <img class="details-image img-fluid"
                                 width="100%"
                                 height="auto"
                                 [defaultImage]="defaultImage"
                                 [errorImage]="errorImage"
                                 offset="1500"
                                 [lazyLoad]="rootUrl+'media/245/160/'+item.token"
                                 alt="{{item.name}}"/>
                          </div>
                          <mdb-card-title>
                            <div class="">
                              <div class="p-3">
                                <h5 class="card-title">{{item.name}}</h5>
                                <p class="card-text">
                                  {{item.description}}
                                </p>
                                <!--                          <a class="btn btn-sm btn-dark" href="{{item.url}}" target="_blank">-->
                                <!--                            <i class="fas fa-download"></i>-->
                                <!--                            Download-->
                                <!--                          </a>-->
                              </div>
                            </div>
                          </mdb-card-title>
                          <!--                      </mdb-card-body>-->
                        </mdb-card>
                      </div>
                    </div>
                    <!--        Layyout for small-->

                    <!--        Layout for medium and Larger-->
                    <div class="d-none d-md-block col-xs-12 col-sm-12 col-md-12 col-lg-12 pt-md-3 ">
                      <div  class="item">
                        <div class="zoom">
                          <div class="p-2 card shadow-none">
                            <div class="media">
                              <div class="">
                                <img class="details-image img-fluid"
                                     width="170px"
                                     height="170px"
                                     [defaultImage]="defaultImage"
                                     [errorImage]="errorImage"
                                     offset="1500"
                                     [lazyLoad]="rootUrl+'media/170/170/'+item.token">
                              </div>
                              <div class="media-body d-flex align-items-lg-start" style="margin-left:5px">
                                <div class="card-body">
                                  <h5 class="card-title">{{item.name}}</h5>
                                  <p class="card-text">
                                    {{item.description}}
                                  </p>
                                  <!--                          <a class="btn btn-sm btn-dark" href="{{item.url}}" target="_blank">-->
                                  <!--                            <i class="fas fa-download"></i>-->
                                  <!--                            Download-->
                                  <!--                          </a>-->
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="mask flex-center rgba-cyan-slight">
                          </div>
                        </div>
                      </div>
                    </div>
                    <!--        Layout for medium and Larger-->
                  </div>
                  <!-- Content -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Grid Column -->
      </div>
      <!-- Grid row -->

    </section>
  </div>
</div>

