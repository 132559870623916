import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MessageComponent} from './messages/message/message.component';
import {HighlightPipe} from './pipes/highlight/highlight.pipe';
import {ToStringPipe} from './pipes/to-string/to-string.pipe';
import { NotFoundComponent } from './not-found/not-found.component';
import {AppRoutingModule} from '../app-routing.module';
import { UnescapePipe } from './pipes/unescape/unescape.pipe';
import { IsEmptyPipe } from './pipes/is-empty/is-empty.pipe';
import { ToxicPipe } from './pipes/toxic/toxic.pipe';
import { WhoClassificationPipe } from './pipes/who-classification/who-classification.pipe';
import { ActiveIngredientsDescriptionPipe } from './pipes/active-ingredients/active-ingredients-description/active-ingredients-description.pipe';
import { CarcinogenicityPipe } from './pipes/active-ingredients/carcinogenicity/carcinogenicity.pipe';
import { MutagenicityPipe } from './pipes/active-ingredients/mutagenicity/mutagenicity.pipe';
import { EdcPipe } from './pipes/active-ingredients/edc/edc.pipe';
import { ReproductionPipe } from './pipes/active-ingredients/reproduction/reproduction.pipe';
import { NeutoroxicantPipe } from './pipes/active-ingredients/neurotoxicant/neutoroxicant.pipe';
import { EuApprovedPipe } from './pipes/active-ingredients/eu-approved/eu-approved.pipe';
import { BeesColorPipe } from './pipes/active-ingredients/bees-color/bees-color.pipe';
import { AquaticColorPipe } from './pipes/active-ingredients/aquatic-color/aquatic-color.pipe';
import { AlgaeColorPipe } from './pipes/active-ingredients/algae-color/algae-color.pipe';
import { EarthwormColorPipe } from './pipes/active-ingredients/earthworm-color/earthworm-color.pipe';
import { BirdsColorPipe } from './pipes/active-ingredients/birds-color/birds-color.pipe';
import { LeachabilityColorPipe } from './pipes/active-ingredients/leachability-color/leachability-color.pipe';
import { WhoClassColorPipe } from './pipes/active-ingredients/who-class-color/who-class-color.pipe';
import { CarcinogenicityColorPipe } from './pipes/active-ingredients/carcinogenicity-color/carcinogenicity-color.pipe';
import { MutagenicityColorPipe } from './pipes/active-ingredients/mutagenicity-color/mutagenicity-color.pipe';
import { EdcColorPipe } from './pipes/active-ingredients/edc-color/edc-color.pipe';
import { ReproductionColorPipe } from './pipes/active-ingredients/reproduction-color/reproduction-color.pipe';
import { NeurotoxicantColorPipe } from './pipes/active-ingredients/neurotoxicant-color/neurotoxicant-color.pipe';
import { EuApprovedColorPipe } from './pipes/active-ingredients/eu-approved-color/eu-approved-color.pipe';


@NgModule({
  declarations: [
    MessageComponent,
    HighlightPipe,
    ToStringPipe,
    NotFoundComponent,
    UnescapePipe,
    IsEmptyPipe,
    ToxicPipe,
    WhoClassificationPipe,
    ActiveIngredientsDescriptionPipe,
    CarcinogenicityPipe,
    MutagenicityPipe,
    EdcPipe,
    ReproductionPipe,
    NeutoroxicantPipe,
    EuApprovedPipe,
    BeesColorPipe,
    AquaticColorPipe,
    AlgaeColorPipe,
    EarthwormColorPipe,
    BirdsColorPipe,
    LeachabilityColorPipe,
    WhoClassColorPipe,
    CarcinogenicityColorPipe,
    MutagenicityColorPipe,
    EdcColorPipe,
    ReproductionColorPipe,
    NeurotoxicantColorPipe,
    EuApprovedColorPipe,
  ],
  imports: [
    CommonModule,
    AppRoutingModule
  ],
  exports: [
    MessageComponent,
    HighlightPipe,
    ToStringPipe,
    NotFoundComponent,
    UnescapePipe,
    IsEmptyPipe,
    ToxicPipe,
    WhoClassificationPipe,
    ActiveIngredientsDescriptionPipe,
    CarcinogenicityPipe,
    MutagenicityPipe,
    EdcPipe,
    ReproductionPipe,
    NeutoroxicantPipe,
    EuApprovedPipe,
    BeesColorPipe,
    EarthwormColorPipe,
    AquaticColorPipe,
    LeachabilityColorPipe,
    WhoClassColorPipe,
    BirdsColorPipe,
    CarcinogenicityColorPipe,
    MutagenicityColorPipe,
    EdcColorPipe,
    ReproductionColorPipe,
    NeurotoxicantColorPipe,
    EuApprovedColorPipe,
  ]
})
export class SharedModule { }
