import { Component, OnInit } from '@angular/core';
import {Gap} from '../../shared/models/gap';
import {GapService} from '../../shared/services/gap.service';
import {AppSettings} from '../../app.settings';
import {MessageService} from '../../shared/messages/message.service';
import {environment} from '../../../environments/environment';
import {ActivatedRoute, Router} from '@angular/router';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-gap-dashboard',
  templateUrl: './gap-dashboard.component.html',
  styleUrls: ['./gap-dashboard.component.css']
})
export class GapDashboardComponent implements OnInit {


  totalRecords: number;
  to: number;
  from: number;
  currentPage: number;
  lastPage: number;
  perPage = '20';
  perPageChanged = false;
  page: string;
  orderColumn = 'id';
  orderDirection = 'desc';
  orderChanged = false;
  ordering = '';
  searchValue = '';


  baseUrl = environment.baseUrl;
  defaultImage = environment.defaultImage;
  errorImage = environment.errorImage;
  loading = true;
  loaded = false;
  error = false;
  gap: Gap[];
  title: string;
  category: string;
  categoryPath: string;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public messageService: MessageService,
    private titleService: Title,
    private gapService: GapService,
  ) { }

  ngOnInit(): void {
    // const currentUrl = this.router.url;
    const currentUrl = this.route.routeConfig.path;
    switch (currentUrl){
      case 'gap/category/cultural':
        this.route.params.subscribe(routeParams => {
          // Get page query param
          this.route.queryParams.subscribe(params => {
            // Defaults to 0 if no query param provided.
            this.page = params.page || '1';
            this.searchValue = params.q || '';
            this.perPage = params.limit || '20';
            this.ordering = params.ordering || '#';
            this.setOrdering(this.ordering);
            this.loading = true;
            this.loaded = false;
            this.categoryPath = '/category/cultural';
            this.getByCategory('cultural', 'Cultural Methods');
          });
        });
        break;
      case 'gap/category/physical':
        this.route.params.subscribe(routeParams => {
          // Get page query param
          this.route.queryParams.subscribe(params => {
            // Defaults to 0 if no query param provided.
            this.page = params.page || '1';
            this.searchValue = params.q || '';
            this.perPage = params.limit || '20';
            this.ordering = params.ordering || '#';
            this.setOrdering(this.ordering);
            this.loading = true;
            this.loaded = false;
            this.categoryPath = '/category/physical';
            this.getByCategory('physical', 'Physical & Mechanical Methods');
          });
        });
        break;
      case 'gap/category/prevention':
        this.route.params.subscribe(routeParams => {
          // Get page query param
          this.route.queryParams.subscribe(params => {
            // Defaults to 0 if no query param provided.
            this.page = params.page || '1';
            this.searchValue = params.q || '';
            this.perPage = params.limit || '20';
            this.ordering = params.ordering || '#';
            this.setOrdering(this.ordering);
            this.loading = true;
            this.loaded = false;
            this.categoryPath = '/category/prevention';
            this.getByCategory('prevention', 'Prevention Methods');
          });
        });
        break;
      case 'gap':
        // Get page query param
        this.route.queryParams.subscribe(params => {
          // Defaults to 0 if no query param provided.
          this.page = params.page || '1';
          this.searchValue = params.q || '';
          this.perPage = params.limit || '20';
          this.ordering = params.ordering || '#';
          this.setOrdering(this.ordering);
          this.loading = true;
          this.loaded = false;
          this.categoryPath = '';
          this.getByCategory('', 'Good Agricultural Practices');
        });
        break;
      default:
        this.router.navigateByUrl('/404');
        break;
    }

  }





  private getAll(title) {
    this.gap = [];
    this.title = title;
    this.titleService.setTitle(this.title);
    // Fetch GAP items
    this.gapService.getAll(this.page, this.perPage, this.orderColumn, this.orderDirection).subscribe(response => {
        this.gap = response.data;
        this.page = response.meta.page;
        this.currentPage = response.meta.current_page;
        this.lastPage = response.meta.last_page;
        this.from = response.meta.from;
        this.to = response.meta.to;
        this.loading = false;
        this.loaded = true;
        this.error = false;
      },
      error => {
        // Error handling
        this.messageService.showMessage('info', 'Not found', 'Good application practices not found');
        this.loading = false;
        this.error = true;
      });
  }

  // Fetch gap items by category
  private getCategory(category: string, title: string) {
    this.gap = [];
    this.title = title;
    this.titleService.setTitle(this.title);
    this.gapService.getByCategory(category, this.page, this.perPage, this.orderColumn, this.orderDirection).subscribe(response => {
        this.gap = response.data;
        this.totalRecords = response.meta.total;
        this.page = response.meta.page;
        this.currentPage = response.meta.current_page;
        this.lastPage = response.meta.last_page;
        this.from = response.meta.from;
        this.to = response.meta.to;
        this.loading = false;
        this.loaded = true;
        this.error = false;
      },
      error => {
        this.messageService.showMessage('info', 'Not found', title + ' not found');
        this.loading = false;
        this.error = true;
      });
  }

  // Fetch gap names by category
  private getByCategory(category: string, title: string) {
    this.gap = [];
    this.title = title;
    this.titleService.setTitle(this.title);
    this.category = category;

    // Fetch gap items based on the type
    this.filter(this.category, this.page, this.perPage, this.orderColumn, this.orderDirection, this.searchValue);
  }

  private filter(category = '', page = '', perPage = '', orderColumn = '', orderDirection = '', searchValue = '') {
    this.gapService.getNamesByCategory(category, page, perPage, orderColumn, orderDirection, searchValue).subscribe(response => {
        this.gap = response.data;
        this.totalRecords = response.total;
        this.page = response.page;
        this.currentPage = response.current_page;
        this.lastPage = response.last_page;
        this.from = response.from;
        this.to = response.to;
        this.loading = false;
        this.loaded = true;
        this.error = false;
      },
      error => {
        // Error handling
        this.messageService.showMessage('info', 'Not found', this.title + 's not found');
        this.loading = false;
        this.error = true;
      }
    );
  }

// Capitalize the first character of a string
  private capitalize(word: string)  {
    return word.charAt(0).toUpperCase() + word.slice(1);
  }

  public goToPage(page){
    this.loading = true;
    this.loaded = false;
    this.page = page;
    // Load in same page
    // this.filter(this.type, this.page, this.perPage, this.orderColumn, this.orderDirection, this.searchValue);
    // Navigate to page
    this.router.navigateByUrl(`/gap${this.categoryPath ? this.categoryPath : ''}?page=${page}${this.orderChanged ? '&ordering=' + this.ordering : ''}${this.searchValue !== '' ? '&q=' + this.searchValue : ''}${this.perPage !== '20' ? '&limit=' + this.perPage : ''}`);

  }
  // Handles an item search based on search value
  public search(){
    this.loading = true;
    this.loaded = false;
    this.page = '1';
    this.filter(this.category, this.page, this.perPage, this.orderColumn, this.orderDirection, this.searchValue);
  }
  // Handle change in items per pagge to be displayed
  public itemsPerPageChanged(){
    this.loading = true;
    this.loaded = false;
    this.page = '1';
    this.perPageChanged = true;
    this.filter(this.category, this.page, this.perPage, this.orderColumn, this.orderDirection, this.searchValue);
  }
  // Handle change in sort order and field
  public sortOrderChanged() {
    const value = this.ordering;
    this.setOrdering(value);
    this.loading = true;
    this.loaded = false;
    this.page = '1';

    this.filter(this.category, this.page, this.perPage, this.orderColumn, this.orderDirection, this.searchValue);
  }
  private setOrdering(value){
    if (value.indexOf('!') === 0) {
      this.orderColumn = value.substring(1, value.length);
      this.orderDirection = 'desc';
      this.orderChanged = true;
    } else if (value === '#'){
      // Default Ordering
      this.orderColumn = 'id';
      this.orderDirection = 'desc';
      this.orderChanged = false;
    } else {
      this.orderColumn = value;
      this.orderDirection = 'asc';
      this.orderChanged = true;
    }
  }
}
