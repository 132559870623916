import {Component, ElementRef, OnChanges, OnInit, ViewChild} from '@angular/core';
import {MessageService} from '../message.service';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.css']
})
export class MessageComponent implements OnInit, OnChanges {
  @ViewChild('alert', { static: false }) alert: ElementRef;

  constructor(
    public messageService: MessageService,
  ) { }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
  }

  closeAlert() {
    this.messageService.clear();
    this.alert.nativeElement.classList.remove('show');
  }

}
