import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HowToComponent } from './how-to/how-to.component';
import {MDBBootstrapModule} from 'angular-bootstrap-md';
import {RouterModule} from '@angular/router';



@NgModule({
  declarations: [HowToComponent],
  imports: [
    CommonModule,
    MDBBootstrapModule,
    RouterModule,
  ],
  exports: [
    HowToComponent
  ]
})
export class HowToModule { }
