import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'euApproved'
})
export class EuApprovedPipe implements PipeTransform {

  transform(value: string): string {
    switch (value) {
      case 'Yes':
        return 'This product is still in use with the European Union';
      case 'No':
        return 'This product has been banned in the European Union';
      default:
        return '';
    }
  }

}
