import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'edc'
})
export class EdcPipe implements PipeTransform {

  transform(value: string): string {
    switch (value) {
      case 'Yes':
        return 'Scientific studies have shown that this product has been linked to incidences of endocrine disruption.';
      case 'No':
        return 'No scientific studies link this product to incidences of endocrine disruption.';
      case 'Possible':
        return 'Although it has not been conclusively determined that this product does not or causes endocrine disruption, the precautionary principle applies. Please avoid using if in doubt';
      case 'No Data':
        return 'No data/ studies exist on this product in relation to endocrine disruption. the precautionary principle applies. Please avoid using if in doubt.';
      default:
        return '';
    }
  }

}
