<div class="d-flex justify-content-center vh-100" *ngIf="loading">
  <div class="flex-center">
    <div class="spinner-grow text-light" style="width: 6rem; height: 6rem;"  role="status">
      <span class="sr-only">Loading...</span>
    </div>
    <span class="component-title orb">Loading...</span>
  </div>
</div>

<app-not-found *ngIf="error"></app-not-found>

<div *ngIf="loaded">
  <mdb-breadcrumb>
    <mdb-breadcrumb-item routerLink="/" class="blue-text">Home</mdb-breadcrumb-item>
    <mdb-breadcrumb-item routerLink="/agrochem" class="blue-text">Agrochem Products</mdb-breadcrumb-item>
    <mdb-breadcrumb-item class="active">{{agrochem.product_name}}</mdb-breadcrumb-item>
  </mdb-breadcrumb>

  <div class="container">

    <section class="component-content">
      <div class="row mb-1">
      </div>


      <div class="agrochem-{{agrochem.toxic ? 'toxic': 'non-toxic'}}">
        <div class="p-2 px-md-5 pt-md-5 pb-md-1">
          <!-- Grid row -->
          <div class="row">
            <!-- Grid column -->
            <div class="col-md-12 display-5 mb-3">
            <span class="h1-responsive montserrat-font font-weight-bold">
              {{agrochem.product_name}}
            </span><br>
              <!-- Section heading -->
              <span class="h4-responsive montserrat-font font-weight-400 my-2">{{agrochem.distributing_company}}</span>
            </div>
            <!-- Grid column -->

            <!-- Grid column -->
            <div class="col-lg-6 col-md-12">
              <!-- Image -->
              <div class="mb-4">
                <div class="view overlay rounded z-depth-1-half mb-4">
                  <!--                  <img class="details-image img-fluid" width="100%" height="auto" src="{{baseUrl}}image/600/400/{{agrochem.image? agrochem.image: 'default.png'}}" alt="Sample image">-->
                  <img class="details-image img-fluid"
                       width="100%"
                       height="auto"
                       [defaultImage]="defaultImage"
                       [lazyLoad]="baseUrl+'image/600/400/'+agrochem.image">
                  <a>
                    <div class="mask rgba-white-slight waves-light" mdbWavesEffect></div>
                  </a>
                </div>
              </div>
              <!-- Image -->
            </div>
            <!-- Grid column -->

            <!-- Grid column -->
            <div class="col-lg-6 col-md-12">
              <div class="">

                <!-- Grid row -->
                <div class="row mb-3" *ngIf="agrochem.crops?.length > 0">
                  <div class="col-md-11 col-10">
                    <h4 class="small-heading mb-1  minor-items-title">
                      Crops<br>
                      <small *ngIf="agrochem.toxic" class="font-weight-400 mb-1"><em>Select to view safer alternatives</em></small>
                    </h4>
                    <ul class="list-inline">
                      <li class="list-inline-item dark-grey-text minor-items-content" *ngFor="let crop of agrochem.crops">
                        <mdb-badge>
                          <a routerLink="/crops/details/{{crop.id}}">{{crop.name}}</a>
                        </mdb-badge>
                      </li>
                    </ul>
                  </div>
                </div>
                <!-- Grid row -->

                <!-- Grid row -->
                <div class="row mb-3" *ngIf="agrochem.active_ingredients?.length > 0">
                  <div class="col-md-11 col-10">
                    <h4 class="small-heading mb-1 minor-items-title">Active Ingredients</h4>
                    <ul class="list-inline">
                      <li class="list-inline-item dark-grey-text minor-items-content" *ngFor="let active_ingredient of agrochem.active_ingredients">
                        <mdb-badge>
                          <a routerLink="/active-ingredients/details/{{active_ingredient.id}}">{{active_ingredient.name}}</a>
                        </mdb-badge>
                      </li>
                    </ul>
                  </div>
                </div>
                <!-- Grid row -->

                <!-- Grid row -->
                <div class="row mb-3" *ngIf="agrochem.pests_disease_weed?.length > 0">
                  <div class="col-md-11 col-10">
                    <h4 class="small-heading mb-1 minor-items-title">
                      Pests, Diseases, Weeds<br>
                      <small *ngIf="agrochem.toxic" class="font-weight-400 mb-1"><em>Select to view safer alternatives</em></small>
                    </h4>
                    <ul class="list-inline">
                      <li class="list-inline-item dark-grey-text minor-items-content" *ngFor="let pest_disease_weed of agrochem.pests_disease_weed">
                        <mdb-badge>
                          <a routerLink="/pdw/details/{{pest_disease_weed.id}}">{{pest_disease_weed.name}}</a>
                        </mdb-badge>
                      </li>
                    </ul>
                  </div>
                </div>
                <!-- Grid row -->

              </div>
            </div>
            <!-- Grid column -->
          </div>
          <!-- Grid row -->
        </div>
      </div>

      <!-- Grid row -->
      <div class="row">
        <!-- Grid Column -->
        <div class="col-12">
          <table mdbTable>
            <thead>
            </thead>
            <tbody>
            <tr mdbTableCol *ngIf="agrochem.toxic != null">
              <th><strong>Toxic</strong></th>
              <td>{{agrochem.toxic | toxic}}</td>
            </tr>
            <tr mdbTableCol *ngIf="agrochem.pcpb_number">
              <th><strong>PCPB Number</strong></th>
              <td>{{agrochem.pcpb_number}}</td>
            </tr>
            <tr mdbTableCol *ngIf="agrochem.who_class">
              <th><strong>WHO Classification</strong></th>
              <td>{{agrochem.who_class | whoClassification}}</td>
            </tr>
            <tr mdbTableCol *ngIf="agrochem.composition">
              <th><strong>Composition</strong></th>
              <td>{{agrochem.composition}}</td>
            </tr>
            <tr mdbTableCol *ngIf="agrochem.phi_days">
              <th><strong>Pre Harvest Interval (PHI)</strong></th>
              <td>{{agrochem.phi_days}} days</td>
            </tr>
            </tbody>
          </table>
        </div>
        <!-- Grid Column -->

        <!-- Grid Column -->
        <div class="col-12 description" *ngIf="agrochem.distributing_company">
          <div class="soft-shadow-upper">
            <div class="soft-shadow-lower">
              <div class="item-section">
                <!-- Title -->
                <div class="item-section-title">
                  <h2 class="category-title mt-5 mb-1"><strong class="">Distribution Company</strong></h2>
                </div>
                <!-- Title -->
                <div class="item-section-content">
                  <!-- Content -->
                  <div class="ck-content dark-grey-text " [innerHTML]="agrochem.distributing_company | unescape"></div>
                  <!-- Content -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Grid Column -->

        <!-- Grid Column -->
        <div class="col-12 description" *ngIf="agrochem.registrant">
          <div class="soft-shadow-upper">
            <div class="soft-shadow-lower">
              <div class="item-section">
                <!-- Title -->
                <div class="item-section-title">
                  <h2 class="category-title mt-5 mb-1"><strong class="">Registrant</strong></h2>
                </div>
                <!-- Title -->
                <div class="item-section-content">
                  <!-- Content -->
                  <div class="ck-content dark-grey-text " [innerHTML]="agrochem.registrant | unescape"></div>
                  <!-- Content -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Grid Column -->

        <!-- Grid Column -->
        <div class="col-12 gap" *ngIf="gapTotal > 0">
          <div class="soft-shadow-upper">
            <div class="soft-shadow-lower">
              <div class="item-section">
                <!-- Title -->
                <div class="item-section-title">
                  <h2 class="category-title mt-5 mb-1"><strong class="">Alternative Good Agricultural Practices</strong></h2>
                </div>
                <!-- Title -->
                <div class="item-section-content">
                  <!-- Content -->
                  <div style="display: -webkit-box; display: flex">
                    <div style="-webkit-box-flex:1; flex: 1">
                      <div class="accordion-tabs" *ngIf="gapPdw?.length > 0">
                        <div class="accordion-tab" *ngFor="let item of gapPdw">
                          <div *ngIf="item.gap?.length > 0">
                            <input type="checkbox" id="gap{{item.id}}"/>
                            <label class="accordion-tab-label" for="gap{{item.id}}">For {{item.name}}</label>
                            <div class="accordion-tab-content">
                              <ul class="list-inline">
                                <li class="list-inline-item dark-grey-text minor-items-content" *ngFor="let gap of item.gap">
                                  <mdb-badge>
                                    <a routerLink="/gap/details/{{gap.id}}">{{gap.name}}</a>
                                  </mdb-badge>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Content -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Grid Column -->

        <!-- Grid Column -->
        <div class="col-12 homemade-organic" *ngIf="homemadeOrganicTotal > 0">
          <div class="soft-shadow-upper">
            <div class="soft-shadow-lower">
              <div class="item-section">
                <!-- Title -->
                <div class="item-section-title">
                  <h2 class="category-title mt-5 mb-1"><strong class="">Alternative Homemade Solutions</strong></h2>
                </div>
                <!-- Title -->
                <div class="item-section-content">
                  <!-- Content -->
                  <div style="display: -webkit-box; display: flex">
                    <div style="-webkit-box-flex:1; flex: 1">
                      <div class="accordion-tabs" *ngIf="homemadeOrganicPdw?.length > 0">
                        <div class="accordion-tab" *ngFor="let item of homemadeOrganicPdw">
                          <div *ngIf="item.homemade_organic?.length > 0">
                            <input type="checkbox" id="hmo{{item.id}}"/>
                            <label class="accordion-tab-label" for="hmo{{item.id}}">For {{item.name}}</label>
                            <div class="accordion-tab-content">
                              <ul class="list-inline">
                                <li class="list-inline-item dark-grey-text minor-items-content" *ngFor="let homemadeOrganic of item.homemade_organic">
                                  <mdb-badge>
                                    <a routerLink="/homemade-organic/details/{{homemadeOrganic.id}}">{{homemadeOrganic.name}}</a>
                                  </mdb-badge>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Content -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Grid Column -->

        <!-- Grid Column -->
        <div class="col-12 commercial-organic" *ngIf="commercialOrganicTotal > 0">
          <div class="soft-shadow-upper">
            <div class="soft-shadow-lower">
              <div class="item-section">
                <!-- Title -->
                <div class="item-section-title">
                  <h2 class="category-title mt-5 mb-1"><strong class="">Alternative Offshelf Organic Products</strong></h2>
                </div>
                <!-- Title -->
                <div class="item-section-content">
                  <!-- Content -->
                  <div style="display: -webkit-box; display: flex">
                    <div style="-webkit-box-flex:1; flex: 1">
                      <div class="accordion-tabs" *ngIf="commercialOrganicPdw?.length > 0">
                        <div class="accordion-tab" *ngFor="let item of commercialOrganicPdw">
                          <div *ngIf="item.commercial_organic?.length > 0">
                            <input type="checkbox" id="cmo{{item.id}}"/>
                            <label class="accordion-tab-label" for="cmo{{item.id}}">For {{item.name}}</label>
                            <div class="accordion-tab-content">
                              <ul class="list-inline">
                                <li class="list-inline-item dark-grey-text minor-items-content" *ngFor="let commercialOrganic of item.commercial_organic">
                                  <mdb-badge>
                                    <a routerLink="/commercial-organic/details/{{commercialOrganic.id}}">{{commercialOrganic.name}}</a>
                                  </mdb-badge>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Content -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Grid Column -->
      </div>
      <!-- Grid row -->

    </section>
  </div>
</div>

