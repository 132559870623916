import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {GlobalSearchComponent} from './global-search/global-search.component';
import {GlobalSearchResultsComponent} from './global-search-results/global-search-results.component';
import {MDBBootstrapModule} from 'angular-bootstrap-md';
import {RouterModule} from '@angular/router';
import {SharedModule} from '../shared/shared.module';
import {LazyLoadImageModule} from 'ng-lazyload-image';



@NgModule({
  declarations: [
    GlobalSearchComponent,
    GlobalSearchResultsComponent
  ],
  imports: [
    CommonModule,
    MDBBootstrapModule,
    RouterModule,
    SharedModule,
    LazyLoadImageModule,
  ]
})
export class GlobalSearchModule { }
