<div class="d-flex justify-content-center vh-100" *ngIf="loading">
  <div class="flex-center">
    <div class="spinner-grow text-light" style="width: 6rem; height: 6rem;"  role="status">
      <span class="sr-only">Loading...</span>
    </div>
    <span class="component-title orb">Loading...</span>
  </div>

</div>

<app-not-found *ngIf="error"></app-not-found>

<div *ngIf="loaded">
  <mdb-breadcrumb>
    <mdb-breadcrumb-item routerLink="/" class="blue-text">Home</mdb-breadcrumb-item>
    <mdb-breadcrumb-item class="active">Search Resuslts</mdb-breadcrumb-item>
  </mdb-breadcrumb>

  <div class="container">

    <div class="component-content shadow-md">

      <div class="p-1 p-md-1 py-md-4">
        <!-- Grid column -->
        <div class="col-md-12 display-5 my-3">
          <span class="h1-responsive montserrat-font font-weight-bold">
          Search Results
          </span><br><br>
          <!-- Section heading -->
          <span class="h4-responsive montserrat-font font-weight-600 my-2 search-value">{{searchValue}}</span><br>
          <span class="h5-responsive montserrat-font font-weight-400 my-2 total-results">{{total}} results found</span><br>
          <span class="h5-responsive montserrat-font font-weight-400 my-2 total-results">Showing {{from}} - {{to}}</span>
        </div>
        <!-- Grid column -->

        <div class="row px-md-4 py-md-4">
          <div class=" col-lg-12 col-md-12  pt-md-1 item" *ngFor="let item of searchResults">
            <div [ngSwitch]="item.type">
              <div routerLink="/crops/details/{{item.searchable.id}}" *ngSwitchCase="'crops'">
                <div class="view overlay zoom">
                  <div class="p-2 card shadow-sm">
                    <div class="media">
                      <img class="d-flex mr-3" src="{{baseUrl}}image/70/70/{{item.image? item.image: 'default.png'}}" alt="Generic placeholder image">
                      <div class="media-body rubik-font">
                        <a  routerLink="">
                          <h3 class="my-0 rubik-font">
                            {{item.searchable.name}}<br>
                            <small class="result-category">Crops</small>
                          </h3>
                        </a>
                        <span>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="mask flex-center rgba-green-light">
                  </div>
                </div>
              </div>
              <div routerLink="/pdw/details/{{item.searchable.id}}" *ngSwitchCase="'pests_disease_weed'">
                <div class="view overlay zoom">
                  <div class="p-2 card shadow-sm">
                    <div class="media">
                      <img class="d-flex mr-3" src="{{baseUrl}}image/70/70/{{item.searchable.image? item.searchable.image: 'default.png'}}" alt="Generic placeholder image">
                      <div class="media-body rubik-font">
                        <a  routerLink="">
                          <h3 class="my-0 rubik-font">
                            {{item.searchable.name}}<br>
                            <small class="result-category">{{item.searchable.type}}</small>
                          </h3>
                        </a>
                        <div *ngFor="let itm of item.searchable | keyvalue">
                          <span *ngIf="containsValue(itm.key, itm.value, searchValue)">
                            <span [innerHTML]="itm.value | highlight : searchValue"></span>
                          </span>
                        </div>
                        <span>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="mask flex-center rgba-green-light">
                  </div>
                </div>
              </div>
              <div routerLink="/agrochem/details/{{item.searchable.id}}" *ngSwitchCase="'agrochem'">
                <div class="view overlay zoom">
                  <div class="p-2 card shadow-sm">
                    <div class="media">
                      <img class="d-flex mr-3" src="{{baseUrl}}image/70/70/{{item.searchable.image? item.searchable.image: 'default.png'}}" alt="Generic placeholder image">
                      <div class="media-body rubik-font">
                        <a  routerLink="">
                          <h3 class="my-0 rubik-font">
                            {{item.searchable.product_name}}<br>
                            <small class="result-category">Agrochem Products</small>
                          </h3>
                        </a>
                        <div *ngFor="let itm of item.searchable | keyvalue">
                          <span *ngIf="containsValue(itm.key, itm.value, searchValue)">
                            <span [innerHTML]="itm.value | highlight : searchValue"></span>
                          </span>
                        </div>
                        <span>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="mask flex-center rgba-green-light">
                  </div>
                </div>
              </div>
              <div routerLink="/active-ingredients/details/{{item.searchable.id}}" *ngSwitchCase="'active_ingredients'">
                <div class="view overlay zoom">
                  <div class="p-2 card shadow-sm">
                    <div class="media">
                      <img class="d-flex mr-3" src="{{baseUrl}}image/70/70/{{item.searchable.image? item.searchable.image: 'default.png'}}" alt="Generic placeholder image">
                      <div class="media-body rubik-font">
                        <a  routerLink="">
                          <h3 class="my-0 rubik-font">
                            {{item.searchable.name}}<br>
                            <small class="result-category">Active Ingredients</small>
                          </h3>
                        </a>
                        <div *ngFor="let itm of item.searchable | keyvalue">
                          <span *ngIf="containsValue(itm.key, itm.value, searchValue)">
                            <span [innerHTML]="itm.value | highlight : searchValue"></span>
                          </span>
                        </div>
                        <span>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="mask flex-center rgba-green-light">
                  </div>
                </div>
              </div>
              <div routerLink="/commercial-organic/details/{{item.searchable.id}}" *ngSwitchCase="'commercialorganic'">
                <div class="view overlay zoom">
                  <div class="p-2 card shadow-sm">
                    <div class="media">
                      <img class="d-flex mr-3" src="{{baseUrl}}image/70/70/{{item.searchable.image? item.searchable.image: 'default.png'}}" alt="Generic placeholder image">
                      <div class="media-body rubik-font">
                        <a  routerLink="">
                          <h3 class="my-0 rubik-font">
                            {{item.searchable.name}}<br>
                            <small class="result-category">Offshelf Organic</small>
                          </h3>
                        </a>
                        <div *ngFor="let itm of item.searchable | keyvalue">
                          <span *ngIf="containsValue(itm.key, itm.value, searchValue)">
                            <span [innerHTML]="itm.value | highlight : searchValue"></span>
                          </span>
                        </div>
                        <span>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="mask flex-center rgba-green-light">
                  </div>
                </div>
              </div>
              <div routerLink="/gap/details/{{item.searchable.id}}" *ngSwitchCase="'gap'">
                <div class="view overlay zoom">
                  <div class="p-2 card shadow-sm">
                    <div class="media">
                      <img class="d-flex mr-3" src="{{baseUrl}}image/70/70/{{item.searchable.image? item.searchable.image: 'default.png'}}" alt="Generic placeholder image">
                      <div class="media-body rubik-font">
                        <a  routerLink="">
                          <h3 class="my-0 rubik-font">
                            {{item.searchable.name}}<br>
                            <small class="result-category">Good Agricultural Practices</small>
                          </h3>
                        </a>
                        <div *ngFor="let itm of item.searchable | keyvalue">
                          <span *ngIf="containsValue(itm.key, itm.value, searchValue)">
                            <span [innerHTML]="itm.value| highlight : searchValue"></span>
                          </span>
                        </div>
                        <span>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="mask flex-center rgba-green-light">
                  </div>
                </div>
              </div>
              <div routerLink="/homemade-organic/details/{{item.searchable.id}}" *ngSwitchCase="'homemadeorganic'">
                <div class="view overlay zoom">
                  <div class="p-2 card shadow-sm">
                    <div class="media">
                      <img class="d-flex mr-3" src="{{baseUrl}}image/70/70/{{item.searchable.image? item.searchable.image: 'default.png'}}" alt="Generic placeholder image">
                      <div class="media-body rubik-font">
                        <a  routerLink="">
                          <h3 class="my-0 rubik-font">
                            {{item.searchable.name}}<br>
                            <small class="result-category">Homemade Solutions</small>
                          </h3>
                        </a>
                        <div *ngFor="let itm of item.searchable | keyvalue">
                          <span *ngIf="containsValue(itm.key, itm.value, searchValue)">
                            <span [innerHTML]="itm.value | highlight : searchValue"></span>
                          </span>
                        </div>
                        <span>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="mask flex-center rgba-green-light">
                  </div>
                </div>
              </div>
              <div routerLink="/control-methods/details/{{item.searchable.id}}" *ngSwitchCase="'control_methods'">
                <div class="view overlay zoom">
                  <div class="p-2 card shadow-sm">
                    <div class="media">
                      <img class="d-flex mr-3" src="{{baseUrl}}image/70/70/{{item.searchable.image? item.searchable.image: 'default.png'}}" alt="Generic placeholder image">
                      <div class="media-body rubik-font">
                        <a  routerLink="">
                          <h3 class="my-0 rubik-font">
                            {{item.searchable.name}}<br>
                            <small class="result-category">Control Methods - <span class="font-weight-500">{{item.searchable.category}}</span></small>
                          </h3>
                        </a>
                        <div *ngFor="let itm of item.searchable | keyvalue">
                          <span *ngIf="containsValue(itm.key, itm.value, searchValue)">
                            <span [innerHTML]="itm.value | highlight : searchValue"></span>
                          </span>
                        </div>
                        <span>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="mask flex-center rgba-green-light">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

