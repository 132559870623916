<div class="d-flex justify-content-center vh-100" *ngIf="loading">
  <div class="flex-center">
    <div class="spinner-grow text-light" style="width: 6rem; height: 6rem;"  role="status">
      <span class="sr-only">Loading...</span>
    </div>
    <span class="component-title orb">Loading...</span>
  </div>
</div>

<app-not-found *ngIf="error"></app-not-found>

<div *ngIf="loaded">
  <mdb-breadcrumb>
    <mdb-breadcrumb-item routerLink="/" class="blue-text">Home</mdb-breadcrumb-item>
    <mdb-breadcrumb-item routerLink="/control-methods" class="blue-text">Control Methods</mdb-breadcrumb-item>
    <mdb-breadcrumb-item class="active">
      <span  *ngIf="controlMethod.name | isEmpty; else noControlMethodName">
        Control methods for <span class="comma" *ngFor="let pwd of controlMethod.pests_diseases_weeds">{{pwd.name}}</span>
      </span>
      <ng-template #noControlMethodName>
        {{controlMethod.name}}
      </ng-template>
    </mdb-breadcrumb-item>
  </mdb-breadcrumb>

  <div class="container">

    <section class="component-content">
      <div class="row mb-1">
        <!--        <div class="col-sm  text-center">-->
        <!--          <span class="component-title">-->
        <!--            -->
        <!--          </span>-->
        <!--        </div>-->
      </div>


      <div class="control-method-{{controlMethod.category | lowercase}}">
        <div class="p-2 px-md-5 pt-md-5 pb-md-1">
        <!-- Grid row -->
        <div class="row">

          <!-- Grid column -->
          <div class="col-md-12 display-5 mb-3">
            <span class="h1-responsive montserrat-font font-weight-bold" *ngIf="controlMethod.name | isEmpty; else noControlMethodName2">
              Control methods for <span class="comma montserrat-font font-weight-bold" *ngFor="let pwd of controlMethod.pests_diseases_weeds">{{pwd.name}}</span>
            </span>
            <ng-template #noControlMethodName2>
              <span class="h1-responsive montserrat-font font-weight-bold">{{controlMethod.name}}</span>
            </ng-template>
            <!-- Section heading -->
            <br><span class="h4-responsive montserrat-font font-weight-400 my-2">{{controlMethod.category}}</span>
          </div>
          <!-- Grid column -->

          <!-- Grid column -->
          <div class="col-lg-6 col-md-12">
            <!-- Image -->
            <div class="mb-4">
              <div class="view overlay rounded z-depth-1-half mb-4">
<!--                <img class="img-fluid" src="{{baseUrl}}image/600/400/{{controlMethod.image? controlMethod.image: 'default.png'}}" alt="Sample image">-->
                <img class="details-image img-fluid"
                     width="100%"
                     height="auto"
                     [defaultImage]="defaultImage"
                     [errorImage]="errorImage"
                     offset="1500"
                     [lazyLoad]="baseUrl+'image/600/400/'+controlMethod.image">
                <a>
                  <div class="mask rgba-white-slight waves-light" mdbWavesEffect></div>
                </a>
              </div>
            </div>
            <!-- Image -->
          </div>
          <!-- Grid column -->

          <!-- Grid column -->
          <div class="col-lg-6 col-md-12">
            <div class="">

              <!-- Grid row -->
              <div class="row mb-3" *ngIf="controlMethod.pests_diseases_weeds?.length > 0">
                <div class="col-md-11 col-10">
                  <h4 class="small-heading mb-1 minor-items-title">Pests, Diseases, Weeds</h4>
                  <ul class="list-inline">
                    <li class="list-inline-item dark-grey-text minor-items-content" *ngFor="let pwd of controlMethod.pests_diseases_weeds">
                      <mdb-badge>
                        <a routerLink="/pdw/details/{{pwd.id}}">{{pwd.name}}</a>
                      </mdb-badge>
                    </li>
                  </ul>
                </div>
              </div>
              <!-- Grid row -->

              <!-- Grid row -->
              <div class="row mb-3" *ngIf="controlMethod.commercial_organic?.length > 0">
                <div class="col-md-11 col-10">
                  <h4 class="small-heading mb-1 minor-items-title">Commercial Products Available</h4>
                  <ul class="list-inline">
                    <li class="list-inline-item dark-grey-text minor-items-content" *ngFor="let cmo of controlMethod.commercial_organic">
                      <mdb-badge>
                        <a routerLink="/commercial-organic/details/{{cmo.id}}">{{cmo.name}}</a>
                      </mdb-badge>
                    </li>
                  </ul>
                </div>
              </div>
              <!-- Grid row -->

              <!-- Grid row -->
              <!--                        <div class="row mb-3" *ngIf="pestsDiseaseWeed.agrochem_products?.length > 0">-->
              <!--                          <div class="col-md-11 col-10">-->
              <!--                            <h4 class="small-heading mb-1">Agrochemicals Used</h4>-->
              <!--                            <ul class="list-inline">-->
              <!--                              <li class="list-inline-item dark-grey-text" *ngFor="let agro_chem of pestsDiseaseWeed.agrochem_products">-->
              <!--                                <a routerLink="/crops/{{agro_chem.id}}">{{agro_chem.product_name}}</a>-->
              <!--                              </li>-->
              <!--                            </ul>-->
              <!--                          </div>-->
              <!--                        </div>-->
              <!-- Grid row -->

            </div>
          </div>
          <!-- Grid column -->

        </div>
        <!-- Grid row -->
        </div>
      </div>

      <!-- Grid row -->
      <div class="row">

        <!-- Grid Column -->
        <div class="col-12 control-methods" *ngIf="controlMethod.options?.length > 0">
          <div class="soft-shadow-upper-2">
            <div class="soft-shadow-lower-2">
              <div class="item-section">
                <!-- Title -->
                <div class="item-section-title">
                  <h2 class="category-title mt-5 mb-1"><strong class="">Options</strong></h2>
                </div>
                <!-- Title -->
                <div class="item-section-content">
                  <!-- Content -->
                  <div *ngFor="let option of controlMethod.options">
                    <div class="ck-content dark-grey-text " [innerHTML]="option | unescape"></div>
                  </div>
                  <!-- Content -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Grid Column -->


        <!-- Grid Column -->
        <div class="col-12 references" *ngIf="controlMethod.external_links?.length > 0">
          <div class="soft-shadow-upper-2">
            <div class="soft-shadow-lower-2">
              <div class="item-section">
                <!-- Title -->
                <div class="item-section-title">
                  <h2 class="category-title mt-5 mb-1"><strong class="">References</strong></h2>
                </div>
                <!-- Title -->
                <div class="item-section-content">
                  <!-- Content -->
                  <div *ngFor="let link of controlMethod.external_links">
                    <div class="ck-content dark-grey-text " [innerHTML]="link | unescape"></div>
                  </div>
                  <!-- Content -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Grid Column -->
      </div>
      <!-- Grid row -->

    </section>
  </div>
</div>

