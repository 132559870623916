import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toxic'
})
export class ToxicPipe implements PipeTransform {

  transform(value: any): string {
    if (value === true || value === 'true' || value === 1 || value === '1'){
      return 'Toxic';
    }else{
      return 'Less Toxic';
    }

  }

}
