import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdvocacyComponent } from './advocacy.component';
import {MDBBootstrapModule} from 'angular-bootstrap-md';
import {RouterModule} from '@angular/router';
import {HowToComponent} from '../how-to/how-to/how-to.component';



@NgModule({
  declarations: [AdvocacyComponent],
  imports: [
    CommonModule,
    MDBBootstrapModule,
    RouterModule,
  ],
  exports: [
    AdvocacyComponent
  ]
})
export class AdvocacyModule { }
