import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'highlight'
})
/*
* Pipe that formats a string to highlight the provided text if it exists in the string
 */
export class HighlightPipe implements PipeTransform {
  transform(text, search): string {
    console.warn(typeof text);
    let pattern = search.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/gi, '\\$&');
    pattern = pattern.split(' ').filter((t) => {
      return t.length > 0;
    }).join('|');
    const regex = new RegExp(pattern, 'gi');

    return search ? this.toString(text).replace(regex, (match) => `<span class='highlight'>${match}</span>`) : this.toString(text);
  }

  private toString(text){
    let stringText = '';
    if (text instanceof Array){
      stringText = text.join('<br>').toString();
      // stringText = text.toString();
    }else if (text instanceof Object){
      stringText = text.valueOf().toString();
    }else if (text === null){
      stringText = '';
    }else{
      stringText = text.toString();
    }
    return stringText;
  }
}
