import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {CommercialOrganic} from '../../shared/models/commercial-organic';
import {CommercialOrganicService} from '../../shared/services/commercial-organic.service';
import {Title} from '@angular/platform-browser';
import {AppSettings} from '../../app.settings';
import {MessageService} from '../../shared/messages/message.service';
import {environment} from '../../../environments/environment';
import {PestsDiseaseWeed} from '../../shared/models/pests-disease-weed';

@Component({
  selector: 'app-commercial-organic-details',
  templateUrl: './commercial-organic-details.component.html',
  styleUrls: ['./commercial-organic-details.component.css']
})
export class CommercialOrganicDetailsComponent implements OnInit {

  baseUrl = environment.baseUrl;
  defaultImage = environment.defaultImage;
  errorImage = environment.errorImage;
  id: any;
  loading = true;
  loaded = false;
  error = false;
  commercialOrganic: CommercialOrganic;
  gapPdw: PestsDiseaseWeed[];
  gapTotal = 0;
  homemadeOrganicPdw: PestsDiseaseWeed[];
  homemadeOrganicTotal = 0;
  constructor(
    private titleService: Title,
    private route: ActivatedRoute,
    private commercialOrganicService: CommercialOrganicService,
    public messageService: MessageService,
  ) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.params.id;
    this.get();
  }

  private get() {
    // Fetch commercial organic item based on id
    this.commercialOrganicService.findById(this.id).subscribe(response => {
        this.commercialOrganic = response.data;
        this.loading = false;
        this.loaded = true;
        this.error = false;
        this.titleService.setTitle(this.commercialOrganic.name);


        // Get pest, disease or weed's homemade organic products
        this.commercialOrganicService.getHomemadeOrganic(this.id).subscribe(responseHomemadeOrganic => {
          this.homemadeOrganicTotal = responseHomemadeOrganic.total;
          this.homemadeOrganicPdw = responseHomemadeOrganic.data;
        });
        // Get pest, disease or weed's GAP items
        this.commercialOrganicService.getGap(this.id).subscribe(responseGap => {
          this.gapTotal = responseGap.total;
          this.gapPdw = responseGap.data;
        });
      },
      error => {
        // Handle error if no commercial organic item is found
        this.messageService.showMessage('info', 'Not found', 'Offshelf organic produuct not found');
        this.loading = false;
        this.error = true;
      });
  }
}
