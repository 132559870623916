import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {PestDiseaseWeedDashboardComponent} from './pest-disease-weed-dashboard/pest-disease-weed-dashboard.component';
import {PestDiseaseWeedDetailsComponent} from './pest-disease-weed-details/pest-disease-weed-details.component';
import {MDBBootstrapModule} from 'angular-bootstrap-md';
import {RouterModule} from '@angular/router';
import {SharedModule} from '../shared/shared.module';
import {LazyLoadImageModule} from 'ng-lazyload-image';
import {FormsModule} from '@angular/forms';
import {MyMaterialModule} from '../material.module';



@NgModule({
  declarations: [
    PestDiseaseWeedDashboardComponent,
    PestDiseaseWeedDetailsComponent
  ],
  imports: [
    CommonModule,
    MDBBootstrapModule,
    RouterModule,
    SharedModule,
    LazyLoadImageModule,
    FormsModule,
    MyMaterialModule,
  ],
  exports: [
    PestDiseaseWeedDashboardComponent,
    PestDiseaseWeedDetailsComponent,
  ]
})
export class PestDiseaseWeedModule { }
