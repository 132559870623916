import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ControlMethodsDashboardComponent} from './control-methods-dashboard/control-methods-dashboard.component';
import {ControlMethodsDetailsComponent} from './control-methods-details/control-methods-details.component';
import {MDBBootstrapModule} from 'angular-bootstrap-md';
import {RouterModule} from '@angular/router';
import {SharedModule} from '../shared/shared.module';
import {LazyLoadImageModule} from 'ng-lazyload-image';
import {FormsModule} from '@angular/forms';



@NgModule({
  declarations: [
    ControlMethodsDashboardComponent,
    ControlMethodsDetailsComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    MDBBootstrapModule,
    RouterModule,
    SharedModule,
    LazyLoadImageModule,
  ],
  exports: [
    ControlMethodsDashboardComponent,
    ControlMethodsDetailsComponent
  ]
})
export class ControlMethodsModule { }
