<div *ngIf="messageService.messages.length > 0">
  <div
  <div class="alert-container">
    <div  *ngFor="let message of messageService.messages">
      <div #alert class="alert alert-{{message.type}} alert-dismissible fade show" role="alert">
        <button type="button" class="close" aria-label="Close" (click)="closeAlert()">
          <span aria-hidden="true">&times;</span>
        </button>
        <h4 class="alert-heading"></h4>
        <p>{{message.message}}</p>
        <hr>
        <strong class="mb-0">{{message.title}}</strong>
      </div>

<!--      <div *ngIf="message.type == 'primary'" >-->
<!--        <div #alert class="alert alert-primary alert-dismissible fade show" role="alert">-->
<!--          <button type="button" class="close" aria-label="Close" (click)="closeAlert()">-->
<!--            <span aria-hidden="true">&times;</span>-->
<!--          </button>-->
<!--          <h4 class="alert-heading"></h4>-->
<!--          <p>{{message.message}}</p>-->
<!--          <hr>-->
<!--          <strong class="mb-0">{{message.title}}</strong>-->
<!--        </div>-->
<!--      </div>-->


<!--      <div *ngIf="message.type == 'secondary'">-->
<!--        <div #alert class="alert alert-secondary alert-dismissible fade show" role="alert">-->
<!--          <button type="button" class="close" aria-label="Close" (click)="closeAlert()">-->
<!--            <span aria-hidden="true">&times;</span>-->
<!--          </button>-->
<!--          <h4 class="alert-heading"></h4>-->
<!--          <p>{{message.message}}</p>-->
<!--          <hr>-->
<!--          <strong class="mb-0">{{message.title}}</strong>-->
<!--        </div>-->
<!--      </div>-->


<!--      <div *ngIf="message.type == 'success'">-->
<!--        <div #alert class="alert alert-success alert-dismissible fade show" role="alert">-->
<!--          <button type="button" class="close" aria-label="Close" (click)="closeAlert()">-->
<!--            <span aria-hidden="true">&times;</span>-->
<!--          </button>-->
<!--          <h4 class="alert-heading"></h4>-->
<!--          <p>{{message.message}}</p>-->
<!--          <hr>-->
<!--          <strong class="mb-0">{{message.title}}</strong>-->
<!--        </div>-->
<!--      </div>-->


<!--      <div *ngIf="message.type == 'danger'" >-->
<!--        <div #alert class="alert alert-danger alert-dismissible fade show" role="alert">-->
<!--          <button type="button" class="close" aria-label="Close" (click)="closeAlert()">-->
<!--            <span aria-hidden="true">&times;</span>-->
<!--          </button>-->
<!--          <h4 class="alert-heading"></h4>-->
<!--          <p>{{message.message}}</p>-->
<!--          <hr>-->
<!--          <strong class="mb-0">{{message.title}}</strong>-->
<!--        </div>-->
<!--      </div>-->


<!--      <div *ngIf="message.type == 'warning'" >-->
<!--        <div #alert class="alert alert-warning alert-dismissible fade show" role="alert">-->
<!--          <button type="button" class="close" aria-label="Close" (click)="closeAlert()">-->
<!--            <span aria-hidden="true">&times;</span>-->
<!--          </button>-->
<!--          <h4 class="alert-heading"></h4>-->
<!--          <p>{{message.message}}</p>-->
<!--          <hr>-->
<!--          <strong class="mb-0">{{message.title}}</strong>-->
<!--        </div>-->
<!--      </div>-->


<!--      <div *ngIf="message.type == 'info'">-->
<!--        <div #alert class="alert alert-info alert-dismissible fade show" role="alert">-->
<!--          <button type="button" class="close" aria-label="Close" (click)="closeAlert()">-->
<!--            <span aria-hidden="true">&times;</span>-->
<!--          </button>-->
<!--          <h4 class="alert-heading"></h4>-->
<!--          <p>{{message.message}}</p>-->
<!--          <hr>-->
<!--          <strong class="mb-0">{{message.title}}</strong>-->
<!--        </div>-->
<!--      </div>-->


<!--      <div *ngIf="message.type == 'light'">-->
<!--        <div #alert class="alert alert-light alert-dismissible fade show" role="alert">-->
<!--          <button type="button" class="close" aria-label="Close" (click)="closeAlert()">-->
<!--            <span aria-hidden="true">&times;</span>-->
<!--          </button>-->
<!--          <h4 class="alert-heading"></h4>-->
<!--          <p>{{message.message}}</p>-->
<!--          <hr>-->
<!--          <strong class="mb-0">{{message.title}}</strong>-->
<!--        </div>-->
<!--      </div>-->


<!--      <div *ngIf="message.type == 'dark'">-->
<!--        <div #alert class="alert alert-dark alert-dismissible fade show" role="alert">-->
<!--          <button type="button" class="close" aria-label="Close" (click)="closeAlert()">-->
<!--            <span aria-hidden="true">&times;</span>-->
<!--          </button>-->
<!--          <h4 class="alert-heading"></h4>-->
<!--          <p>{{message.message}}</p>-->
<!--          <hr>-->
<!--          <strong class="mb-0">{{message.title}}</strong>-->
<!--        </div>-->
<!--      </div>-->
    </div>
  </div>
</div>
