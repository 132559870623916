import { Component, OnInit } from '@angular/core';
import {PestDiseaseWeedService} from '../../shared/services/pest-disease-weed.service';
import {PestsDiseaseWeed} from '../../shared/models/pests-disease-weed';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute, Router} from '@angular/router';
import {MessageService} from '../../shared/messages/message.service';
import {environment} from '../../../environments/environment';
import {JsonToXlsService} from '../../shared/services/json-to-xls.service';

@Component({
  selector: 'app-pest-disease-weed-dashboard',
  templateUrl: './pest-disease-weed-dashboard.component.html',
  styleUrls: ['./pest-disease-weed-dashboard.component.css']
})
export class PestDiseaseWeedDashboardComponent implements OnInit {


  totalRecords: number;
  to: number;
  from: number;
  currentPage: number;
  lastPage: number;
  perPage = '20';
  perPageChanged = false;
  page: string;
  orderColumn = 'id';
  orderDirection = 'desc';
  orderChanged = false;
  ordering = '';
  searchValue = '';


  baseUrl = environment.baseUrl;
  defaultImage = environment.defaultImage;
  errorImage = environment.errorImage;
  loading = true;
  loaded = false;
  error = false;
  pestsDiseaseWeeds: PestsDiseaseWeed[] = [];
  title: string;
  type: string;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public messageService: MessageService,
    private titleService: Title,
    private pestDiseaseWeedService: PestDiseaseWeedService,
    private jsonToXlsService: JsonToXlsService,
  ) { }

  ngOnInit(): void {
    // const currentUrl = this.router.url;
    const currentUrl = this.route.routeConfig.path;


    // Load items based on URL
    switch (currentUrl){
      case 'pdw/type/:type':
        this.route.params.subscribe(routeParams => {
          // Get page query param
          this.route.queryParams.subscribe(params => {
            // Defaults to 0 if no query param provided.
            this.page = params.page || '1';
            this.searchValue = params.q || '';
            this.perPage = params.limit || '20';
            this.ordering = params.ordering || '#';
            this.setOrdering(this.ordering);
            this.loading = true;
            this.loaded = false;
            this.getByType(routeParams.type);
          });
        });
        break;
      case 'pdw':
        // Get page query param
        this.route.queryParams.subscribe(params => {
          // Defaults to 0 if no query param provided.
          this.page = params.page || '1';
          this.searchValue = params.q || '';
          this.perPage = params.limit || '20';
          this.ordering = params.ordering || '#';
          this.setOrdering(this.ordering);
          this.loading = true;
          this.loaded = false;
          this.title = 'Pests Diseases & Weeds';
          this.titleService.setTitle('Pests Diseases & Weeds');
          this.getByType('');
        });
        break;
      default:
        this.router.navigateByUrl('/404');
        break;
    }


  }

  private getAll(title: string) {
    this.titleService.setTitle(title);
    this.title = title;
    this.type = '';

    // Fetch all pests, diseases, weeds
    this.pestDiseaseWeedService.getNamesByType('', this.page, this.perPage, this.orderColumn, this.orderDirection).subscribe(response => {
      this.pestsDiseaseWeeds = response.data;
      this.page = response.page;
      this.currentPage = response.current_page;
      this.lastPage = response.last_page;
      this.from = response.from;
      this.to = response.to;
      this.loading = false;
      this.loaded = true;
      this.error = false;
    },
      error => {
        // Error handling if no items found
        this.messageService.showMessage('info', 'Not found',  'No pests, diseases or weeds found');
        this.loading = false;
        this.error = true;
    });
  }

  private getByType(type: string) {
    this.type = type;
    // if (type !== ''){
    switch (type){
      case 'pest':
        this.title = 'Common Pests';
        this.titleService.setTitle(this.title);
        break;
      case 'disease':
        this.title = 'Common Diseases';
        this.titleService.setTitle(this.title);
        break;
      case 'weed':
        this.title = 'Common Weeds';
        this.titleService.setTitle(this.title);
        break;
      default:
        break;
    }
      // this.title = this.capitalize(type);
      // this.titleService.setTitle(this.capitalize(type));
    // }
    // Fetch pests, diseases or weeds based on the type
    this.filter(this.type, this.page, this.perPage, this.orderColumn, this.orderDirection, this.searchValue);
  }

  private filter(type = '', page = '', perPage = '', orderColumn = '', orderDirection = '', searchValue = '') {
    this.pestDiseaseWeedService.getNamesByType(type, page, perPage, orderColumn, orderDirection, searchValue).subscribe(response => {
        this.pestsDiseaseWeeds = response.data;
        this.totalRecords = response.total;
        this.page = response.page;
        this.currentPage = response.current_page;
        this.lastPage = response.last_page;
        this.from = response.from;
        this.to = response.to;
        this.loading = false;
        this.loaded = true;
        this.error = false;
      },
      error => {
        // Error handling
        this.messageService.showMessage('info', 'Not found', this.title + ' not found');
        this.loading = false;
        this.error = true;
      }
    );
  }

// Capitalize the first character of a string
  private capitalize(word: string)  {
    return word.charAt(0).toUpperCase() + word.slice(1);
  }

  public goToPage(page){
    this.loading = true;
    this.loaded = false;
    this.page = page;
    // Load in same page
    // this.filter(this.type, this.page, this.perPage, this.orderColumn, this.orderDirection, this.searchValue);
    // Navigate to page
    this.router.navigateByUrl(`/pdw${this.type ? '/type/' + this.type : ''}?page=${page}${this.orderChanged ? '&ordering=' + this.ordering : ''}${this.searchValue !== '' ? '&q=' + this.searchValue : ''}${this.perPage !== '20' ? '&limit=' + this.perPage : ''}`);

  }
  // Handles an item search based on search value
  public search(){
    this.loading = true;
    this.loaded = false;
    this.page = '1';
    this.filter(this.type, this.page, this.perPage, this.orderColumn, this.orderDirection, this.searchValue);
  }
  // Handle change in items per pagge to be displayed
  public itemsPerPageChanged(){
    this.loading = true;
    this.loaded = false;
    this.page = '1';
    this.perPageChanged = true;
    this.filter(this.type, this.page, this.perPage, this.orderColumn, this.orderDirection, this.searchValue);
  }
  // Handle change in sort order and field
  public sortOrderChanged() {
    const value = this.ordering;
    this.setOrdering(value);
    this.loading = true;
    this.loaded = false;
    this.page = '1';

    this.filter(this.type, this.page, this.perPage, this.orderColumn, this.orderDirection, this.searchValue);
  }
  private setOrdering(value){
    if (value.indexOf('!') === 0) {
      this.orderColumn = value.substring(1, value.length);
      this.orderDirection = 'desc';
      this.orderChanged = true;
    } else if (value === '#'){
      // Default Ordering
      this.orderColumn = 'id';
      this.orderDirection = 'desc';
      this.orderChanged = false;
    } else {
      this.orderColumn = value;
      this.orderDirection = 'asc';
      this.orderChanged = true;
    }
  }
  public generateXLS(allPages = false){
    if (allPages){
      this.jsonToXlsService.convertPdwToXls(this.type, this.page, '9999999', this.orderColumn, this.orderDirection, this.searchValue);
    }else {
      this.jsonToXlsService.convertPdwToXls(this.type, this.page, this.perPage, this.orderColumn, this.orderDirection, this.searchValue);
    }
  }
}
