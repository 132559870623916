<div>
  <mdb-breadcrumb>
    <mdb-breadcrumb-item routerLink="/" class="blue-text">Home</mdb-breadcrumb-item>
    <mdb-breadcrumb-item class="active">How To Use The Database</mdb-breadcrumb-item>
  </mdb-breadcrumb>

  <div class="container">
    <div class="layer-bg-top-left" style="background-image: url('../../../assets/noun_usability_31468569.png');">
      <div class="layer-bg-bottom-right" style="background-image: url('../../../assets/noun_usability_31468569.png');">
        <div class="row mb-1 wow fadeInUp" data-wow-delay="0.3s">
          <div class="col-sm">
            <span class="component-title">
              How To Use The Database
            </span>
          </div>
        </div>
        <div class="component-content">
          <div class="row shadow-md wow fadeIn" data-wow-delay="0.6s">
            <div class="col-12">
              <div class="content-1 mb-5 pb-5">
                The database is user friendly where one can navigate based on the area of interest. If you are interested in a certain pest/disease/weed, you can search for it and it will give you the name of the pest/disease/weed, on which crops they affect, the Good Agricultural Practices to control, Bio pesticides available and also the less toxic alternatives available in the market.
                <br><br>
                Navigation can also be done from the crops side where you search a crop/plant and it gives you the main common pests/diseases/weeds that affect them and how to control them using Good Agricultural Practices, bio-pesticides and less toxic alternatives.
                <br><br>
                The site also lists the toxic active ingredients and products there-of, which have been withdrawn from other markets or are heavily restricted in their use due to potential chronic health effects, environmental persistence, high toxicity towards fish and bees or the fact that there is insufficient data to prove no harm towards human health and environment.
                <br><br>
                We appreciate your feed back here
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>



