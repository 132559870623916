import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'whoClassification'
})
export class WhoClassificationPipe implements PipeTransform {

  transform(value: any): string {
    switch (value) {
      case 'IA':
        return 'IA - Extremely Hazardous';
      case 'IB':
        return 'IB - Highly Hazardous';
      case 'II':
        return 'II - Moderately Hazardous';
      case 'III':
        return 'III -	Slightly Hazardous';
      case 'U':
        return 'U -	Unlikely To Be Hazardous';
      default:
        return 'Unclassified';
    }


  }

}
