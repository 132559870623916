import { Component, OnInit } from '@angular/core';
import {environment} from '../../../../environments/environment';
import {PestsDiseaseWeed} from '../../../shared/models/pests-disease-weed';
import {Agrochem} from '../../../shared/models/agrochem';
import {ActivatedRoute, Router} from '@angular/router';
import {PestDiseaseWeedService} from '../../../shared/services/pest-disease-weed.service';
import {Title} from '@angular/platform-browser';
import {MessageService} from '../../../shared/messages/message.service';
import {Post} from '../../../shared/models/post';
import {PostsService} from '../../../shared/services/posts.service';

@Component({
  selector: 'app-post',
  templateUrl: './post.component.html',
  styleUrls: ['./post.component.css']
})
export class PostComponent implements OnInit {

  baseUrl = environment.baseUrl;
  rootUrl = environment.rootUrl;
  defaultImage = environment.defaultImage;
  errorImage = environment.errorImage;
  id: any;
  loading = true;
  loaded = false;
  error = false;
  post: Post;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private postsService: PostsService,
    private titleService: Title,
    public messageService: MessageService,
  ) { }

  ngOnInit(): void {
    this.getItem();
  }

  private getItem() {
    this.route.params.subscribe(routeParams => {
      this.id = routeParams.id;
      // Get pest, disease or weed item based on the id
      this.postsService.findById(this.id).subscribe(response => {
          this.post = response.data;
          this.loading = false;
          this.loaded = true;
          this.error = false;
          // Set web page title
          this.titleService.setTitle(this.post.name);
        },
        error => {
          // Error handling if no pests, diseases/ weeds found
          this.messageService.showMessage('info', 'Not found', 'No post found');
          this.loading = false;
          this.error = true;
        });
    });
  }
}
