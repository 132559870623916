import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {CommercialOrganicDashboardComponent} from './commercial-organic-dashboard/commercial-organic-dashboard.component';
import {CommercialOrganicDetailsComponent} from './commercial-organic-details/commercial-organic-details.component';
import {MDBBootstrapModule} from 'angular-bootstrap-md';
import {RouterModule} from '@angular/router';
import {SharedModule} from '../shared/shared.module';
import {LazyLoadImageModule} from 'ng-lazyload-image';
import {FormsModule} from '@angular/forms';



@NgModule({
  declarations: [
    CommercialOrganicDashboardComponent,
    CommercialOrganicDetailsComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    MDBBootstrapModule,
    RouterModule,
    SharedModule,
    LazyLoadImageModule,
  ],
  exports: [
    CommercialOrganicDashboardComponent,
    CommercialOrganicDetailsComponent
  ]
})
export class CommercialOrganicModule { }
