import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'euApprovedColor'
})
export class EuApprovedColorPipe implements PipeTransform {

  transform(value: string): string {
    switch (value){
      case 'Yes':
        return 'green darken-3';
      case 'No':
        return 'red darken-4';
      default:
        return '';
    }
  }

}
