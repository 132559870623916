<div>
  <mdb-breadcrumb>
    <mdb-breadcrumb-item routerLink="/" class="blue-text">Home</mdb-breadcrumb-item>
    <mdb-breadcrumb-item class="active">Crops</mdb-breadcrumb-item>
  </mdb-breadcrumb>
  <div class="container">
    <div class="row mb-1">
      <div class="col-sm">
        <span class="component-title">
          Crops
        </span>
      </div>
    </div>
    <div class="component-content shadow-md">
      <form class="">
        <div class="row">
          <div class="col-md-12">
            <div class="input-group">
              <input type="text" class=" col-sm-2 col-md-8 mb-2" placeholder="Filter" style="font-size:12px; border-bottom-left-radius:0; border-left:none; border-right:none; border-top:none; border-bottom:1px solid rgba(79,79,79,0.2);" aria-label="Filter" (keyup.enter)="search()" [(ngModel)]="searchValue" [ngModelOptions]="{standalone: true}">
              <div class="input-group-append">
                <div class="input-group">
                  <select class="browser-default custom-select mb-2" style="font-size:12px; border-bottom-left-radius:0; border-top-left-radius:0; border-left:1px solid rgba(79,79,79,0.2); border-right:1px solid rgba(79,79,79,0.2); border-top:1px solid rgba(79,79,79,0.2);" (change)="itemsPerPageChanged()" [(ngModel)]="perPage" [ngModelOptions]="{standalone: true}">
                    <option value="" selected><strong>Items Per Page</strong></option>
                    <option value="4">4</option>
                    <option value="12">12</option>
                    <option value="20">20</option>
                    <option value="48">48</option>
                    <option value="100">100</option>
                  </select>
                  <select class="browser-default custom-select mb-2" style="font-size:12px; border-bottom-left-radius:0; border-bottom-right-radius:0; border-left:1px solid rgba(79,79,79,0.2); border-right:1px solid rgba(79,79,79,0.2); border-top:1px solid rgba(79,79,79,0.2);" (change)="sortOrderChanged()" [(ngModel)]="ordering" [ngModelOptions]="{standalone: true}">
                    <option value="#" selected><strong>Order By</strong></option>
                    <option value="name">Name - Asc</option>
                    <option value="!name">Name - Desc</option>
                    <option value="!id">Most Recent</option>
                    <option value="id">Earliest</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
        </div>
      </form>



      <!--      Loader-->
      <div class="d-flex justify-content-center vh-70" *ngIf="loading">
        <div class="flex-center">
          <div class="spinner-grow text-light" style="width: 6rem; height: 6rem;"  role="status">
            <span class="sr-only">Loading...</span>
          </div>
          <span class="component-title orb">Loading...</span>
        </div>
      </div>


      <app-not-found *ngIf="error"></app-not-found>

      <div *ngIf="loaded">

        <!--  RESULTS COUNT-->
        <div class="row mt-1 mb-1">
          <div class="col-12">
            <span class="results-count" *ngIf="totalRecords"><span class="font-weight-800">{{totalRecords}}</span> Found</span>
          </div>
        </div>
        <!--  RESULTS COUNT-->

        <!--    PAGINATION-->
        <div class="row mt-3 mb-1">
          <div class="col-sm-12 flex-center">
            <nav aria-label="Page navigation example">
              <ul class="pagination pg-blue">
                <li class="page-item" [class.disabled]="currentPage == 1">
              <span class="page-link">
                <a (click)="goToPage(1)">
                  First
                </a>
              </span>
                </li>
                <li class="page-item" [class.disabled]="currentPage == 1">
              <span class="page-link">
                <a (click)="goToPage(currentPage - 1)">
                  Previous
                </a>
              </span>
                </li>
                <li class="page-item" *ngIf="currentPage - 10 > 0">
                  <a class="page-link" mdbWavesEffect (click)="goToPage(currentPage - 10)">
                    {{currentPage - 10}}
                  </a>
                </li>
                <li class="page-item" *ngIf="currentPage - 9 > 0">
                  <a class="page-link" mdbWavesEffect (click)="goToPage(currentPage - 9)">
                    {{currentPage - 9}}
                  </a>
                </li>
                <li class="page-item" *ngIf="currentPage - 8 > 0">
                  <a class="page-link" mdbWavesEffect (click)="goToPage(currentPage - 8)">
                    {{currentPage - 8}}
                  </a>
                </li>
                <li class="page-item" *ngIf="currentPage - 7 > 0">
                  <a class="page-link" mdbWavesEffect (click)="goToPage(currentPage - 7)">
                    {{currentPage - 7}}
                  </a>
                </li>
                <li class="page-item" *ngIf="currentPage - 6 > 0">
                  <a class="page-link" mdbWavesEffect (click)="goToPage(currentPage - 6)">
                    {{currentPage - 6}}
                  </a>
                </li>
                <li class="page-item" *ngIf="currentPage - 5 > 0">
                  <a class="page-link" mdbWavesEffect (click)="goToPage(currentPage - 5)">
                    {{currentPage - 5}}
                  </a>
                </li>
                <li class="page-item" *ngIf="currentPage - 4 > 0">
                  <a class="page-link" mdbWavesEffect (click)="goToPage(currentPage - 4)">
                    {{currentPage - 4}}
                  </a>
                </li>
                <li class="page-item" *ngIf="currentPage - 3 > 0">
                  <a class="page-link" mdbWavesEffect (click)="goToPage(currentPage - 3)">
                    {{currentPage - 3}}
                  </a>
                </li>
                <li class="page-item" *ngIf="currentPage - 2 > 0">
                  <a class="page-link" mdbWavesEffect (click)="goToPage(currentPage - 2)">
                    {{currentPage - 2}}
                  </a>
                </li>
                <li class="page-item" *ngIf="currentPage - 1 > 0">
                  <a class="page-link" mdbWavesEffect (click)="goToPage(currentPage - 1)">
                    {{currentPage - 1}}
                  </a>
                </li>
                <li class="page-item active">
              <span class="page-link" mdbWavesEffect>
                {{currentPage}}
                <span class="sr-only">(current)</span>
              </span>
                </li>
                <li class="page-item" *ngIf="currentPage + 1 <= lastPage">
                  <a class="page-link" mdbWavesEffect (click)="goToPage(currentPage + 1)">
                    {{currentPage + 1}}
                  </a>
                </li>
                <li class="page-item" *ngIf="currentPage + 2 <= lastPage">
                  <a class="page-link" mdbWavesEffect (click)="goToPage(currentPage + 2)">
                    {{currentPage + 2}}
                  </a>
                </li>
                <li class="page-item" *ngIf="currentPage + 3 <= lastPage">
                  <a class="page-link" mdbWavesEffect (click)="goToPage(currentPage + 3)">
                    {{currentPage + 3}}
                  </a>
                </li>
                <li class="page-item" *ngIf="currentPage + 4 <= lastPage">
                  <a class="page-link" mdbWavesEffect (click)="goToPage(currentPage + 4)">
                    {{currentPage + 4}}
                  </a>
                </li>
                <li class="page-item" *ngIf="currentPage + 5 <= lastPage">
                  <a class="page-link" mdbWavesEffect (click)="goToPage(currentPage + 5)">
                    {{currentPage + 5}}
                  </a>
                </li>
                <li class="page-item" *ngIf="currentPage + 6 <= lastPage">
                  <a class="page-link" mdbWavesEffect (click)="goToPage(currentPage + 6)">
                    {{currentPage + 6}}
                  </a>
                </li>
                <li class="page-item" *ngIf="currentPage + 7 <= lastPage">
                  <a class="page-link" mdbWavesEffect (click)="goToPage(currentPage + 7)">
                    {{currentPage + 7}}
                  </a>
                </li>
                <li class="page-item" *ngIf="currentPage + 8 <= lastPage">
                  <a class="page-link" mdbWavesEffect (click)="goToPage(currentPage + 8)">
                    {{currentPage + 8}}
                  </a>
                </li>
                <li class="page-item" *ngIf="currentPage + 9 <= lastPage">
                  <a class="page-link" mdbWavesEffect (click)="goToPage(currentPage + 9)">
                    {{currentPage + 9}}
                  </a>
                </li>
                <li class="page-item" *ngIf="currentPage + 10 <= lastPage">
                  <a class="page-link" mdbWavesEffect (click)="goToPage(currentPage + 10)">
                    {{currentPage + 10}}
                  </a>
                </li>
                <li class="page-item" [class.disabled]="currentPage == lastPage">
                  <a class="page-link" mdbWavesEffect (click)="goToPage(currentPage + 1)">
                    Next
                  </a>
                </li>
                <li class="page-item" [class.disabled]="currentPage == lastPage">
                  <a class="page-link" mdbWavesEffect (click)="goToPage(lastPage)">Last</a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <!--    --/PAGINATOR-->

        <div class="row">
          <!--        Layyout for small-->
          <div class=" d-block d-md-none col-sm-12 mx-3 my-1 p-2 view" *ngFor="let item of crops">
            <div class="media item" routerLink="/crops/details/{{item.id}}">
              <div class="card-img-container">
                <img class="details-image img-fluid"
                     width="70px"
                     height="70px"
                     [defaultImage]="defaultImage"
                     [errorImage]="errorImage"
                     offset="1500"
                     [lazyLoad]="baseUrl+'image/70/70/'+item.image">
              </div>
              <!--            <img src="{{baseUrl}}image/100/67/{{item.image? item.image: 'default.png'}}" class="mr-3" alt="...">-->
              <div class="media-body d-flex align-items-center" style="height: 70px; margin-left:5px">
                <div class="card-title-container"><a class="card-title" routerLink="/crops/details/{{item.id}}">{{item.name}}</a></div>
              </div>
            </div>
          </div>
          <!--        Layyout for small-->
          <!--        Layout for medium and Larger-->
          <div class="d-none d-md-block col-xs-6 col-sm-6 col-md-4 col-lg-3 pt-md-3 " *ngFor="let item of crops">
            <div class="soft-shadow-upper">
              <div class="soft-shadow-lower">
                <div  routerLink="/crops/details/{{item.id}}" class="item">
                  <div class="view overlay zoom">
                    <mdb-card class="shadow-sm">
                      <!--Card image-->
                      <div class="card-img-container">
                        <img class="details-image img-fluid"
                             width="100%"
                             height="auto"
                             [defaultImage]="defaultImage"
                             [errorImage]="errorImage"
                             offset="1500"
                             [lazyLoad]="baseUrl+'image/245/160/'+item.image"
                             alt="Sample image"/>
                      </div>
                      <!--                <mdb-card-img src="{{baseUrl}}image/245/160/{{item.image? item.image: 'default.png'}}" alt="Image Not Found"></mdb-card-img>-->
                      <!--Card content-->
                      <!--                  <mdb-card-body>-->
                      <!--Title-->
                      <mdb-card-title>
                        <div class="card-title-container"><a class="card-title" routerLink="/crops/details/{{item.id}}">{{item.name}}</a></div>
                      </mdb-card-title>
                      <!--                  </mdb-card-body>-->
                    </mdb-card>
                    <div class="mask flex-center rgba-cyan-slight">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--        Layout for medium and Larger-->
        </div>

        <!--    PAGINATION-->
        <div class="row mt-3 mb-1">
          <div class="col-sm-12 flex-center">
            <nav aria-label="Page navigation example">
              <ul class="pagination pg-blue">
                <li class="page-item" [class.disabled]="currentPage == 1">
              <span class="page-link">
                <a (click)="goToPage(1)">
                  First
                </a>
              </span>
                </li>
                <li class="page-item" [class.disabled]="currentPage == 1">
              <span class="page-link">
                <a (click)="goToPage(currentPage - 1)">
                  Previous
                </a>
              </span>
                </li>
                <li class="page-item" *ngIf="currentPage - 10 > 0">
                  <a class="page-link" mdbWavesEffect (click)="goToPage(currentPage - 10)">
                    {{currentPage - 10}}
                  </a>
                </li>
                <li class="page-item" *ngIf="currentPage - 9 > 0">
                  <a class="page-link" mdbWavesEffect (click)="goToPage(currentPage - 9)">
                    {{currentPage - 9}}
                  </a>
                </li>
                <li class="page-item" *ngIf="currentPage - 8 > 0">
                  <a class="page-link" mdbWavesEffect (click)="goToPage(currentPage - 8)">
                    {{currentPage - 8}}
                  </a>
                </li>
                <li class="page-item" *ngIf="currentPage - 7 > 0">
                  <a class="page-link" mdbWavesEffect (click)="goToPage(currentPage - 7)">
                    {{currentPage - 7}}
                  </a>
                </li>
                <li class="page-item" *ngIf="currentPage - 6 > 0">
                  <a class="page-link" mdbWavesEffect (click)="goToPage(currentPage - 6)">
                    {{currentPage - 6}}
                  </a>
                </li>
                <li class="page-item" *ngIf="currentPage - 5 > 0">
                  <a class="page-link" mdbWavesEffect (click)="goToPage(currentPage - 5)">
                    {{currentPage - 5}}
                  </a>
                </li>
                <li class="page-item" *ngIf="currentPage - 4 > 0">
                  <a class="page-link" mdbWavesEffect (click)="goToPage(currentPage - 4)">
                    {{currentPage - 4}}
                  </a>
                </li>
                <li class="page-item" *ngIf="currentPage - 3 > 0">
                  <a class="page-link" mdbWavesEffect (click)="goToPage(currentPage - 3)">
                    {{currentPage - 3}}
                  </a>
                </li>
                <li class="page-item" *ngIf="currentPage - 2 > 0">
                  <a class="page-link" mdbWavesEffect (click)="goToPage(currentPage - 2)">
                    {{currentPage - 2}}
                  </a>
                </li>
                <li class="page-item" *ngIf="currentPage - 1 > 0">
                  <a class="page-link" mdbWavesEffect (click)="goToPage(currentPage - 1)">
                    {{currentPage - 1}}
                  </a>
                </li>
                <li class="page-item active">
              <span class="page-link" mdbWavesEffect>
                {{currentPage}}
                <span class="sr-only">(current)</span>
              </span>
                </li>
                <li class="page-item" *ngIf="currentPage + 1 <= lastPage">
                  <a class="page-link" mdbWavesEffect (click)="goToPage(currentPage + 1)">
                    {{currentPage + 1}}
                  </a>
                </li>
                <li class="page-item" *ngIf="currentPage + 2 <= lastPage">
                  <a class="page-link" mdbWavesEffect (click)="goToPage(currentPage + 2)">
                    {{currentPage + 2}}
                  </a>
                </li>
                <li class="page-item" *ngIf="currentPage + 3 <= lastPage">
                  <a class="page-link" mdbWavesEffect (click)="goToPage(currentPage + 3)">
                    {{currentPage + 3}}
                  </a>
                </li>
                <li class="page-item" *ngIf="currentPage + 4 <= lastPage">
                  <a class="page-link" mdbWavesEffect (click)="goToPage(currentPage + 4)">
                    {{currentPage + 4}}
                  </a>
                </li>
                <li class="page-item" *ngIf="currentPage + 5 <= lastPage">
                  <a class="page-link" mdbWavesEffect (click)="goToPage(currentPage + 5)">
                    {{currentPage + 5}}
                  </a>
                </li>
                <li class="page-item" *ngIf="currentPage + 6 <= lastPage">
                  <a class="page-link" mdbWavesEffect (click)="goToPage(currentPage + 6)">
                    {{currentPage + 6}}
                  </a>
                </li>
                <li class="page-item" *ngIf="currentPage + 7 <= lastPage">
                  <a class="page-link" mdbWavesEffect (click)="goToPage(currentPage + 7)">
                    {{currentPage + 7}}
                  </a>
                </li>
                <li class="page-item" *ngIf="currentPage + 8 <= lastPage">
                  <a class="page-link" mdbWavesEffect (click)="goToPage(currentPage + 8)">
                    {{currentPage + 8}}
                  </a>
                </li>
                <li class="page-item" *ngIf="currentPage + 9 <= lastPage">
                  <a class="page-link" mdbWavesEffect (click)="goToPage(currentPage + 9)">
                    {{currentPage + 9}}
                  </a>
                </li>
                <li class="page-item" *ngIf="currentPage + 10 <= lastPage">
                  <a class="page-link" mdbWavesEffect (click)="goToPage(currentPage + 10)">
                    {{currentPage + 10}}
                  </a>
                </li>
                <li class="page-item" [class.disabled]="currentPage == lastPage">
                  <a class="page-link" mdbWavesEffect (click)="goToPage(currentPage + 1)">
                    Next
                  </a>
                </li>
                <li class="page-item" [class.disabled]="currentPage == lastPage">
                  <a class="page-link" mdbWavesEffect (click)="goToPage(lastPage)">Last</a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <!--    --/PAGINATOR-->

      </div>
    </div>
  </div>
</div>

