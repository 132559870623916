<div class="d-flex justify-content-center vh-100" *ngIf="loading">
  <div class="flex-center">
    <div class="spinner-grow text-light" style="width: 6rem; height: 6rem;"  role="status">
      <span class="sr-only">Loading...</span>
    </div>
    <span class="component-title orb">Loading...</span>
  </div>
</div>

<app-not-found *ngIf="error"></app-not-found>

<div *ngIf="loaded">
  <mdb-breadcrumb>
    <mdb-breadcrumb-item routerLink="/" class="blue-text">Home</mdb-breadcrumb-item>
    <mdb-breadcrumb-item routerLink="/crops" class="blue-text">Crops</mdb-breadcrumb-item>
    <mdb-breadcrumb-item class="active">{{crop.name}}</mdb-breadcrumb-item>
  </mdb-breadcrumb>

  <div class="container">

    <section class="component-content">
      <div class="row mb-1">
        <div class="col-sm  text-center">
          <span class="component-title">
          </span>
        </div>
      </div>


      <div class="p-2 px-md-5 pt-md-5 pb-md-1">
        <!-- Section heading -->
        <!--        <h2 class="h1-responsive font-weight-bold text-center my-3">{{pestsDiseaseWeed.type}}</h2>-->
        <!-- Section description -->
        <!--        <p class="text-center dark-grey-text w-responsive mx-auto mb-5"><em>{{pestsDiseaseWeed.scientific_name}}</em></p>-->

        <!-- Grid row -->
        <div class="row">
          <!-- Grid column -->
          <div class="col-md-12 display-5 mb-3">
              <span class="h1-responsive montserrat-font font-weight-bold">
                {{crop.name}}
              </span><br>
          </div>
          <!-- Grid column -->

          <!-- Grid column -->
          <div class="col-lg-6 col-md-12">
            <!-- Image -->
            <div class="mb-4">
              <div class="view overlay rounded z-depth-1-half mb-4">
<!--                <img class="img-fluid" src="{{baseUrl}}image/600/400/{{crop.image? crop.image: 'default.png'}}" alt="Sample image">-->
                <img class="details-image img-fluid"
                     width="100%"
                     height="auto"
                     [defaultImage]="defaultImage"
                     [errorImage]="errorImage"
                     [lazyLoad]="baseUrl+'image/600/400/'+crop.image">
                <a>
                  <div class="mask rgba-white-slight waves-light" mdbWavesEffect></div>
                </a>
              </div>
            </div>
            <!-- Image -->
          </div>
          <!-- Grid column -->
        </div>
        <!-- Grid row -->
      </div>

      <!-- Grid row -->
      <div class="row">
        <!-- Grid Column -->
        <div class="col-12 effect-on-crops" *ngIf="pestsDiseaseWeeds?.length > 0">
          <div class="soft-shadow-upper-2">
            <div class="soft-shadow-lower-2">
              <div class="item-section">
                <!-- Title -->
                <div class="item-section-title">
                  <h2 class="category-title mt-5 mb-1"><strong class="">Pesticides, Diseases, Weeds Vulnerable To</strong></h2>
                </div>
                <!-- Title -->
                <div class="item-section-content">
                  <!-- Content -->
                  <div class="row">
                    <!--        Layyout for small-->
                    <div class=" d-block d-md-none col-sm-12 mx-3 my-1 p-2 view" *ngFor="let item of crop.pests_disease_weed">
                      <div class="media item" routerLink="/pdw/details/{{item.id}}">
                        <div class="card-img-container">
                          <img class="details-image img-fluid"
                               width="70px"
                               height="70px"
                               [defaultImage]="defaultImage"
                               [errorImage]="errorImage"
                               offset="1500"
                               [lazyLoad]="baseUrl+'image/70/70/'+item.image">
                        </div>
                        <!--            <img src="{{baseUrl}}image/100/67/{{item.image? item.image: 'default.png'}}" class="mr-3" alt="...">-->
                        <div class="media-body d-flex align-items-center" style="height: 70px; margin-left:5px">
                          <div class="card-title-container"><a class="card-title" routerLink="/pdw/details/{{item.id}}">{{item.name}}</a></div>
                        </div>
                      </div>
                    </div>
                    <!--        Layyout for small-->
                    <!--        Layout for medium and Larger-->
                    <div class="d-none d-md-block col-xs-6 col-sm-6 col-md-4 col-lg-3 pt-md-3 " *ngFor="let item of crop.pests_disease_weed">
                      <div  routerLink="/pdw/details/{{item.id}}" class="item">
                        <div class="view overlay zoom">
                          <mdb-card class="shadow-sm">
                            <!--Card image-->
                            <div class="card-img-container">
                              <img class="details-image img-fluid"
                                   width="100%"
                                   height="auto"
                                   [defaultImage]="defaultImage"
                                   [errorImage]="errorImage"
                                   offset="1500"
                                   [lazyLoad]="baseUrl+'image/245/160/'+item.image"
                                   alt="Sample image"/>
                            </div>
                            <!--                <mdb-card-img src="{{baseUrl}}image/245/160/{{item.image? item.image: 'default.png'}}" alt="Image Not Found"></mdb-card-img>-->
                            <!--Card content-->
                            <!--                                  <mdb-card-body>-->
                            <!--Title-->
                            <mdb-card-title>
                              <div class="card-title-container"><a class="card-title" routerLink="/pdw/details/{{item.id}}">{{item.name}}</a></div>
                            </mdb-card-title>
                            <!--                                  </mdb-card-body>-->
                          </mdb-card>
                          <div class="mask flex-center rgba-cyan-slight"></div>
                        </div>
                      </div>
                    </div>
                    <!--        Layout for medium and Larger-->
                  </div>
                  <!-- Content -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Grid Column -->

        <!-- Grid Column -->
        <div class="col-12 gap" *ngIf="gapTotal > 0">
          <div class="soft-shadow-upper-2">
            <div class="soft-shadow-lower-2">
              <div class="item-section">
                <!-- Title -->
                <div class="item-section-title">
                  <h2 class="category-title mt-5 mb-1"><strong class="">Good Agricultural Practices</strong></h2>
                </div>
                <!-- Title -->
                <div class="item-section-content">
                  <!-- Content -->
                  <div style="display: -webkit-box; display: flex">
                    <div style="-webkit-box-flex:1; flex: 1">
                      <div class="accordion-tabs" *ngIf="gapPdw?.length > 0">
                        <div class="accordion-tab" *ngFor="let item of gapPdw">
                          <div *ngIf="item.gap?.length > 0">
                            <input type="checkbox" id="gap{{item.id}}"/>
                            <label class="accordion-tab-label" for="gap{{item.id}}">For {{item.name}}</label>
                            <div class="accordion-tab-content">
                              <ul class="list-inline">
                                <li class="list-inline-item dark-grey-text minor-items-content" *ngFor="let gap of item.gap">
                                  <div class="accordion-list-item-wrapper">
                                    <a routerLink="/gap/details/{{gap.id}}">{{gap.name}}</a>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Content -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Grid Column -->

        <!-- Grid Column -->
        <div class="col-12 homemade-organic" *ngIf="homemadeOrganicTotal > 0">
          <div class="soft-shadow-upper-2">
            <div class="soft-shadow-lower-2">
              <div class="item-section">
                <!-- Title -->
                <div class="item-section-title">
                  <h2 class="category-title mt-5 mb-1"><strong class="">Homemade Solutions</strong></h2>
                </div>
                <!-- Title -->
                <div class="item-section-content">
                  <!-- Content -->
                  <div style="display: -webkit-box; display: flex">
                    <div style="-webkit-box-flex:1; flex: 1">
                      <div class="accordion-tabs" *ngIf="homemadeOrganicPdw?.length > 0">
                        <div class="accordion-tab" *ngFor="let item of homemadeOrganicPdw">
                          <div *ngIf="item.homemade_organic?.length > 0">
                            <input type="checkbox" id="hmo{{item.id}}"/>
                            <label class="accordion-tab-label" for="hmo{{item.id}}">For {{item.name}}</label>
                            <div class="accordion-tab-content">
                              <ul class="list-inline">
                                <li class="list-inline-item dark-grey-text minor-items-content" *ngFor="let homemadeOrganic of item.homemade_organic">
                                  <div class="accordion-list-item-wrapper">
                                    <a routerLink="/homemade-organic/details/{{homemadeOrganic.id}}">{{homemadeOrganic.name}}</a>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Content -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Grid Column -->

        <!-- Grid Column -->
        <div class="col-12 commercial-organic" *ngIf="commercialOrganicTotal > 0">
          <div class="soft-shadow-upper-2">
            <div class="soft-shadow-lower-2">
              <div class="item-section">
                <!-- Title -->
                <div class="item-section-title">
                  <h2 class="category-title mt-5 mb-1"><strong class="">Offshelf Organic Products</strong></h2>
                </div>
                <!-- Title -->
                <div class="item-section-content">
                  <!-- Content -->
                  <div style="display: -webkit-box; display: flex">
                    <div style="-webkit-box-flex:1; flex: 1">
                      <div class="accordion-tabs" *ngIf="commercialOrganicPdw?.length > 0">
                        <div class="accordion-tab" *ngFor="let item of commercialOrganicPdw">
                          <div *ngIf="item.commercial_organic?.length > 0">
                            <input type="checkbox" id="cmo{{item.id}}"/>
                            <label class="accordion-tab-label" for="cmo{{item.id}}">For {{item.name}}</label>
                            <div class="accordion-tab-content">
                              <ul class="list-inline">
                                <li class="list-inline-item dark-grey-text minor-items-content" *ngFor="let commercialOrganic of item.commercial_organic">
                                  <div class="accordion-list-item-wrapper">
                                    <a routerLink="/commercial-organic/details/{{commercialOrganic.id}}">{{commercialOrganic.name}}</a>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Content -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Grid Column -->

        <!-- Grid Column -->
<!--        <div class="col-12 less-toxic-agrochem" *ngIf="nonToxicAgrochems?.length > 0; else noLessTOxicAgrochems">-->
<!--          <div class="soft-shadow-upper">-->
<!--            <div class="soft-shadow-lower">-->
<!--              <div class="item-section">-->
<!--                &lt;!&ndash; Title &ndash;&gt;-->
<!--                <div class="item-section-title">-->
<!--                  <h2 class="category-title mt-5 mb-1"><strong class="">Less Toxic Alternative Agrochemicals</strong></h2>-->
<!--                </div>-->
<!--                &lt;!&ndash; Title &ndash;&gt;-->
<!--                <div class="item-section-content">-->
<!--                  &lt;!&ndash; Content &ndash;&gt;-->
<!--                  <div class="row">-->
<!--                    &lt;!&ndash;        Layyout for small&ndash;&gt;-->
<!--                    <div class=" d-block d-md-none col-sm-12 mx-3 my-1 p-2 view" *ngFor="let item of nonToxicAgrochems">-->
<!--                      <div class="media item" routerLink="/agrochem/details/{{item.id}}">-->
<!--                        <div class="card-img-container">-->
<!--                          <img class="details-image img-fluid"-->
<!--                               width="70px"-->
<!--                               height="70px"-->
<!--                               [defaultImage]="defaultImage"-->
<!--                               [lazyLoad]="baseUrl+'image/70/70/'+item.image">-->
<!--                        </div>-->
<!--                        &lt;!&ndash;            <img src="{{baseUrl}}image/100/67/{{item.image? item.image: 'default.png'}}" class="mr-3" alt="...">&ndash;&gt;-->
<!--                        <div class="media-body d-flex align-items-center" style="height: 70px; margin-left:5px">-->
<!--                          <div class="card-title-container"><a class="card-title" routerLink="/agrochem/details/{{item.id}}">{{item.product_name}}</a></div>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                    &lt;!&ndash;        Layyout for small&ndash;&gt;-->
<!--                    &lt;!&ndash;        Layout for medium and Larger&ndash;&gt;-->
<!--                    <div class="d-none d-md-block col-xs-6 col-sm-6 col-md-4 col-lg-3 pt-md-3 " *ngFor="let item of nonToxicAgrochems">-->
<!--                      <div  routerLink="/agrochem/details/{{item.id}}" class="item">-->
<!--                        <div class="view overlay zoom">-->
<!--                          <mdb-card class="shadow-sm">-->
<!--                            &lt;!&ndash;Card image&ndash;&gt;-->
<!--                            <div class="card-img-container">-->
<!--                              <img class="details-image img-fluid"-->
<!--                                   width="100%"-->
<!--                                   height="auto"-->
<!--                                   [defaultImage]="defaultImage"-->
<!--                                   [lazyLoad]="baseUrl+'image/245/160/'+item.image"-->
<!--                                   alt="Sample image"/>-->
<!--                            </div>-->
<!--                            &lt;!&ndash;                <mdb-card-img src="{{baseUrl}}image/245/160/{{item.image? item.image: 'default.png'}}" alt="Image Not Found"></mdb-card-img>&ndash;&gt;-->
<!--                            &lt;!&ndash;Card content&ndash;&gt;-->
<!--                            &lt;!&ndash;                                  <mdb-card-body>&ndash;&gt;-->
<!--                            &lt;!&ndash;Title&ndash;&gt;-->
<!--                            <mdb-card-title>-->
<!--                              <div class="card-title-container"><a class="card-title" routerLink="/agrochem/details/{{item.id}}">{{item.product_name}}</a></div>-->
<!--                            </mdb-card-title>-->
<!--                            &lt;!&ndash;                                  </mdb-card-body>&ndash;&gt;-->
<!--                          </mdb-card>-->
<!--                          <div class="mask flex-center rgba-red-light" *ngIf="item.toxic"></div>-->
<!--                          <div class="mask flex-center rgba-red-slight" *ngIf="!item.toxic"></div>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                    &lt;!&ndash;        Layout for medium and Larger&ndash;&gt;-->
<!--                  </div>-->
<!--                  &lt;!&ndash; Content &ndash;&gt;-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
        <!-- Description -->
<!--        <div class="col-12">-->
<!--          <ng-template #noLessTOxicAgrochems>-->
<!--            <div class="col-12 default">-->
<!--              <div class="soft-shadow-upper">-->
<!--                <div class="soft-shadow-lower">-->
<!--                  <div class="item-section-title">-->
<!--                    <h2 class="category-title mt-5 mb-1">-->
<!--                      <strong>-->
<!--                        No Lesser toxic options have been listed to be used on {{crop.name}}-->
<!--                      </strong>-->
<!--                    </h2>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </ng-template>-->
<!--        </div>-->
        <!-- Grid Column -->

        <!-- Grid Column -->
<!--        <div class="col-12 agrochem" *ngIf="toxicAgrochems?.length > 0">-->
<!--          <div class="soft-shadow-upper">-->
<!--            <div class="soft-shadow-lower">-->
<!--              <div class="item-section">-->
<!--                &lt;!&ndash; Title &ndash;&gt;-->
<!--                <div class="item-section-title">-->
<!--                  <h2 class="category-title mt-5 mb-1"><strong>Agrochemicals used on {{crop.name}}</strong></h2>-->
<!--                </div>-->
<!--                &lt;!&ndash; Title &ndash;&gt;-->
<!--                <div class="item-section-content">-->
<!--                  &lt;!&ndash; Content &ndash;&gt;-->
<!--                  <div class="row">-->
<!--                    &lt;!&ndash;        Layyout for small&ndash;&gt;-->
<!--                    <div class=" d-block d-md-none col-sm-12 mx-3 my-1 p-2 view" *ngFor="let item of toxicAgrochems">-->
<!--                      <div class="media item" routerLink="/agrochem/details/{{item.id}}">-->
<!--                        <div class="card-img-container">-->
<!--                          <img class="details-image img-fluid"-->
<!--                               width="70px"-->
<!--                               height="70px"-->
<!--                               [defaultImage]="defaultImage"-->
<!--                               [lazyLoad]="baseUrl+'image/70/70/'+item.image">-->
<!--                        </div>-->
<!--                        &lt;!&ndash;            <img src="{{baseUrl}}image/100/67/{{item.image? item.image: 'default.png'}}" class="mr-3" alt="...">&ndash;&gt;-->
<!--                        <div class="media-body d-flex align-items-center" style="height: 70px; margin-left:5px">-->
<!--                          <div class="card-title-container"><a class="card-title" routerLink="/agrochem/details/{{item.id}}">{{item.product_name}}</a></div>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                    &lt;!&ndash;        Layyout for small&ndash;&gt;-->
<!--                    &lt;!&ndash;        Layout for medium and Larger&ndash;&gt;-->
<!--                    <div class="d-none d-md-block col-xs-6 col-sm-6 col-md-4 col-lg-3 pt-md-3 " *ngFor="let item of toxicAgrochems">-->
<!--                      <div  routerLink="/agrochem/details/{{item.id}}" class="item">-->
<!--                        <div class="view overlay zoom">-->
<!--                          <mdb-card class="shadow-sm">-->
<!--                            &lt;!&ndash;Card image&ndash;&gt;-->
<!--                            <div class="card-img-container">-->
<!--                              <img class="details-image img-fluid"-->
<!--                                   width="100%"-->
<!--                                   height="auto"-->
<!--                                   [defaultImage]="defaultImage"-->
<!--                                   [lazyLoad]="baseUrl+'image/245/160/'+item.image"-->
<!--                                   alt="Sample image"/>-->
<!--                            </div>-->
<!--                            &lt;!&ndash;                <mdb-card-img src="{{baseUrl}}image/245/160/{{item.image? item.image: 'default.png'}}" alt="Image Not Found"></mdb-card-img>&ndash;&gt;-->
<!--                            &lt;!&ndash;Card content&ndash;&gt;-->
<!--                            &lt;!&ndash;                                  <mdb-card-body>&ndash;&gt;-->
<!--                            &lt;!&ndash;Title&ndash;&gt;-->
<!--                            <mdb-card-title>-->
<!--                              <div class="card-title-container"><a class="card-title" routerLink="/agrochem/details/{{item.id}}">{{item.product_name}}</a></div>-->
<!--                            </mdb-card-title>-->
<!--                            &lt;!&ndash;                                  </mdb-card-body>&ndash;&gt;-->
<!--                          </mdb-card>-->
<!--                          <div class="mask flex-center rgba-red-light" *ngIf="item.toxic"></div>-->
<!--                          <div class="mask flex-center rgba-red-slight" *ngIf="!item.toxic"></div>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                    &lt;!&ndash;        Layout for medium and Larger&ndash;&gt;-->
<!--                  </div>-->
<!--                  &lt;!&ndash; Content &ndash;&gt;-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
        <!-- Grid Column -->
      </div>
      <!-- Grid row -->

    </section>
  </div>
</div>

