import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpParams} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {catchError, retry} from 'rxjs/operators';
import {throwError} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CoreService {

  private authToken: string;
  private tokenExpiry: number;

  constructor(private http: HttpClient) { }

  public getAuthToken(): string{
    return this.authToken;
  }

  public setAuthToken(token){
    this.authToken = token;
  }

  public getTokenExpiry(): number{
    return this.tokenExpiry;
  }

  public setTokenExpiry(expiry){
    this.tokenExpiry = expiry;
  }


  // Get authentication token
  authenticate(){
    // const state = Math.random().toString(36).substring(2, 22) + Math.random().toString(36).substring(2, 22); // random string
    const state = this.generateRandomString(40); // random string
    // const codeVerifier = Math.random().toString(36).substring(2, 66) + Math.random().toString(36).substring(2, 66);
    const codeVerifier = this.generateRandomString(128);
    const codeChallenge = btoa(codeVerifier).replace(/=*/gi, '').trim();

    const clientId = '90ee088d-893e-45e0-bc4b-9b8ddc7b751b';
    const responseType = 'code';
    const redirectUri = '';
    const codeChallengeMethod = 'S256';
    const scope = '';


    const  params = new  HttpParams()
      .set('client_id', clientId)
      .set('redirect_uri', redirectUri)
      .set('response_type', responseType)
      .set('scope', scope)
      .set('state', state)
      .set('code_challenge', codeChallenge)
      .set('code_challenge_method', codeChallengeMethod);
    const url = `${environment.rootUrl}oauth/authorize/`;
    return this.http.get<any>(url, {params})
      .pipe(
        retry(1), // retry a failed request up to 3 times
        catchError(this.handleError)
      );
  }


  // Error handling
  private handleError(error: HttpErrorResponse): any {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error.message}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  }


  private generateRandomString(length): string {
    let result           = '';
    const characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for ( let i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
}
