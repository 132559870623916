import { Component, OnInit } from '@angular/core';
import {AppSettings} from '../../app.settings';
import {Gap} from '../../shared/models/gap';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute} from '@angular/router';
import {GapService} from '../../shared/services/gap.service';
import {ControlMethods} from '../../shared/models/control_methods';
import {ControlMethodsService} from '../../shared/services/control-methods.service';
import {MessageService} from '../../shared/messages/message.service';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-control-methods-details',
  templateUrl: './control-methods-details.component.html',
  styleUrls: ['./control-methods-details.component.css']
})
export class ControlMethodsDetailsComponent implements OnInit {

  baseUrl = environment.baseUrl;
  defaultImage = environment.defaultImage;
  errorImage = environment.errorImage;
  id: any;
  loading = true;
  loaded = false;
  error = false;
  controlMethod: ControlMethods;

  constructor(
    private titleService: Title,
    private route: ActivatedRoute,
    private controlMethodsService: ControlMethodsService,
    public messageService: MessageService,
  ) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.params.id;
    this.getItem();
  }


  private getItem() {
    // Get control method item based on id
    this.controlMethodsService.findById(this.id).subscribe(response => {
        this.controlMethod = response.data;
        this.loading = false;
        this.loaded = true;
        this.error = false;
        this.titleService.setTitle(this.controlMethod.name);
      },
      error => {
        // Handle error if no control method item  is found
        this.messageService.showMessage('info', 'Not found', 'Control method not found');
        this.loading = false;
        this.error = true;
      });
  }
}
