import { NgModule } from '@angular/core';
import {Routes, RouterModule, ExtraOptions} from '@angular/router';
import {PestDiseaseWeedDashboardComponent} from './pest-disease-weed/pest-disease-weed-dashboard/pest-disease-weed-dashboard.component';
import {PestDiseaseWeedDetailsComponent} from './pest-disease-weed/pest-disease-weed-details/pest-disease-weed-details.component';
import {AgrochemProductsDashboardComponent} from './agrochem-products/agrochem-products-dashboard/agrochem-products-dashboard.component';
import {AgrochemProductsDetailsComponent} from './agrochem-products/agrochem-products-details/agrochem-products-details.component';
import {ActiveIngredientsDashboardComponent
} from './active-ingredients/active-ingredients-dashboard/active-ingredients-dashboard.component';
import {ActiveIngredientsDetailsComponent} from './active-ingredients/active-ingredients-details/active-ingredients-details.component';
import {CommercialOrganicDashboardComponent} from './commercial-organic/commercial-organic-dashboard/commercial-organic-dashboard.component';
import {CommercialOrganicDetailsComponent} from './commercial-organic/commercial-organic-details/commercial-organic-details.component';
import {HomemadeOrganicDashboardComponent} from './homemade-organic/homemade-organic-dashboard/homemade-organic-dashboard.component';
import {HomemadeOrganicDetailsComponent} from './homemade-organic/homemade-organic-details/homemade-organic-details.component';
import {CropsDashboardComponent} from './crops/crops-dashboard/crops-dashboard.component';
import {CropsDetailsComponent} from './crops/crops-details/crops-details.component';
import {HomeComponent} from './home/home/home.component';
import {GapDashboardComponent} from './gap/gap-dashboard/gap-dashboard.component';
import {GapDetailsComponent} from './gap/gap-details/gap-details.component';
import {GlobalSearchComponent} from './global-search/global-search/global-search.component';
import {GlobalSearchResultsComponent} from './global-search/global-search-results/global-search-results.component';
import {ControlMethodsDashboardComponent} from './control-methods/control-methods-dashboard/control-methods-dashboard.component';
import {ControlMethodsDetailsComponent} from './control-methods/control-methods-details/control-methods-details.component';
import {NotFoundComponent} from './shared/not-found/not-found.component';
import {AboutUsComponent} from './about-us/about-us.component';
import {HowToComponent} from './how-to/how-to/how-to.component';
import {AdvocacyComponent} from './advocacy/advocacy.component';
import {DisclaimerComponent} from './disclaimer/disclaimer.component';
import {DownloadsListComponent} from './cms/downloads/downloads-list/downloads-list.component';
import {DownloadDetailsComponent} from './cms/downloads/download-details/download-details.component';
import {MediaListComponent} from './cms/media/media-list/media-list.component';
import {MediaDetailsComponent} from './cms/media/media-details/media-details.component';
import {PostsListComponent} from './cms/posts/posts-list/posts-list.component';
import {PostComponent} from './cms/posts/post/post.component';


const routes: Routes = [
  { path: '',
    redirectTo: '/home',
    pathMatch: 'full',
    data: {
      title: 'Safe Inputs'
    }
  },
  { path: 'home',
    component: HomeComponent,
    data: {
      title: 'Safe Inputs'
    },
  },

  { path: 'about-us',
    component: AboutUsComponent,
    data: {
      title: 'About Us'
    },
  },

  { path: 'how-to',
    component: HowToComponent,
    data: {
      title: 'How To Use'
    },
  },
  { path: 'advocacy',
    component: AdvocacyComponent,
    data: {
      title: 'Advocacy'
    },
  },
  { path: 'disclaimer',
    component: DisclaimerComponent,
    data: {
      title: 'Disclaimer'
    },
  },


  {
    path: 'pdw',
    component: PestDiseaseWeedDashboardComponent,
    data: {
      title: 'Pests Diseases & Weeds'
    }
  },
  { path: 'pdw/type/:type',
    component: PestDiseaseWeedDashboardComponent,
    data: {
      title: ':type'
    }
  },
  { path: 'pdw/details/:id',
    component: PestDiseaseWeedDetailsComponent,
    data: {
      title: 'Details'
    }
  },


  { path: 'agrochem',
    component: AgrochemProductsDashboardComponent,
    data: {
      title: 'Agrochem Products'
    }
  },
  { path: 'agrochem/details/:id',
    component: AgrochemProductsDetailsComponent,
    data: {
      title: 'Details'
    }
  },
  { path: 'agrochem/nontoxic',
    component: AgrochemProductsDashboardComponent,
    data: {
      title: 'Safer Agrochemicals'
    }
  },
  { path: 'agrochem/toxic',
    component: AgrochemProductsDashboardComponent,
    data: {
      title: 'Agrochemicals'
    }
  },


  { path: 'active-ingredients',
    component: ActiveIngredientsDashboardComponent,
    data: {
      title: 'Active Ingredients'
    },
  },
  { path: 'active-ingredients/details/:id',
    component: ActiveIngredientsDetailsComponent,
    data: {
      title: 'Details'
    }
  },


  { path: 'commercial-organic',
    component: CommercialOrganicDashboardComponent,
    data: {
      title: 'OffShelf Organic'
    },
  },
  { path: 'commercial-organic/details/:id',
    component: CommercialOrganicDetailsComponent,
    data: {
      title: 'Details'
    }
  },


  { path: 'homemade-organic',
    component: HomemadeOrganicDashboardComponent,
    data: {
      title: 'Homemade Organic'
    },
  },
  { path: 'homemade-organic/details/:id',
    component: HomemadeOrganicDetailsComponent,
    data: {
      title: 'Details'
    }
  },


  { path: 'gap',
    component: GapDashboardComponent,
    data: {
      title: 'Good Agricultural Practices'
    },
  },
  {
    path: 'gap/category/cultural',
    component: GapDashboardComponent,
    data: {
      title: 'Cultural Practices'
    }
  },
  {
    path: 'gap/category/physical',
    component: GapDashboardComponent,
    data: {
      title: 'Physical Practices'
    }
  },
  {
    path: 'gap/category/prevention',
    component: GapDashboardComponent,
    data: {
      title: 'Prevention'
    }
  },
  { path: 'gap/details/:id',
    component: GapDetailsComponent,
    data: {
      title: 'Details'
    }
  },


  { path: 'crops',
    component: CropsDashboardComponent,
    data: {
      title: 'Crops'
    },
  },
  { path: 'crops/details/:id',
    component: CropsDetailsComponent,
    data: {
      title: 'Details'
    }
  },


  { path: 'control-methods',
    component: ControlMethodsDashboardComponent,
    data: {
      title: 'Control Methods'
    },
  },
  { path: 'control-methods/biocontrol',
    component: ControlMethodsDashboardComponent,
    data: {
      title: 'Control Methods - Biocontrol'
    },
  },
  { path: 'control-methods/biopesticide',
    component: ControlMethodsDashboardComponent,
    data: {
      title: 'Control Methods - Biopesticide'
    },
  },
  { path: 'control-methods/details/:id',
    component: ControlMethodsDetailsComponent,
    data: {
      title: 'Details'
    }
  },


  { path: 'global-search',
    component: GlobalSearchComponent,
    data: {
      title: 'Search'
    },
  },
  { path: 'search/:searchValue',
    component: GlobalSearchResultsComponent,
    data: {
      title: 'Search Results'
    }
  },


  { path: 'downloads',
    component: DownloadsListComponent,
    data: {
      title: 'Downloads'
    },
  },
  { path: 'downloads/details/:id',
    component: DownloadDetailsComponent,
    data: {
      title: 'Details'
    }
  },


  { path: 'media',
    component: MediaListComponent,
    data: {
      title: 'Media'
    },
  },
  { path: 'media/details/:id',
    component: MediaDetailsComponent,
    data: {
      title: 'Details'
    }
  },


  { path: 'posts',
    component: PostsListComponent,
    data: {
      title: 'Posts'
    },
  },
  { path: 'posts/tag/:tag',
    component: PostsListComponent,
    data: {
      title: 'Posts'
    },
  },
  { path: 'posts/details/:id',
    component: PostComponent,
    data: {
      title: 'Details'
    }
  },


  { path: '**',
    component: NotFoundComponent,
    data: {
      title: 'Not Found'
    }
  },
];

const routerOptions: ExtraOptions = {
  useHash: false,
  scrollPositionRestoration: 'enabled',
  anchorScrolling: 'enabled',
  scrollOffset: [0, 64],
  // ...any other options you'd like to use
};

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
