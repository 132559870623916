import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ActiveIngredientsDashboardComponent} from './active-ingredients-dashboard/active-ingredients-dashboard.component';
import {ActiveIngredientsDetailsComponent} from './active-ingredients-details/active-ingredients-details.component';
import {MDBBootstrapModule} from 'angular-bootstrap-md';
import {RouterModule} from '@angular/router';
import {SharedModule} from '../shared/shared.module';
// import {LazyLoadImageModule} from 'ng-lazyload-image';
import { LazyLoadImageModule, ScrollHooks } from 'ng-lazyload-image';
import {FormsModule} from '@angular/forms';



@NgModule({
  declarations: [
    ActiveIngredientsDashboardComponent,
    ActiveIngredientsDetailsComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    MDBBootstrapModule,
    RouterModule,
    SharedModule,
    LazyLoadImageModule,
  ],
  exports: [
    ActiveIngredientsDashboardComponent,
    ActiveIngredientsDetailsComponent
  ],
})
export class ActiveIngredientsModule { }
