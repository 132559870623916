import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DownloadsListComponent } from './downloads-list/downloads-list.component';
import { DownloadDetailsComponent } from './download-details/download-details.component';
import {MDBBootstrapModule} from 'angular-bootstrap-md';
import {FormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {SharedModule} from '../../shared/shared.module';
import {LazyLoadImageModule} from 'ng-lazyload-image';



@NgModule({
  declarations: [
    DownloadsListComponent,
    DownloadDetailsComponent
  ],
  imports: [
    CommonModule,
    MDBBootstrapModule,
    FormsModule,
    RouterModule,
    SharedModule,
    LazyLoadImageModule,
  ],
  exports: [
    DownloadsListComponent,
    DownloadDetailsComponent
  ],
})
export class DownloadsModule { }
