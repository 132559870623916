import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MediaListComponent } from './media-list/media-list.component';
import { MediaDetailsComponent } from './media-details/media-details.component';
import {MDBBootstrapModule} from 'angular-bootstrap-md';
import {FormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {SharedModule} from '../../shared/shared.module';
import {LazyLoadImageModule} from 'ng-lazyload-image';



@NgModule({
  declarations: [
    MediaListComponent,
    MediaDetailsComponent
  ],
  imports: [
    CommonModule,
    MDBBootstrapModule,
    FormsModule,
    RouterModule,
    SharedModule,
    LazyLoadImageModule,
  ],
  exports: [
    MediaListComponent,
    MediaDetailsComponent
  ],
})
export class MediaModule { }
