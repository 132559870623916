import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'whoClassColor'
})
export class WhoClassColorPipe implements PipeTransform {

  transform(value: any): string {
    switch (value) {
      case 'IA':
        return 'red darken-3';
      case 'IB':
        return 'red darken-3';
      case 'II':
        return 'yellow darken-2';
      case 'III':
        return 'yellow darken-2';
      case 'U':
        return 'green darken-1';
      default:
        return '';
    }
  }

}
