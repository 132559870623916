import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'reproductionColor'
})
export class ReproductionColorPipe implements PipeTransform {

  transform(value: string): string {
    switch (value){
      case 'Yes':
        return 'red darken-4';
      case 'No':
        return 'grey';
      case 'Possible':
        return 'red';
      case 'No Data':
        return 'text-black-50 grey lighten-5';
      default:
        return '';
    }
  }

}
