import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DisclaimerComponent } from './disclaimer.component';
import {MDBBootstrapModule} from 'angular-bootstrap-md';
import {RouterModule} from '@angular/router';


@NgModule({
  declarations: [DisclaimerComponent],
  imports: [
    CommonModule,
    MDBBootstrapModule,
    RouterModule,
  ],
  exports: [
    DisclaimerComponent
  ]
})
export class DisclaimerModule { }
