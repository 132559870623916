import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toString'
})
/*
* Pipe that converts an object to a string
 */
export class ToStringPipe implements PipeTransform {

  transform(text: any): string {
    let stringText = '';
    if (text instanceof Array){
      // stringText = text.join('<br>'.toString());
      stringText = text.join('<br>');
      // stringText = 'Is an array';
    }else if (text instanceof Object){
      stringText = text.valueOf().toString();
    }else if (text == null){
      stringText = '';
    }else{
      stringText = text.toString();
    }
    return stringText;
  }

}
