import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams} from '@angular/common/http';
import {catchError, retry} from 'rxjs/operators';
import {throwError} from 'rxjs';
import {environment} from '../../../environments/environment';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'text/html',
    'Access-Control-Allow-Origin': '*'
  })
};
@Injectable({
  providedIn: 'root'
})
export class ControlMethodsService {

  constructor(private http: HttpClient) { }

  // Fetch all control methods
  getAll(page = '1', perPage = '20', orderColumn = 'id', orderDirection = 'desc'){
    const params = new HttpParams()
      .set('page', page)
      .set('per_page', perPage)
      .set('order_column', orderColumn)
      .set('order_direction', orderDirection)
    ;
    const url = `${environment.baseUrl}control_methods/`;
    return this.http.get<any>(url, {params})
      .pipe(
        retry(3), // retry a failed request up to 3 times
        catchError(this.handleError)
      );
  }

  // Get control method item based on id
  findById(id: string){
    const url = `${environment.baseUrl}control_methods/${id}`;
    return this.http.get<any>(url)
      .pipe(
        retry(3), // retry a failed request up to 3 times
        catchError(this.handleError)
      );
  }

  // Get control methods based on category (biocontrol|biopesticide)
  getByCategory(category: string, page = '1', perPage = '20', orderColumn = 'id', orderDirection = 'desc',  searchValue = '') {
    const params = new HttpParams()
      .set('category', category)
      .set('page', page)
      .set('per_page', perPage)
      .set('order_column', orderColumn)
      .set('order_direction', orderDirection)
    ;
    const url = `${environment.baseUrl}control_methods/filter`;
    return this.http.get<any>(url, {params})
      .pipe(
        retry(3), // retry a failed request up to 3 times
        catchError(this.handleError)
      );
  }

  // Find control method names on type
  getNamesByCategory(category: string, page = '1', perPage = '20', orderColumn = 'id', orderDirection = 'desc',  searchValue = '') {
    const params = new HttpParams()
      .set('category', category)
      .set('page', page)
      .set('per_page', perPage)
      .set('order_column', orderColumn)
      .set('order_direction', orderDirection)
    ;
    const url = `${environment.baseUrl}control_methods/summary/names/${searchValue}`;
    return this.http.get<any>(url, {params})
      .pipe(
        retry(3), // retry a failed request up to 3 times
        catchError(this.handleError)
      );
  }

  // Get control method item's pests, diseases, weeds
  findPestsDiseasesWeeds(id: string){
    const url = `${environment.baseUrl}control_methods/${id}/pdw`;
    return this.http.get<any>(url)
      .pipe(
        retry(3), // retry a failed request up to 3 times
        catchError(this.handleError)
      );
  }

  // Find control method's commercial organic items
  findCommercialOrganic(id: string){
    const url = `${environment.baseUrl}control_methods/${id}/commercial_organic`;
    return this.http.get<any>(url)
      .pipe(
        retry(3), // retry a failed request up to 3 times
        catchError(this.handleError)
      );
  }

  // Error handling
  private handleError(error: HttpErrorResponse): any {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error.message}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  }

}
