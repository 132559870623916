<div class="d-flex justify-content-center vh-100" *ngIf="loading">
  <div class="flex-center">
    <div class="spinner-grow text-light" style="width: 6rem; height: 6rem;"  role="status">
      <span class="sr-only">Loading...</span>
    </div>
    <span class="component-title orb">Loading...</span>
  </div>
</div>

<app-not-found *ngIf="error"></app-not-found>

<div *ngIf="loaded">
  <mdb-breadcrumb>
    <mdb-breadcrumb-item routerLink="/" class="blue-text">Home</mdb-breadcrumb-item>
    <mdb-breadcrumb-item routerLink="/commercial-organic" class="blue-text">Offshelf Organic</mdb-breadcrumb-item>
    <mdb-breadcrumb-item class="active">{{commercialOrganic.name}}</mdb-breadcrumb-item>
  </mdb-breadcrumb>

  <div class="container">

    <section class="component-content">
      <div class="row mb-1">
      </div>


      <div class="p-2 px-md-5 pt-md-5 pb-md-1">
        <!-- Grid row -->
        <div class="row">

          <!-- Grid column -->
          <div class="col-md-12 display-5 mb-3">
            <span class="h1-responsive montserrat-font font-weight-bold">
            {{commercialOrganic.name}}
            </span><br>
          </div>
          <!-- Grid column -->

          <!-- Grid column -->
          <div class="col-lg-6 col-md-12">
            <!-- Image -->
            <div class="mb-4">
              <div class="view overlay rounded z-depth-1-half mb-4">
<!--                <img class="details-image img-fluid" width="100%" height="auto" src="{{baseUrl}}image/600/400/{{commercialOrganic.image? commercialOrganic.image: 'default.png'}}" alt="Sample image">-->
                <img class="details-image img-fluid"
                     width="100%"
                     height="auto"
                     [defaultImage]="defaultImage"
                     [errorImage]="errorImage"
                     offset="1500"
                     [lazyLoad]="baseUrl+'image/600/400/'+commercialOrganic.image">
                <a>
                  <div class="mask rgba-white-slight waves-light" mdbWavesEffect></div>
                </a>
              </div>
            </div>
            <!-- Image -->
          </div>
          <!-- Grid column -->

          <!-- Grid column -->
          <div class="col-lg-6 col-md-12">
            <div class="">
              <!-- Grid row -->
              <div class="row mb-3" *ngIf="commercialOrganic.pests_diseases_weed?.length > 0">
                <div class="col-md-11 col-10">
                  <h4 class="small-heading mb-1 minor-items-title">Pests, Diseases, Weeds</h4>
                  <ul class="list-inline">
                    <li class="list-inline-item dark-grey-text minor-items-content" *ngFor="let pdw of commercialOrganic.pests_diseases_weed">
                      <mdb-badge>
                        <a routerLink="/pdw/details/{{pdw.id}}">{{pdw.name}}</a>
                      </mdb-badge>
                    </li>
                  </ul>
                </div>
              </div>
              <!-- Grid row -->
            </div>
          </div>
          <!-- Grid column -->
        </div>
        <!-- Grid row -->
      </div>

      <!-- Grid row -->
      <div class="row">
        <!-- Grid Column -->
        <div class="col-12">
          <table mdbTable>
            <thead>
            </thead>
            <tbody>
            <tr mdbTableCol *ngIf="commercialOrganic.pcpb_number">
              <th><strong>PCPB Number</strong></th>
              <td>{{commercialOrganic.pcpb_number}}</td>
            </tr>
            <tr mdbTableCol *ngIf="commercialOrganic.manufacturer">
              <th><strong>Manufucturer</strong></th>
              <td>{{commercialOrganic.manufacturer}}</td>
            </tr>
            <tr mdbTableCol *ngIf="commercialOrganic.distributor">
              <th><strong>Distributor</strong></th>
              <td>{{commercialOrganic.distributor}}</td>
            </tr>
            </tbody>
          </table>
        </div>
        <!-- Grid Column -->

        <!-- Grid Column -->
        <div class="col-12 description" *ngIf="commercialOrganic.contact_details">
          <div class="soft-shadow-upper-2">
            <div class="soft-shadow-lower-2">
              <div class="item-section">
                <!-- Title -->
                <div class="item-section-title">
                  <h2 class="category-title mt-5 mb-1"><strong class="">Contact Details</strong></h2>
                </div>
                <!-- Title -->
                <div class="item-section-content">
                  <!-- Content -->
                  <div>
                    <div class="ck-content dark-grey-text " [innerHTML]="commercialOrganic.contact_details | unescape"></div>
                  </div>
                  <!-- Content -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Grid Column -->

        <!-- Grid Column -->
        <div class="col-12 commercial-organic" *ngIf="commercialOrganic.application_details?.length > 0">
          <div class="soft-shadow-upper-2">
            <div class="soft-shadow-lower-2">
              <div class="item-section">
                <!-- Title -->
                <div class="item-section-title">
                  <h2 class="category-title mt-5 mb-1"><strong class="">Application Details</strong></h2>
                </div>
                <!-- Title -->
                <div class="item-section-content">
                  <!-- Content -->
                  <div *ngFor="let detail of  commercialOrganic.application_details">
                    <div class="ck-content dark-grey-text " [innerHTML]="detail | unescape"></div>
                  </div>
                  <!-- Content -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Grid Column -->


        <!-- Grid Column -->
        <div class="col-12 control-methods" *ngIf="commercialOrganic.control_methods?.length > 0; else noControlMethods">
          <div class="soft-shadow-upper-2">
            <div class="soft-shadow-lower-2">
              <div class="item-section">
                <!-- Title -->
                <div class="item-section-title">
                  <h2 class="category-title mt-5 mb-1"><strong>Control Methods</strong></h2>
                </div>
                <!-- Title -->
                <div class="item-section-content">
                  <!-- Content -->
                  <div *ngFor="let controlMethod of commercialOrganic.control_methods; let i = index">
                    <div>
                      <div class="description-paragraph dark-grey-text font-weight-700 ">
                        <a routerLink="/control-methods/details/{{controlMethod.id}}">
                            <span class="description-sub-heading">{{i+1}}. {{controlMethod.category}}
                              <span  *ngIf="controlMethod.name | isEmpty; else noControlMethodName">
                                - Control methods for  <span class="comma" *ngFor="let pwd of controlMethod.pests_diseases_weeds">{{pwd.name}}</span>
                              </span>
                              <ng-template #noControlMethodName>
                                {{' - ' + controlMethod.name}}
                              </ng-template>
                            </span>
                        </a>
                      </div>
                      <div *ngIf="controlMethod.options?.length> 0">
                        <div class="description-paragraph dark-grey-text ">
                          <span class="description-sub-heading-2">Options</span>
                        </div>
                        <div class="ck-content dark-grey-text" [innerHTML]="option | unescape" *ngFor="let option of controlMethod.options"></div>
                      </div>
                      <div *ngIf="controlMethod.external_links?.length> 0">
                        <div class="description-paragraph dark-grey-text ">
                          <span class="description-sub-heading-2">External Links</span>
                        </div>
                        <div class="ck-content dark-grey-text" [innerHTML]="link | unescape" *ngFor="let link of controlMethod.external_links"></div>
                      </div>
                      <br>
                    </div>
                  </div>
                  <!-- Content -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <ng-template #noControlMethods>
          <div class="col-12 default">
            <div class="soft-shadow-upper">
              <div class="soft-shadow-lower">
                <div class="item-section-title">
                  <h2 class="category-title mt-5 mb-1">
                    <strong>No control methods have been listed that use {{commercialOrganic.name}}</strong>
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
        <!-- Grid Column -->

        <!-- Grid Column -->
        <div class="col-12 gap" *ngIf="gapTotal > 0">
          <div class="soft-shadow-upper-2">
            <div class="soft-shadow-lower-2">
              <div class="item-section">
                <!-- Title -->
                <div class="item-section-title">
                  <h2 class="category-title mt-5 mb-1"><strong class="">Alternative Good Agricultural Practices</strong></h2>
                </div>
                <!-- Title -->
                <div class="item-section-content">
                  <!-- Content -->
                  <div style="display: -webkit-box; display: flex">
                    <div style="-webkit-box-flex:1; flex: 1">
                      <div class="accordion-tabs" *ngIf="gapPdw?.length > 0">
                        <div class="accordion-tab" *ngFor="let item of gapPdw">
                          <div *ngIf="item.gap?.length > 0">
                            <input type="checkbox" id="gap{{item.id}}"/>
                            <label class="accordion-tab-label" for="gap{{item.id}}">For {{item.name}}</label>
                            <div class="accordion-tab-content">
                              <ul class="list-inline">
                                <li class="list-inline-item dark-grey-text minor-items-content" *ngFor="let gap of item.gap">
                                  <div class="accordion-list-item-wrapper">
                                    <a routerLink="/gap/details/{{gap.id}}">{{gap.name}}</a>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Content -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Grid Column -->

        <!-- Grid Column -->
        <div class="col-12 homemade-organic" *ngIf="homemadeOrganicTotal > 0">
          <div class="soft-shadow-upper-2">
            <div class="soft-shadow-lower-2">
              <div class="item-section">
                <!-- Title -->
                <div class="item-section-title">
                  <h2 class="category-title mt-5 mb-1"><strong class="">Alternative Homemade Solutions</strong></h2>
                </div>
                <!-- Title -->
                <div class="item-section-content">
                  <!-- Content -->
                  <div style="display: -webkit-box; display: flex">
                    <div style="-webkit-box-flex:1; flex: 1">
                      <div class="accordion-tabs" *ngIf="homemadeOrganicPdw?.length > 0">
                        <div class="accordion-tab" *ngFor="let item of homemadeOrganicPdw">
                          <div *ngIf="item.homemade_organic?.length > 0">
                            <input type="checkbox" id="hmo{{item.id}}"/>
                            <label class="accordion-tab-label" for="hmo{{item.id}}">For {{item.name}}</label>
                            <div class="accordion-tab-content">
                              <ul class="list-inline">
                                <li class="list-inline-item dark-grey-text minor-items-content" *ngFor="let homemadeOrganic of item.homemade_organic">
                                  <div class="accordion-list-item-wrapper">
                                    <a routerLink="/homemade-organic/details/{{homemadeOrganic.id}}">{{homemadeOrganic.name}}</a>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Content -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Grid Column -->

        <!-- Grid Column -->
        <div class="col-12 references" *ngIf="commercialOrganic.external_links?.length > 0">
          <div class="soft-shadow-upper-2">
            <div class="soft-shadow-lower-2">
              <div class="item-section">
                <!-- Title -->
                <div class="item-section-title">
                  <h2 class="category-title mt-5 mb-1"><strong class="">External Links</strong></h2>
                </div>
                <!-- Title -->
                <div class="item-section-content">
                  <!-- Content -->
                  <div *ngFor="let link of commercialOrganic.external_links">
                    <div class="ck-content dark-grey-text " [innerHTML]="link | unescape"></div>
                  </div>
                  <!-- Content -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Grid Column -->


      </div>
      <!-- Grid row -->

    </section>
  </div>
</div>

