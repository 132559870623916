import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'beesColor'
})
export class BeesColorPipe implements PipeTransform {

  transform(value: number): string {
    if (value < 1){
      return 'red darken-3';
    }else if (value >= 1 && value <= 100 ){
      return 'yellow darken-2';
    }else if (value > 100 ){
      return 'green darken-1';
    }
    return ;
  }

}
