import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {HomemadeOrganicDashboardComponent} from './homemade-organic-dashboard/homemade-organic-dashboard.component';
import {HomemadeOrganicDetailsComponent} from './homemade-organic-details/homemade-organic-details.component';
import {MDBBootstrapModule} from 'angular-bootstrap-md';
import {RouterModule} from '@angular/router';
import {SharedModule} from '../shared/shared.module';
import {LazyLoadImageModule} from 'ng-lazyload-image';
import {FormsModule} from '@angular/forms';



@NgModule({
  declarations: [
    HomemadeOrganicDashboardComponent,
    HomemadeOrganicDetailsComponent
  ],
  imports: [
    CommonModule,
    MDBBootstrapModule,
    FormsModule,
    RouterModule,
    SharedModule,
    LazyLoadImageModule,
  ],
  exports: [
    HomemadeOrganicDashboardComponent,
    HomemadeOrganicDetailsComponent,
  ]
})
export class HomemadeOrganicModule { }
