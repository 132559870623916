<!--Carousel-->
<div class="home-carousel">
  <div class="home-carousel-overlay">
    <h1 class="home-carousel-overlay-text tagline text-center montserrat-font wow fadeIn" data-wow-delay="0.3s">
      Replacing Harmful Agricultural Practices in Kenyan Market with Less Toxic Alternatives
    </h1>
  </div>
  <mdb-carousel class="carousel slide carousel-fade jarallax"   [isControls]="false" [animation]="'fade'" xmlns="http://www.w3.org/1999/html">
    <mdb-carousel-item>
      <img class="d-block w-100" src="../../../assets/8.jpg" alt="First slide">
    </mdb-carousel-item>
    <mdb-carousel-item>
      <img class="d-block w-100" src="../../../assets/7.jpg" alt="Second slide">
    </mdb-carousel-item>
    <mdb-carousel-item>
      <img class="d-block w-100" src="../../../assets/1.jpg" alt="Third slide">
    </mdb-carousel-item>
  </mdb-carousel>
</div>
<!--#Carousel-->


<!--Intro-->
<div class="container my-3">
  <div class="row">
    <div class="col-md-12">
    </div>

    <div class="col-md-10 offset-md-1">
      <span class="content-1 text-justify">
        It’s remarkable that the volume of imported insecticides, herbicides and fungicides has
        more than doubled within four years from <strong>6,400</strong> tonnes in <em>2015</em> to <strong>15,600</strong> tonnes in
        <em>2018</em>, with a growth rate of <strong>144%</strong> according to the Association of Agrochemicals Kenya
        (AAK), 2018. <br>
        From a recent study by the Route to Food Initiative (RTFI), in <em>2019</em>, <strong>77</strong> of
        the <em>230</em> active ingredients registered in Kenya have been withdrawn from the European
        market or are heavily restricted in their use due to potential chronic health effects,
        environmental persistence, high toxicity towards fish and bees or the fact that there is
        insufficient data to prove no harm towards human health and environment. An
        additional 19 active ingredients are not listed in the European database.
      </span>
    </div>
  </div>
  <br><br><br>
</div>
<!--#Intro-->


<!--Database Alternative-->
<div class="content-wrapper jarallax" style="background-image: url('../../../assets/database-2.jpg'); ">
  <div class="layer-bg-top-left" style="background-image: url('../../../assets/noun_alternative_31658759.png');">
    <div class="layer-bg-bottom-right" style="background-image: url('../../../assets/noun_alternative_31658759.png');">
      <br><br><br>
      <div class="container my-3 pb-5">
        <div class="row">
          <div class="col-md-12 col-lg-4 wow fadeIn" data-wow-delay="0.6s">
            <h2 class="montserrat-font font-weight-600 sub-heading" style="">Database With Alternatives</h2>
          </div>

          <div class="col-md-12 col-lg-8 wow fadeIn" data-wow-delay="0.8s">
            <span class="content-1 text-justify">
              It is against this background that the Kenya Organic Agriculture Network (KOAN) has developed this database to provide farmers, extensionists, companies and other stakeholders with information on alternatives to toxic chemicals. The approach is to transition farmers from reliance on harmful agrochemicals towards use of Good Agricultural practices, homemade organic solutions, Biocontrol and biopesticide products which are safer and sustainable in the long run.
            </span>
          </div>
        </div>
        <br><br><br>
      </div>
      <br><br><br>
    </div>
  </div>
</div>
<br>
<br>
<!--#Database Alternative-->


<!--Integrated Pest Management-->
<div class="content-wrapper jarallax" style="background-image: url('../../../assets/ipm.jpg');">
  <div class="layer-bg-top-left" style="background-image: url('../../../assets/noun_integrated system_31692289.png');">
    <div class="layer-bg-bottom-right" style="background-image: url('../../../assets/noun_integrated system_31692289.png');">
      <br><br><br>
      <div style="z-index: 2;" class="container my-3 pb-5">
        <div class="row">
          <div class="col-md-12 col-lg-4">
            <h2 class="montserrat-font font-weight-600 sub-heading wow fadeInUp" data-wow-delay="0.6s">Integrated Pest Management Approach</h2>
            <span class="content-2 text-justify wow fadeIn" data-wow-delay="0.7s">
              Transitioning farmers from use of agrochemicals to sustainable/organic is a process and not a one-time on-off. <br>
              Our approach is based on Integrated Pest Management which focuses on:
            </span>
            <ol class="content-2 wow fadeIn" data-wow-delay="0.8s">
              <li>
                Adoption of Good Agricultural Practices (GAP):
                <ul>
                  <li>Preventative methods/practices</li>
                  <li>Cultural methods/practices</li>
                  <li>Physical methods/practices</li>
                  <li>Biological control methods/practices</li>
                </ul>
              </li>
              <li>
                Use of bio-pesticides
              </li>
              <li>
                Use of less toxic chemicals if/when absolutely necessary
              </li>
            </ol>
          </div>
          <div class="col-md-12 col-lg-8">
            <div class="wrapper">
              <div class="pyramid">
                <div class="zone wow fadeInUp" data-wow-delay="0.7s" routerLink="/gap/category/prevention">
                  <div class="zone-wrapper">
                    <h3 class="zone-title">Prevention Methods - GAP</h3>
                  </div>
                  <div class="zone-bg"></div>
                </div>
                <div class="zone wow fadeInUp" data-wow-delay="0.8s" routerLink="/gap/category/cultural">
                  <div class="zone-wrapper">
                    <h3 class="zone-title">Cultural Methods - GAP</h3>
                  </div>
                  <div class="zone-bg"></div>
                </div>
                <div class="zone wow fadeInUp" data-wow-delay="0.9s" routerLink="/gap/category/physical">
                  <div class="zone-wrapper">
                    <h3 class="zone-title">Physical & Mechanical Methods - GAP</h3>
                  </div>
                  <div class="zone-bg"></div>
                </div>
                <div class="zone wow fadeInUp" data-wow-delay="1.0s" routerLink="/homemade-organic">
                  <div class="zone-wrapper">
                    <h3 class="zone-title">Homemade Solutions</h3>
                  </div>
                  <div class="zone-bg"></div>
                </div>
                <div class="zone wow fadeInUp" data-wow-delay="1.1s" routerLink="/control-methods/biocontrol">
                  <div class="zone-wrapper">
                    <h3 class="zone-title">Biocontrol - Control Methods</h3>
                  </div>
                  <div class="zone-bg"></div>
                </div>
                <div class="zone wow fadeInUp" data-wow-delay="1.2s" routerLink="/control-methods/biopesticide">
                  <div class="zone-wrapper">
                    <h3 class="zone-title">Biopesticide - Control Methods</h3>
                  </div>
                  <div class="zone-bg"></div>
                </div>
<!--                <div class="zone wow fadeInUp" data-wow-delay="1.3s" routerLink="/agrochem">-->
<!--                  <div class="zone-wrapper">-->
<!--                    <h3 class="zone-title">Agrochem Products</h3>-->
<!--                  </div>-->
<!--                  <div class="zone-bg"></div>-->
<!--                </div>-->
              </div>
            </div><!-- Closes .wrapper -->
          </div>
        </div>
      </div>
      <br><br><br>
    </div>
  </div>
</div>
<br>
<br>
<!--#Integrated Pest Management-->


<!--Database Summary-->
<div class="content-wrapper jarallax" style="background-image: url('../../../assets/summary.jpg');">
  <div class="layer-bg-top-left" style="background-image: url('../../../assets/noun_database_15321129.png');">
    <div class="layer-bg-bottom-right" style="background-image: url('../../../assets/noun_database_15321129.png');">
      <br><br><br>
      <div class="container my-3 pb-5">

        <div class="row">
          <div class="col-md-12">
            <h2 class="montserrat-font font-weight-600 sub-heading wow fadeInUp" data-wow-delay="0.6s">Database Summary</h2>
          </div>


          <div class="col-md-12">
            <div class="row no-gutters">
              <!--            <div class="col-md-6 col-lg-3 p-1 my-1" routerLink="/gap">-->
              <div class="col-md-6 col-lg-4 col-xl-4 p-1 my-1 wow fadeIn" data-wow-delay="0.7s">
                <mdb-card class="card-home card-gap">
                  <!--Card image-->
                  <img class="img-fluid"
                       width="100%"
                       height="auto"
                       src="../../../assets/3.jpg"
                       alt="Good Agricultural Practices"/>
                  <!--                <mdb-card-img src="../../../assets/3.png" alt="Good Agricultural Practices"></mdb-card-img>-->
                  <!--Card image-->
                  <!--Card content-->
                  <mdb-card-body>
                    <!--Title-->
                    <mdb-badge default="true" color="green darken-4">
                      Good Agricultural Practices (GAP)
                    </mdb-badge>
                    <mdb-card-title>
                      <div class="count-container">
                      <span class="count">
                        {{gapTotal}}
                      </span>
                        <mdb-card-text>
                        <span class="count-description">
                          Good agricultural Practices (GAPS) that will allow you to avoid pests diseases and weeds
                        </span>
                        </mdb-card-text>
                      </div>
                      <div class="col-12 call-to-action-wrapper">
                        <a class="call-to-action" routerLink="/gap">View</a>
                      </div>
                    </mdb-card-title>
                    <!--Text-->
                  </mdb-card-body>
                </mdb-card>
              </div>

              <!--            <div class="col-md-6 col-lg-3 p-1 my-1" routerLink="/homemade-organic">-->
              <div class="col-md-6 col-lg-4 col-xl-4 p-1 my-1 wow fadeIn" data-wow-delay="0.8s">
                <mdb-card class="card-home card-homemade-organic">
                  <!--Card image-->
                  <img class="img-fluid"
                       width="100%"
                       height="auto"
                       src="../../../assets/mulch2.jpg"
                       alt="Homemade Organic Solutions"/>
                  <!--                <mdb-card-img src="../../../assets/mulch2.jpg" alt="Homemade Organic Solutions"></mdb-card-img>-->
                  <!--Card image-->
                  <!--Card content-->
                  <mdb-card-body>
                    <!--Title-->
                    <mdb-badge default="true" color="green lighten-1">
                      Homemade Organic Solutions
                    </mdb-badge>
                    <mdb-card-title>
                      <div class="count-container">
                      <span class="count">
                        {{homemadeOrganicTotal}}
                      </span>
                        <mdb-card-text>
                        <span class="count-description">
                          Homemade Organic solutions we have compiled to tackle common pests and diseases.  If the GAPS don’t work, try these.
                        </span>
                        </mdb-card-text>
                      </div>
                    </mdb-card-title>
                    <!--Text-->
                    <div class="col-12 call-to-action-wrapper">
                      <a class="call-to-action" routerLink="/homemade-organic">View</a>
                    </div>
                  </mdb-card-body>
                </mdb-card>
              </div>

              <!--            <div class="col-md-6 col-lg-3 p-1 my-1" routerLink="/commercial-organic">-->
              <div class="col-md-6 col-lg-4 col-xl-4 p-1 my-1 wow fadeIn" data-wow-delay="0.9s">
                <mdb-card class="card-home card-commercial-organic">
                  <!--Card image-->
                  <img class="img-fluid"
                       width="100%"
                       height="auto"
                       src="../../../assets/commercial_organic2.jpg"
                       alt="Offshelf Organic"/>
                  <!--                <mdb-card-img src="../../../assets/commercial_organic2.jpg" alt="Offshelf Organic"></mdb-card-img>-->
                  <!--Card image-->
                  <!--Card content-->
                  <mdb-card-body>
                    <!--Title-->
                    <mdb-badge default="true" color="red darken-1">
                      Offshelf Organic
                    </mdb-badge>
                    <mdb-card-title>
                      <div class="count-container">
                      <span class="count">
                        {{commercialOrganicTotal}}
                      </span>
                        <mdb-card-text>
                        <span class="count-description">
                          Off the Shelf Organic Solutions that you Can Buy to solve pest and disease problems
                        </span>
                        </mdb-card-text>
                      </div>
                    </mdb-card-title>
                    <!--Text-->
                    <div class="col-12 call-to-action-wrapper">
                      <a class="call-to-action" routerLink="/commercial-organic">View</a>
                    </div>
                  </mdb-card-body>
                </mdb-card>
              </div>

              <!--            <div class="col-md-6 col-lg-3 p-1 my-1" routerLink="/agrochem">-->
<!--              <div class="col-md-6 col-lg-4 col-xl-3 p-1 my-1 wow fadeIn" data-wow-delay="1.0s">-->
<!--                <mdb-card class="card-home card-active-ingredients">-->
<!--                  &lt;!&ndash;Card image&ndash;&gt;-->
<!--                  <img class="img-fluid"-->
<!--                       width="100%"-->
<!--                       height="auto"-->
<!--                       src="../../../assets/agrochem2-1.jpg"-->
<!--                       alt="Agrochem Products"/>-->
<!--                  &lt;!&ndash;                <mdb-card-img src="../../../assets/agrochem2-1.jpg" alt="Agrochem Products"></mdb-card-img>&ndash;&gt;-->
<!--                  &lt;!&ndash;Card image&ndash;&gt;-->
<!--                  &lt;!&ndash;Card content&ndash;&gt;-->
<!--                  <mdb-card-body>-->
<!--                    &lt;!&ndash;Title&ndash;&gt;-->
<!--                    <mdb-badge default="true" color="red darken-4">-->
<!--                      Agrochem Products-->
<!--                    </mdb-badge>-->
<!--                    <mdb-card-title>-->
<!--                      <div class="count-container">-->
<!--                      <span class="count">-->
<!--                        {{agrochemTotal}}-->
<!--                      </span>-->
<!--                        <mdb-card-text>-->
<!--                        <span class="count-description">-->
<!--                          Agrochemical products that contain activie ingredients known to be toxic.-->
<!--                        </span>-->
<!--                        </mdb-card-text>-->
<!--                      </div>-->
<!--                    </mdb-card-title>-->
<!--                  </mdb-card-body>-->
<!--                  &lt;!&ndash;Text&ndash;&gt;-->
<!--                  <div class="call-to-action-wrapper">-->
<!--                    <a class="call-to-action" routerLink="/agrochem">View</a>-->
<!--                  </div>-->
<!--                </mdb-card>-->
<!--              </div>-->
            </div>
          </div>
          <br><br><br>

        </div>
      </div>
      <br><br><br>
    </div>
  </div>
</div>
<!--#Database Summary-->
