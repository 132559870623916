import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {PestsDiseaseWeed} from '../../shared/models/pests-disease-weed';
import {PestDiseaseWeedService} from '../../shared/services/pest-disease-weed.service';
import {Title} from '@angular/platform-browser';
import {AppSettings} from '../../app.settings';
import {ControlMethods} from '../../shared/models/control_methods';
import {Agrochem} from '../../shared/models/agrochem';
import {MessageService} from '../../shared/messages/message.service';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-pest-disease-weed-details',
  templateUrl: './pest-disease-weed-details.component.html',
  styleUrls: ['./pest-disease-weed-details.component.css']
})
export class PestDiseaseWeedDetailsComponent implements OnInit {

  baseUrl = environment.baseUrl;
  defaultImage = environment.defaultImage;
  errorImage = environment.errorImage;
  id: any;
  loading = true;
  loaded = false;
  error = false;
  pestsDiseaseWeed: PestsDiseaseWeed;
  // controlMethods: ControlMethods[];
  toxicAgrochems: Agrochem[];
  nonToxicAgrochems: Agrochem[];
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private pestDiseaseWeedService: PestDiseaseWeedService,
    private titleService: Title,
    public messageService: MessageService,
  ) { }

  ngOnInit(): void {
    this.getItem();
  }

  private getItem() {
    this.route.params.subscribe(routeParams => {
      this.id = routeParams.id;
      // Get pest, disease or weed item based on the id
      this.pestDiseaseWeedService.findById(this.id).subscribe(response => {
          this.pestsDiseaseWeed = response.data;
          this.loading = false;
          this.loaded = true;
          this.error = false;
          // Get pest, disease or weed's control methods
          // this.pestDiseaseWeedService.findControlMethods(this.id).subscribe(responseControlMethods => {
          //   this.controlMethods = responseControlMethods.data.control_methods;
          // });
          // Get pest, disease or weed's toxic agrochem products
          this.pestDiseaseWeedService.getAgrochemsByToxicity(this.id, 'true').subscribe(responseToxicAgrochems => {
            this.toxicAgrochems = responseToxicAgrochems.data.agrochem_products;
          });
          // Get pest, disease or weed's non toxic agrochem products
          this.pestDiseaseWeedService.getAgrochemsByToxicity(this.id, 'false').subscribe(responseNonToxicAgrochems => {
            this.nonToxicAgrochems = responseNonToxicAgrochems.data.agrochem_products;
          });
          // Set web page title
          this.titleService.setTitle(this.pestsDiseaseWeed.name);
        },
        error => {
          // Error handling if no pests, diseases/ weeds found
          this.messageService.showMessage('info', 'Not found', 'No pest, disease or weed found');
          this.loading = false;
          this.error = true;
        });
    });
  }
}
