import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'carcinogenicity'
})
export class CarcinogenicityPipe implements PipeTransform {

  transform(value: string): string {
    switch (value){
      case 'Yes':
        return 'Studies have shown that this product can cause cancer.';
      case 'No':
        return 'No studies have linked this product to causing cancer.';
      case 'Possible':
        return 'Although no direct causation has been proven, this product may cause cancer.';
      case 'No Data':
        return '– There is no data on this product in relation to cancer causation. Precautionary principle applies, avoid using if in doubt.';
      default:
        return '';
    }
  }

}
