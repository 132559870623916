import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PostsListComponent } from './posts-list/posts-list.component';
import { PostComponent } from './post/post.component';
import {MDBBootstrapModule} from 'angular-bootstrap-md';
import {FormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {SharedModule} from '../../shared/shared.module';
import {LazyLoadImageModule} from 'ng-lazyload-image';



@NgModule({
  declarations: [
    PostsListComponent,
    PostComponent
  ],
  imports: [
    CommonModule,
    MDBBootstrapModule,
    FormsModule,
    RouterModule,
    SharedModule,
    LazyLoadImageModule,
  ],
  exports: [
    PostsListComponent,
    PostComponent
  ],
})
export class PostsModule { }
