<div>
  <mdb-breadcrumb>
    <mdb-breadcrumb-item routerLink="/" class="blue-text">Home</mdb-breadcrumb-item>
    <mdb-breadcrumb-item class="active">About Us</mdb-breadcrumb-item>
  </mdb-breadcrumb>

  <div class="container">
    <div class="layer-bg-top-left" style="background-image: url('../../assets/noun_about us_14799159.png');">
      <div class="layer-bg-bottom-right" style="background-image: url('../../assets/noun_about us_14799159.png');">
        <div class="row mb-1 wow fadeInUp" data-wow-delay="0.3s">
          <div class="col-sm">
            <span class="component-title">
              About Us
            </span>
          </div>
        </div>
        <div class="component-content">
          <div class="row shadow-md wow fadeIn" data-wow-delay="0.6s">
            <div class="col-12">
              <div class="content-1 mb-5 pb-5">
                This database contains information about the common pests, diseases and weeds which are mainly controlled using the toxic chemicals. The toxic active ingredients were first identified and traced back to the common pests, diseases and weeds they are used against. Data was later gathered on the alternatives from research, farmers practices that have worked and products from companies producing bio pesticides as well as less toxic alternatives. The information contained here-in is a summary of the alternatives. It is a dynamic site that will continuously upload more information as it is generated. Our aim is to provide genuine unbiased information that will help producers make informed choices on how they farm and hopefully use less toxic chemicals and assure food safety, environment protection for a better planet for all of us.
              </div>
            </div>
            <div class="col-12">
              <div class="content-1 mb-5 pb-5">
                Research and information on this database was developed by the Kenya Organic Agriculture Network in partnership with <a href="http://www.eco-trac.de/">Ecotrac Consulting</a>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>



