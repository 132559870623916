import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'birdsColor'
})
export class BirdsColorPipe implements PipeTransform {

  transform(value: number): string {
    if (value < 100){
      return 'red darken-3';
    }else if (value >= 100 && value <= 2000 ){
      return 'yellow darken-2';
    }else if (value > 2000 ){
      return 'green darken-1';
    }
    return ;
  }

}
