import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MessageService} from '../../shared/messages/message.service';
import {GlobalSearchService} from '../../shared/services/global-search.service';
import {FormControl} from '@angular/forms';
import {AppSettings} from '../../app.settings';
import {environment} from '../../../environments/environment';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-global-search-results',
  templateUrl: './global-search-results.component.html',
  styleUrls: ['./global-search-results.component.css']
})
// Global Search implementation based on Spatie/Search API
export class GlobalSearchResultsComponent implements OnInit {

  baseUrl = environment.baseUrl;
  loading = true;
  loaded = false;
  error = false;
  searchValue: string;
  searchResults: any;
  total: any;
  from: any;
  to: any;
  page: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public messageService: MessageService,
    private globalSearchService: GlobalSearchService,
    private titleService: Title,
  ) { }


  ngOnInit(): void {
    this.route.params.subscribe(routeParams => {
      this.searchValue = routeParams.searchValue;
      this.route.queryParams.subscribe(params => {
        // Defaults to 0 if no query param provided.
        this.page = params.page || 0;
        this.loading = true;
        this.searchGlobal(this.searchValue);
      });
    });
  }



  public searchGlobal(searchValue: string){
    if (this.page > 0){
    }
    this.titleService.setTitle('Search - ' + searchValue);
    // Pass value to the search API
    this.globalSearchService.searchAlt(searchValue, this.page).subscribe(response => {
        this.searchResults = response.data.data;
        this.total = response.data.total;
        this.from = response.data.from;
        this.to = response.data.to;
        this.loading = false;
        this.loaded = true;
        this.error = false;
      },
      error => {
        // Error handling
        this.messageService.showMessage('info', 'Not found', 'No results found related to \"' + this.searchValue + '\"' );

        this.loading = false;
        this.loaded = false;
        this.error = true;
      }
    );
  }



  public containsValue(key: string, text: any, searchValue: string): boolean{
    // Exclude field with the following keys as they are not to be displayed in the matches
    if (key.toString().toLowerCase() === 'image'){
      return false;
    }else{
      // Determine if a field contains the search value so as to be displayed in the search results
      searchValue = searchValue.toLowerCase();
      let stringText = '';
      if (text == null){
        stringText = '';
      }else if (text instanceof Array){
        stringText = text.join(' ');
      }else if (text instanceof Object){
        stringText = text.valueOf().toString();
      }else{
        stringText = text.toString();
      }
      return stringText.toLowerCase().includes(searchValue);
    }
  }



}
