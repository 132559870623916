import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams} from '@angular/common/http';
import {catchError, retry} from 'rxjs/operators';
import {throwError} from 'rxjs';
import {environment} from '../../../environments/environment';

const httpOptions = {
  headers: new HttpHeaders(
    { 'Content-Type': 'application/json' }
  )
};

@Injectable({
  providedIn: 'root'
})
export class AgrochemProductsService {

  constructor(private http: HttpClient) { }

  // Fetch all agrochem products
  getAll(page = '1', perPage = '20', orderColumn = 'id', orderDirection = 'desc'){
    const params = new HttpParams()
      .set('page', page)
      .set('per_page', perPage)
      .set('order_column', orderColumn)
      .set('order_direction', orderDirection)
    ;
    const url = `${environment.baseUrl}agrochem/`;
    return this.http.get<any>(url, {params})
      .pipe(
        retry(3), // retry a failed request up to 3 times
        catchError(this.handleError)
      );
  }

  // Fetch agrochem product based on id
  findById(id: string){
    const url = `${environment.baseUrl}agrochem/${id}`;
    return this.http.get<any>(url)
      .pipe(
        retry(3), // retry a failed request up to 3 times
        catchError(this.handleError)
      );
  }

  // Get agrochem products based on toxicity (true|false)
  getToxic(toxic: string, page = '1', perPage = '20', orderColumn = 'id', orderDirection = 'desc',  searchValue = '') {
    const params = new HttpParams()
      .set('toxic', toxic)
      .set('page', page)
      .set('per_page', perPage)
      .set('order_column', orderColumn)
      .set('order_direction', orderDirection)
    ;
    const url = `${environment.baseUrl}agrochem/filter`;
    return this.http.get<any>(url, {params})
      .pipe(
        retry(3), // retry a failed request up to 3 times
        catchError(this.handleError)
      );
  }

  // Find agrochem product names on toxicity
  getSummaryNamesByToxic(toxic = '', page = '1', perPage = '20', orderColumn = 'id', orderDirection = 'desc',  searchValue = '') {
    let params: HttpParams;
    if(toxic === 'true' || toxic === 'false'){
      params = new HttpParams()
        .set('page', page)
        .set('per_page', perPage)
        .set('order_column', orderColumn)
        .set('order_direction', orderDirection)
        .set('toxic', toxic)
      ;
    }else{
      params = new HttpParams()
        .set('page', page)
        .set('per_page', perPage)
        .set('order_column', orderColumn)
        .set('order_direction', orderDirection)
      ;
    }
    const url = `${environment.baseUrl}agrochem/summary/names/${searchValue}`;
    return this.http.get<any>(url, {params})
      .pipe(
        retry(3), // retry a failed request up to 3 times
        catchError(this.handleError)
      );
  }

  // Find pest, disease or weed's commercial organic products
  getCommercialOrganic(id: string){
    const  params = new  HttpParams()
    ;
    const url = `${environment.baseUrl}agrochem/${id}/commercial_organic`;
    return this.http.get<any>(url, {params})
      .pipe(
        retry(3), // retry a failed request up to 3 times
        catchError(this.handleError)
      );
  }

  // Find pest, disease or weed's homemade organic
  getHomemadeOrganic(id: string){
    const  params = new  HttpParams()
    ;
    const url = `${environment.baseUrl}agrochem/${id}/homemade_organic`;
    return this.http.get<any>(url, {params})
      .pipe(
        retry(3), // retry a failed request up to 3 times
        catchError(this.handleError)
      );
  }

  // Find pest, disease or weed's GAP items
  getGap(id: string){
    const  params = new  HttpParams()
    ;
    const url = `${environment.baseUrl}agrochem/${id}/gap`;
    return this.http.get<any>(url, {params})
      .pipe(
        retry(3), // retry a failed request up to 3 times
        catchError(this.handleError)
      );
  }

  // Error handlind
  private handleError(error: HttpErrorResponse): any {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error.message}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  }
}
