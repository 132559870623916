import { Component, OnInit } from '@angular/core';
import {HomemadeOrganic} from '../../shared/models/homemade_organic';
import {ActivatedRoute} from '@angular/router';
import {HomemadeOrganicService} from '../../shared/services/homemade-organic.service';
import {Title} from '@angular/platform-browser';
import {AppSettings} from '../../app.settings';
import {MessageService} from '../../shared/messages/message.service';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-homemade-organic-details',
  templateUrl: './homemade-organic-details.component.html',
  styleUrls: ['./homemade-organic-details.component.css']
})
export class HomemadeOrganicDetailsComponent implements OnInit {

  baseUrl = environment.baseUrl;
  defaultImage = environment.defaultImage;
  errorImage = environment.errorImage;
  id: any;
  loading = true;
  loaded = false;
  error = false;
  homemadeOrganic: HomemadeOrganic;

  constructor(
    private titleService: Title,
    private route: ActivatedRoute,
    private homemadeOrganicService: HomemadeOrganicService,
    public messageService: MessageService,
  ) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.params.id;
    this.getItem();



  }

  private getItem() {
    // Get homemade organic item based on id
    this.homemadeOrganicService.findById(this.id).subscribe(response => {
        this.homemadeOrganic = response.data;
        this.loading = false;
        this.loaded = true;
        this.error = false;
        this.titleService.setTitle(this.homemadeOrganic.name);
      },
      error => {
      // Error handling
        this.messageService.showMessage('info', 'Not found', 'Homemade solution not found');
        this.loading = false;
        this.error = true;
      });
  }
}
