import { Component, OnInit } from '@angular/core';
import {ActiveIngredients} from '../../shared/models/active_ingredients';
import {ActivatedRoute} from '@angular/router';
import {ActiveIngredientsService} from '../../shared/services/active-ingredients.service';
import {Title} from '@angular/platform-browser';
import {AppSettings} from '../../app.settings';
import {Agrochem} from '../../shared/models/agrochem';
import {MessageService} from '../../shared/messages/message.service';
import {environment} from '../../../environments/environment';
import {PestsDiseaseWeed} from '../../shared/models/pests-disease-weed';

@Component({
  selector: 'app-active-ingredients-details',
  templateUrl: './active-ingredients-details.component.html',
  styleUrls: ['./active-ingredients-details.component.css']
})
export class ActiveIngredientsDetailsComponent implements OnInit {

  baseUrl = environment.baseUrl;
  defaultImage = environment.defaultImage;
  errorImage = environment.errorImage;
  id: any;
  loading = true;
  loaded = false;
  error = true;
  activeIngredient: ActiveIngredients;
  toxicAgrochems: Agrochem[];
  nonToxicAgrochems: Agrochem[];
  commercialOrganicPdw: PestsDiseaseWeed[];
  commercialOrganicTotal = 0;
  gapPdw: PestsDiseaseWeed[];
  gapTotal = 0;
  homemadeOrganicPdw: PestsDiseaseWeed[];
  homemadeOrganicTotal = 0;

  constructor(
    private titleService: Title,
    private route: ActivatedRoute,
    private activeIngredientService: ActiveIngredientsService,
    public messageService: MessageService,
  ) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.params.id;
    this.getItem();
  }

  private getItem() {
// Fetches the active ingredient data from the API using the service
    this.activeIngredientService.findById(this.id).subscribe(response => {
        this.activeIngredient = response.data;
        this.loading = false;
        this.loaded = true;
        this.error = false;
        this.titleService.setTitle(this.activeIngredient.name);
        // Fetches the toxic agrochem data from the API using the service
        this.activeIngredientService.getAgrochemsByToxicity(this.id, 'true').subscribe(responseToxicAgrochems => {
          this.toxicAgrochems = responseToxicAgrochems.data.agrochem;
        });
        // Fetches the non toxic agrochem data from the API using the service
        this.activeIngredientService.getAgrochemsByToxicity(this.id, 'false').subscribe(responseNonToxicAgrochems => {
          this.nonToxicAgrochems = responseNonToxicAgrochems.data.agrochem;
        });

        // Get pest, disease or weed's commercial organic products
        this.activeIngredientService.getCommercialOrganic(this.id).subscribe(responseCommercialOrganic => {
          this.commercialOrganicTotal = responseCommercialOrganic.total;
          this.commercialOrganicPdw = responseCommercialOrganic.data;
        });
        // Get pest, disease or weed's homemade organic products
        this.activeIngredientService.getHomemadeOrganic(this.id).subscribe(responseHomemadeOrganic => {
          this.homemadeOrganicTotal = responseHomemadeOrganic.total;
          this.homemadeOrganicPdw = responseHomemadeOrganic.data;
        });
        // Get pest, disease or weed's GAP items
        this.activeIngredientService.getGap(this.id).subscribe(responseGap => {
          this.gapTotal = responseGap.total;
          this.gapPdw = responseGap.data;
        });
      },
      error => {
        // Handles response when the data is not available
        this.messageService.showMessage('info', 'Not found', 'Active ingredient not found');
        this.loading = false;
        this.error = true;
      });
  }
}
