import { Component, OnInit } from '@angular/core';
import {Agrochem} from '../../shared/models/agrochem';
import {AgrochemProductsService} from '../../shared/services/agrochem-products.service';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute, Router} from '@angular/router';
import {MessageService} from '../../shared/messages/message.service';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-agrochem-products-dashboard',
  templateUrl: './agrochem-products-dashboard.component.html',
  styleUrls: ['./agrochem-products-dashboard.component.css']
})
export class AgrochemProductsDashboardComponent implements OnInit {


  totalRecords: number;
  to: number;
  from: number;
  currentPage: number;
  lastPage: number;
  perPage = '20';
  perPageChanged = false;
  page: string;
  orderColumn = 'id';
  orderDirection = 'desc';
  orderChanged = false;
  ordering = '';
  searchValue = '';


  baseUrl = environment.baseUrl;
  defaultImage = environment.defaultImage;
  loading = true;
  loaded = false;
  error = false;
  agrochems: Agrochem[];
  title: string;
  toxic: string;
  toxicPath: string;
  category: string;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public messageService: MessageService,
    private titleService: Title,
    private agrochemService: AgrochemProductsService,
  ) { }

  ngOnInit(): void {
    // const currentUrl = this.router.url;
    const currentUrl = this.route.routeConfig.path;
    switch (currentUrl){
      case 'agrochem/toxic':
        this.route.params.subscribe(routeParams => {
          // Get page query param
          this.route.queryParams.subscribe(params => {
            // Defaults to 0 if no query param provided.
            this.page = params.page || '1';
            this.searchValue = params.q || '';
            this.perPage = params.limit || '20';
            this.ordering = params.ordering || '#';
            this.setOrdering(this.ordering);
            this.loading = true;
            this.loaded = false;
            this.category = 'toxic';
            this.toxicPath = '/toxic';
            this.getNamesByToxic('true', 'Agrochemicals');
          });
        });
        break;
      case 'agrochem/nontoxic':
        this.route.params.subscribe(routeParams => {
          // Get page query param
          this.route.queryParams.subscribe(params => {
            // Defaults to 0 if no query param provided.
            this.page = params.page || '1';
            this.searchValue = params.q || '';
            this.perPage = params.limit || '20';
            this.ordering = params.ordering || '#';
            this.setOrdering(this.ordering);
            this.loading = true;
            this.loaded = false;
            this.category = 'nontoxic';
            this.toxicPath = '/nontoxic';
            this.getNamesByToxic('false', 'Less Toxic Agrochemicals');
          });
        });
        break;
      case 'agrochem':
        // Get page query param
        this.route.queryParams.subscribe(params => {
          // Defaults to 0 if no query param provided.
          this.page = params.page || '1';
          this.searchValue = params.q || '';
          this.perPage = params.limit || '20';
          this.ordering = params.ordering || '#';
          this.setOrdering(this.ordering);
          this.loading = true;
          this.loaded = false;
          this.toxicPath = '';
          this.getNamesByToxic('', 'Agrochem Products');
        });
        break;
      default:
        this.router.navigateByUrl('/404');
        break;
    }
  }

  // Fetch all agrochem products
  private getAll(title) {
    this.title = title;
    this.titleService.setTitle(this.title);
    this.agrochemService.getAll(this.page, this.perPage, this.orderColumn, this.orderDirection).subscribe(response => {
      this.agrochems = response.data;
      this.page = response.meta.page;
      this.currentPage = response.meta.current_page;
      this.lastPage = response.meta.last_page;
      this.from = response.meta.from;
      this.to = response.meta.to;
      this.loading = false;
      this.loaded = true;
      this.error = false;
    },
      error => {
        this.messageService.showMessage('info', 'Not found',  'No agrochem products found');
        this.loading = false;
        this.error = true;
    });
  }

  // Fetch agrochem products by toxicity
  private getToxic(toxic: string, title: string) {
    this.title = title;
    this.titleService.setTitle(this.title);
    this.agrochemService.getToxic(toxic, this.page, this.perPage, this.orderColumn, this.orderDirection).subscribe(response => {
      this.agrochems = response.data;
      this.totalRecords = response.meta.total;
      this.page = response.meta.page;
      this.currentPage = response.meta.current_page;
      this.lastPage = response.meta.last_page;
      this.from = response.meta.from;
      this.to = response.meta.to;
      this.title = title;
      this.loading = false;
      this.loaded = true;
      this.error = false;
      },
      error => {
        this.messageService.showMessage('info', 'Not found', title + ' agrochemical products not found');
        this.loading = false;
        this.error = true;
    });
  }

  private getNamesByToxic(toxic: string, title: string) {
    this.toxic = toxic;
    this.title = title;
    this.titleService.setTitle(title);
    // Fetch pests, diseases or weeds based on the type
    this.filter(this.toxic, this.page, this.perPage, this.orderColumn, this.orderDirection, this.searchValue);
  }

  private filter(toxic = '', page = '', perPage = '', orderColumn = '', orderDirection = '', searchValue = '') {
    this.agrochemService.getSummaryNamesByToxic(toxic, page, perPage, orderColumn, orderDirection, searchValue).subscribe(response => {
        this.agrochems = response.data;
        this.totalRecords = response.total;
        this.page = response.page;
        this.currentPage = response.current_page;
        this.lastPage = response.last_page;
        this.from = response.from;
        this.to = response.to;
        this.loading = false;
        this.loaded = true;
        this.error = false;
      },
      error => {
        // Error handling
        this.messageService.showMessage('info', 'Not found', this.title + 's not found');
        this.loading = false;
        this.error = true;
      }
    );
  }

// Capitalize the first character of a string
  private capitalize(word: string)  {
    return word.charAt(0).toUpperCase() + word.slice(1);
  }

  public goToPage(page){
    this.loading = true;
    this.loaded = false;
    this.page = page;
    // Load in same page
    // this.filter(this.type, this.page, this.perPage, this.orderColumn, this.orderDirection, this.searchValue);
    // Navigate to page
    this.router.navigateByUrl(`/agrochem${this.toxicPath ? this.toxicPath : ''}?page=${page}${this.orderChanged ? '&ordering=' + this.ordering : ''}${this.searchValue !== '' ? '&q=' + this.searchValue : ''}${this.perPage !== '20' ? '&limit=' + this.perPage : ''}`);

  }
  // Handles an item search based on search value
  public search(){
    this.loading = true;
    this.loaded = false;
    this.page = '1';
    this.filter(this.toxic, this.page, this.perPage, this.orderColumn, this.orderDirection, this.searchValue);
  }
  // Handle change in items per pagge to be displayed
  public itemsPerPageChanged(){
    this.loading = true;
    this.loaded = false;
    this.page = '1';
    this.perPageChanged = true;
    this.filter(this.toxic, this.page, this.perPage, this.orderColumn, this.orderDirection, this.searchValue);
  }
  // Handle change in sort order and field
  public sortOrderChanged() {
    const value = this.ordering;
    this.setOrdering(value);
    this.loading = true;
    this.loaded = false;
    this.page = '1';

    this.filter(this.toxic, this.page, this.perPage, this.orderColumn, this.orderDirection, this.searchValue);
  }
  private setOrdering(value){
    if (value.indexOf('!') === 0) {
      this.orderColumn = value.substring(1, value.length);
      this.orderDirection = 'desc';
      this.orderChanged = true;
    } else if (value === '#'){
      // Default Ordering
      this.orderColumn = 'id';
      this.orderDirection = 'desc';
      this.orderChanged = false;
    } else {
      this.orderColumn = value;
      this.orderDirection = 'asc';
      this.orderChanged = true;
    }
  }
}
