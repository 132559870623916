import { Component, OnInit } from '@angular/core';
import {Title} from '@angular/platform-browser';
import {MessageService} from '../shared/messages/message.service';

@Component({
  selector: 'app-advocacy',
  templateUrl: './advocacy.component.html',
  styleUrls: ['./advocacy.component.css']
})
export class AdvocacyComponent implements OnInit {

  constructor(
    private titleService: Title,
    public messageService: MessageService,
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle('Disclaimer');
  }

}
