
import {NgModule} from '@angular/core';
import {ActiveIngredientsModule} from './active-ingredients/active-ingredients.module';
import {AgrochemProductsModule} from './agrochem-products/agrochem-products.module';
import {CommercialOrganicModule} from './commercial-organic/commercial-organic.module';
import {ControlMethodsModule} from './control-methods/control-methods.module';
import {CropsModule} from './crops/crops.module';
import {GapModule} from './gap/gap.module';
import {GlobalSearchModule} from './global-search/global-search.module';
import {HomeModule} from './home/home.module';
import {HomemadeOrganicModule} from './homemade-organic/homemade-organic.module';
import {PestDiseaseWeedModule} from './pest-disease-weed/pest-disease-weed.module';
import {CommonModule} from '@angular/common';
import {SharedModule} from './shared/shared.module';
import {AboutUsModule} from './about-us/about-us.module';
import {HowToModule} from './how-to/how-to.module';
import {AdvocacyModule} from './advocacy/advocacy.module';
import {DisclaimerModule} from './disclaimer/disclaimer.module';
import {DownloadsModule} from './cms/downloads/downloads.module';
import {MediaModule} from './cms/media/media.module';
import {PostsModule} from './cms/posts/posts.module';

@NgModule({
  imports: [
    AboutUsModule,
    HowToModule,
    AdvocacyModule,
    DisclaimerModule,
    ActiveIngredientsModule,
    AgrochemProductsModule,
    CommercialOrganicModule,
    ControlMethodsModule,
    CropsModule,
    GapModule,
    GlobalSearchModule,
    HomeModule,
    HomemadeOrganicModule,
    PestDiseaseWeedModule,
    DownloadsModule,
    MediaModule,
    SharedModule
  ],
  exports: [
    AboutUsModule,
    HowToModule,
    AdvocacyModule,
    DisclaimerModule,
    ActiveIngredientsModule,
    AgrochemProductsModule,
    CommercialOrganicModule,
    ControlMethodsModule,
    CropsModule,
    GapModule,
    GlobalSearchModule,
    HomeModule,
    HomemadeOrganicModule,
    PestDiseaseWeedModule,
    DownloadsModule,
    MediaModule,
    SharedModule,
    PostsModule
  ],
  declarations: [
  ],
})

export  class  PDWModule { }
