import { Injectable } from '@angular/core';
import {Message} from './message/message';
import {MatSnackBar, MatSnackBarConfig} from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class MessageService {
  messages: Message[] = [];

  constructor(
    private snackBar: MatSnackBar
  ) { }

  add(message: Message){
    this.messages.push(message);
  }

  clear(){
    this.messages = [];
  }

  showMessage(type: string, title: string, message: string){
    // const msg = new Message();
    // msg.type = type;
    // msg.title = title;
    // msg.message = message;
    //
    // this.add(msg);


    // Display snackbar with the message
    this.snackBar.open(
      message,
      'OK',
      {
        duration: 3000,
        verticalPosition: 'top'
      }
      );
  }
}
