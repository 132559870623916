import { Injectable } from '@angular/core';
import {PestDiseaseWeedService} from './pest-disease-weed.service';
import {HttpClient} from '@angular/common/http';
import {GapService} from './gap.service';
import {HomemadeOrganicService} from './homemade-organic.service';
import {CommercialOrganicService} from './commercial-organic.service';
import {ControlMethodsService} from './control-methods.service';
import {ActiveIngredientsService} from './active-ingredients.service';
import {MessageService} from '../messages/message.service';
import {PestsDiseaseWeed} from '../models/pests-disease-weed';

// import * as Excel from 'exceljs/dist/exceljs.min.js';
// import * as ExcelProper from 'exceljs';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import {Gap} from '../models/gap';
import {ControlMethods} from '../models/control_methods';
import {CommercialOrganic} from '../models/commercial-organic';
import {HomemadeOrganic} from '../models/homemade_organic';
import {LocalNames} from '../models/local_names';
import {Crops} from '../models/crops';
import {Agrochem} from '../models/agrochem';
import {ActiveIngredients} from '../models/active_ingredients';

@Injectable({
  providedIn: 'root'
})
export class JsonToXlsService {

  constructor(
    private http: HttpClient,
    private pestDiseaseWeedService: PestDiseaseWeedService,
    private gapService: GapService,
    private homemadeOrganicService: HomemadeOrganicService,
    private commercialOrganicService: CommercialOrganicService,
    private controlMethodsService: ControlMethodsService,
    private activeIngredientsService: ActiveIngredientsService,
    private messageService: MessageService,
  ) { }

  convertPdwDetailsToCsv(){

  }
  convertPdwToXls(type: string, page = '1', perPage = '20', orderColumn = 'id', orderDirection = 'desc', searchValue = ''){
    const header = [
      'ID',
      'Name',
      'Type',
      'Scientific Name',
      'Description',
      'Effect On Crops',
      'References',
      'Crops',
      'Local Names',
      'Homemade Organic Practices',
      'Offshelf Organic Products',
      'Control Methods',
      'GAP',
    ];

    let data = [];
    let pestDiseaseWeeds: PestsDiseaseWeed[];
    this.pestDiseaseWeedService.findByType(type, page, perPage, orderColumn, orderDirection, searchValue).subscribe(response => {
        pestDiseaseWeeds = response.data;
        data = this.generatePDWXLSData(pestDiseaseWeeds);

        const workbook = new Workbook();

        // const workbook: ExcelProper.Workbook = new Excel.Workbook();
        const worksheet = workbook.addWorksheet('Pests, Diseases, Weeds');

        // Add Header Row
        const headerRow = worksheet.addRow(header);

        // Cell Style : Fill and Border
        headerRow.eachCell((cell, num) => {
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFFFFF00' },
            bgColor: { argb: 'FF0000FF' }
          };
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' }
          };
        });

        // Add Data and Conditional Formatting
        data.forEach(d => {
            const row = worksheet.addRow(d);
            // const qty = row.getCell(5);
            // let color = 'FF99FF99';
            // if (+qty.value < 500) {
            //   color = 'FF9999'
            // }
            // qty.fill = {
            //   type: 'pattern',
            //   pattern: 'solid',
            //   fgColor: { argb: color }
            // }
          }
        );


        // Export file using FileSaver
        workbook.xlsx.writeBuffer().then((dta) => {
          const blob = new Blob(
            [dta],
            { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }
            );
          fs.saveAs(blob, 'pdw.xlsx');
        });


      },
      error => {
        // Error handling if no pests, diseases/ weeds found
        this.messageService.showMessage('info', 'Not found', 'Generation Failed\n Item not found');
      });

  }

  private generatePDWXLSData(pestsDiseasesWeeds: PestsDiseaseWeed[]){
    const data = [];
    for (const item of pestsDiseasesWeeds) {
      const row = [];
      row.push(item.id);
      row.push(this.isNull(item.name) ? '' : item.name);
      row.push(this.isNull(item.type) ? '' : item.type);
      row.push(this.isNull(item.scientific_name) ? '' : item.scientific_name);
      row.push(this.isNull(item.description_pest) ? '' : this.stringToHTML(item.description_pest));
      row.push(this.isNull(item.description_impact) ? '' : this.stringToHTML(item.description_impact));
      row.push(this.isNull(item.references) ? '' : this.stringToHTML(item.references));
      row.push(this.isNull(item.crops) ? '' : this.cropsToString(item.crops));
      row.push(this.isNull(item.local_names) ? '' : this.localNamesToString(item.local_names));
      row.push(this.isNull(item.homemade_organic) ? '' : this.homemadeOrganicsToString(item.homemade_organic));
      row.push(this.isNull(item.commercial_organic) ? '' : this.commercialOrganicsToString(item.commercial_organic));
      row.push(this.isNull(item.control_methods) ? '' : this.controlMethodsToString(item.control_methods));
      row.push(this.isNull(item.gap) ? '' : this.stringToHTML(this.gapsToString(item.gap)));

      data.push(row);
    }

    return data;
  }

  private generateAgrochemXLSData(agrochems: Agrochem[]){
    const data = [];
    for (const item of agrochems) {
      const row = [];
      row.push(item.id);
      row.push(this.isNull(item.product_name) ? '' : item.product_name);
      row.push(this.isNull(item.pcpb_number) ? '' : item.pcpb_number);
      row.push(this.isNull(item.who_class) ? '' : this.stringToHTML(item.who_class));
      row.push(this.isNull(item.toxic) ? '' : item.toxic);
      row.push(this.isNull(item.type) ? '' : item.type);
      row.push(this.isNull(item.composition) ? '' : this.stringToHTML(item.composition));
      row.push(this.isNull(item.distributing_company) ? '' : this.stringToHTML(item.distributing_company));
      row.push(this.isNull(item.registrant) ? '' : this.stringToHTML(item.registrant));
      row.push(this.isNull(item.phi_days) ? '' : this.stringToHTML(item.phi_days));

      data.push(row);
    }

    return data;
  }

  private generateActiveIngredientsXLSData(activeIngredients: ActiveIngredients[]){
    const data = [];
    for (const item of activeIngredients) {
      const row = [];
      row.push(item.id);
      row.push(this.isNull(item.name) ? '' : item.name);
      row.push(this.isNull(item.who_classification) ? '' : item.who_classification);
      row.push(this.isNull(item.potential_harm) ? '' : item.potential_harm);
      row.push(this.isNull(item.aquatic) ? '' : this.stringToHTML(item.aquatic));
      row.push(this.isNull(item.bees) ? '' : this.stringToHTML(item.bees));
      row.push(this.isNull(item.earthworm) ? '' : this.stringToHTML(item.earthworm));
      row.push(this.isNull(item.birds) ? '' : this.stringToHTML(item.birds));
      row.push(this.isNull(item.leachability) ? '' : this.stringToHTML(item.leachability));
      row.push(this.isNull(item.carcinogenicity) ? '' : this.stringToHTML(item.carcinogenicity));
      row.push(this.isNull(item.mutagenicity) ? '' : this.stringToHTML(item.mutagenicity));
      row.push(this.isNull(item.edc) ? '' : this.stringToHTML(item.edc));
      row.push(this.isNull(item.reproduction) ? '' : this.stringToHTML(item.reproduction));
      row.push(this.isNull(item.ache) ? '' : this.stringToHTML(item.ache));
      row.push(this.isNull(item.neurotoxicant) ? '' : this.stringToHTML(item.neurotoxicant));
      row.push(this.isNull(item.eu_approved) ? '' : this.stringToHTML(item.eu_approved));

      data.push(row);
    }

    return data;
  }

  private generateControlMethodsXLSData(controlMethods: ControlMethods[]){
    const data = [];
    for (const item of controlMethods) {
      const row = [];
      row.push(item.id);
      row.push(this.isNull(item.name) ? '' : item.name);
      row.push(this.isNull(item.category) ? '' : item.category);
      row.push(this.isNull(item.options) ? '' : this.stringToHTML(item.options));
      row.push(this.isNull(item.external_links) ? '' : this.stringToHTML(item.external_links));

      data.push(row);
    }

    return data;
  }

  private generateCommercialOrganicXLSData(commercialOrganics: CommercialOrganic[]){
    const data = [];
    for (const item of commercialOrganics) {
      const row = [];
      row.push(item.id);
      row.push(this.isNull(item.name) ? '' : item.name);
      row.push(this.isNull(item.pcpb_number) ? '' : item.pcpb_number);
      row.push(this.isNull(item.manufacturer) ? '' : item.manufacturer);
      row.push(this.isNull(item.distributor) ? '' : item.distributor);
      row.push(this.isNull(item.contact_details) ? '' : this.stringToHTML(item.contact_details));
      row.push(this.isNull(item.external_links) ? '' : this.stringToHTML(item.external_links));
      row.push(this.isNull(item.application_details) ? '' : this.stringToHTML(item.application_details));

      data.push(row);
    }

    return data;
  }

  private generateCropsXLSData(crops: Crops[]){
    const data = [];
    for (const item of crops) {
      const row = [];
      row.push(item.id);
      row.push(this.isNull(item.name) ? '' : item.name);

      data.push(row);
    }

    return data;
  }

  private generateHomemadeOrganicXLSData(homemadeOrganics: HomemadeOrganic[]){
    const data = [];
    for (const item of homemadeOrganics) {
      const row = [];
      row.push(item.id);
      row.push(this.isNull(item.name) ? '' : item.name);
      row.push(this.isNull(item.practices) ? '' : this.stringToHTML(item.practices));
      row.push(this.isNull(item.external_links) ? '' : this.stringToHTML(item.external_links));

      data.push(row);
    }

    return data;
  }

  private generateGapXLSData(gaps: Gap[]){
    const data = [];
    for (const item of gaps) {
      const row = [];
      row.push(item.id);
      row.push(this.isNull(item.name) ? '' : item.name);
      row.push(this.isNull(item.category) ? '' : item.category);
      row.push(this.isNull(item.practices) ? '' : this.stringToHTML(item.practices));
      row.push(this.isNull(item.references) ? '' : this.stringToHTML(item.references));

      data.push(row);
    }

    return data;
  }

  private stringToHTML(val) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(val, 'text/html');
    return doc.body.innerText;
  }

  private isNull(val){
    if (val instanceof Array && val.length === 0){
      return true;
    }else if (val == null){
      return true;
    }else{
      return false;
    }
  }

  private cropsToString(crops: Crops[]){
    let strCrops = '';
    let i = 1;
    const total = crops.length;
    for (const crop of crops){
      strCrops += crop.name;
      strCrops += i < total ? '\r\n ' : '';
      ++i;
    }

    return strCrops;
  }

  private localNamesToString(localNames: LocalNames[]){
    let strLocalNames = '';
    let j = 1;
    const total = localNames.length;
    for (const localName of localNames){
      strLocalNames += localName.local_name;
      strLocalNames += j < total ? ', ' : '';
      ++j;
    }

    return strLocalNames;
  }

  private homemadeOrganicsToString(homemadeOrganics: HomemadeOrganic[]){
    let strHomemadeOrganics = '';
    let k = 1;
    const total = homemadeOrganics.length;
    for (const homemadeOrganic of homemadeOrganics){
      strHomemadeOrganics += homemadeOrganic.name;
      strHomemadeOrganics += k < total ? ', ' : '';
      ++k;
    }

    return strHomemadeOrganics;
  }

  private commercialOrganicsToString(commercialOrganics: CommercialOrganic[]){
    let strCommercialOrganics = '';
    let l = 1;
    const total = commercialOrganics.length;
    for (const commercialOrganic of commercialOrganics){
      strCommercialOrganics += commercialOrganic.name;
      strCommercialOrganics += l < total ? ', ' : '';
      ++l;
    }

    return strCommercialOrganics;
  }

  private controlMethodsToString(controlMethods: ControlMethods[]){
    let strControlMethods = '';
    let m = 1;
    const total = controlMethods.length;
    for (const controlMethod of controlMethods){
      strControlMethods += controlMethod.name;
      strControlMethods += m < total ? ', ' : '';
      ++m;
    }

    return strControlMethods;
  }

  private gapsToString(gaps: Gap[]){
    let strGaps = '';
    let n = 1;
    const total = gaps.length;
    for (const gap of gaps){
      strGaps += gap.practices;
      strGaps += n < total ? ', ' : '';
      ++n;
    }

    return strGaps;
  }
}
