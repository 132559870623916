import { Component, OnInit } from '@angular/core';
import {Crops} from '../../shared/models/crops';
import {ActivatedRoute} from '@angular/router';
import {CropsService} from '../../shared/services/crops.service';
import {Title} from '@angular/platform-browser';
import {PestsDiseaseWeed} from '../../shared/models/pests-disease-weed';
import {Agrochem} from '../../shared/models/agrochem';
import {AppSettings} from '../../app.settings';
import {MessageService} from '../../shared/messages/message.service';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-crops-details',
  templateUrl: './crops-details.component.html',
  styleUrls: ['./crops-details.component.css']
})
export class CropsDetailsComponent implements OnInit {


  baseUrl = environment.baseUrl;
  defaultImage = environment.defaultImage;
  errorImage = environment.errorImage;
  id: any;
  loading = true;
  loaded = false;
  error = true;
  crop: Crops;
  pestsDiseaseWeeds: PestsDiseaseWeed[];
  toxicAgrochems: Agrochem[];
  nonToxicAgrochems: Agrochem[];
  commercialOrganicPdw: PestsDiseaseWeed[];
  commercialOrganicTotal = 0;
  gapPdw: PestsDiseaseWeed[];
  gapTotal = 0;
  homemadeOrganicPdw: PestsDiseaseWeed[];
  homemadeOrganicTotal = 0;

  constructor(
    private titleService: Title,
    private route: ActivatedRoute,
    private cropsService: CropsService,
    public messageService: MessageService,
  ) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.params.id;
    this.getAll();
  }

  private getAll() {
    // Get crop item based on ID
    this.cropsService.findById(this.id).subscribe(response => {
        this.crop = response.data;
        this.loading = false;
        this.loaded = true;
        this.error = false;
        this.titleService.setTitle(this.crop.name);
        // Get pestd, diseases. weeds related to the crop
        this.cropsService.findPestsWeedsDiseasesById(this.id).subscribe(responsePdw => {
          this.pestsDiseaseWeeds = responsePdw.data.pests_disease_weed;
        });
        // Get toxic agrochem products related to the crop
        this.cropsService.getAgrochemsByToxicity(this.id, 'true').subscribe(responseToxicAgrochems => {
          this.toxicAgrochems = responseToxicAgrochems.data.agrochem;
        });
        // Get non toxic agrochem products related to the crop
        this.cropsService.getAgrochemsByToxicity(this.id, 'false').subscribe(responseNonToxicAgrochems => {
          this.nonToxicAgrochems = responseNonToxicAgrochems.data.agrochem;
        });

        // Get pest, disease or weed's commercial organic products
        this.cropsService.getCommercialOrganic(this.id).subscribe(responseCommercialOrganic => {
          this.commercialOrganicTotal = responseCommercialOrganic.total;
          this.commercialOrganicPdw = responseCommercialOrganic.data;
        });
        // Get pest, disease or weed's homemade organic products
        this.cropsService.getHomemadeOrganic(this.id).subscribe(responseHomemadeOrganic => {
          this.homemadeOrganicTotal = responseHomemadeOrganic.total;
          this.homemadeOrganicPdw = responseHomemadeOrganic.data;
        });
        // Get pest, disease or weed's GAP items
        this.cropsService.getGap(this.id).subscribe(responseGap => {
          this.gapTotal = responseGap.total;
          this.gapPdw = responseGap.data;
        });
      },
      error => {
        // Handle error when no crop matching the id is found
        this.messageService.showMessage('info', 'Not found', 'Crop not found');
        this.loading = false;
        this.error = true;
      });
  }
}
