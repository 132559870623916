import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'earthwormColor'
})
export class EarthwormColorPipe implements PipeTransform {

  transform(value: number): string {
    if (value < 10){
      return 'red darken-3';
    }else if (value >= 10 && value <= 1000 ){
      return 'yellow darken-2';
    }else if (value > 1000 ){
      return 'green darken-1';
    }
    return ;
  }

}
