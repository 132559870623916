<div>
  <mdb-breadcrumb>
    <mdb-breadcrumb-item routerLink="/" class="blue-text">Home</mdb-breadcrumb-item>
    <mdb-breadcrumb-item class="active">Policy Statement</mdb-breadcrumb-item>
  </mdb-breadcrumb>


  <div class="container">
    <div class="layer-bg-top-left" style="">
      <div class="layer-bg-bottom-right" style="">
        <div class="row mb-1 wow fadeInUp" data-wow-delay="0.3s">
          <div class="col-sm">
            <span class="component-title">
              Policy Statement
            </span>
          </div>
        </div>
        <div class="component-content">
          <div class="row shadow-md wow fadeIn" data-wow-delay="0.6s">
            <div class="col-12">
              <div class="content-1 mb-5 pb-5">

              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>



