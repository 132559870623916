import {AfterContentInit, AfterViewInit, Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MessageService} from '../../shared/messages/message.service';
import {Title} from '@angular/platform-browser';
import {SummaryService} from '../../shared/services/summary.service';



import 'jarallax';
declare var jarallax: any;
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit, AfterContentInit, AfterViewInit {


  carcinogenic = '0';
  mutagenic = '0';
  edc = '0';
  neurotoxic = '0';
  reproduction = '0';

  gapTotal = '0';
  homemadeOrganicTotal = '0';
  commercialOrganicTotal = '0';
  agrochemTotal = '0';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public messageService: MessageService,
    private titleService: Title,
    private summaryService: SummaryService
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle('Agrochemcals & Alternatives Project');
  }

  ngAfterViewInit() {
    jarallax(document.querySelectorAll('.jarallax'), {
      speed: 0.2
    });
  }

  ngAfterContentInit(): void {
    // this.summaryService.getSummaryCountAgrochemByActiveIngredient('carcinogenicity', 'yes').subscribe(response => {
    //     this.carcinogenic = response.total;
    //   },
    //   error => {
    //     // Error handling
    //     this.carcinogenic = '0';
    //   }
    // );
    // this.summaryService.getSummaryCountAgrochemByActiveIngredient('mutagenicity', 'yes').subscribe(response => {
    //     this.mutagenic = response.total;
    //   },
    //   error => {
    //     // Error handling
    //     this.mutagenic = '0';
    //   }
    // );
    // this.summaryService.getSummaryCountAgrochemByActiveIngredient('edc', 'yes').subscribe(response => {
    //     this.edc = response.total;
    //   },
    //   error => {
    //     // Error handling
    //     this.edc = '0';
    //   }
    // );
    // this.summaryService.getSummaryCountAgrochemByActiveIngredient('neurotoxicant', 'yes').subscribe(response => {
    //     this.neurotoxic = response.total;
    //   },
    //   error => {
    //     // Error handling
    //     this.neurotoxic = '0';
    //   }
    // );
    // this.summaryService.getSummaryCountAgrochemByActiveIngredient('reproduction', 'yes').subscribe(response => {
    //     this.reproduction = response.total;
    //   },
    //   error => {
    //     // Error handling
    //     this.reproduction = '0';
    //   }
    // );
    this.summaryService.getSummaryCountGap().subscribe(response => {
        this.gapTotal = response.total;
      },
      error => {
        // Error handling
        this.gapTotal = '0';
      }
    );
    this.summaryService.getSummaryCountHomemadeOrganic().subscribe(response => {
        this.homemadeOrganicTotal = response.total;
      },
      error => {
        // Error handling
        this.homemadeOrganicTotal = '0';
      }
    );
    this.summaryService.getSummaryCountCommercialOrganic().subscribe(response => {
        this.commercialOrganicTotal = response.total;
      },
      error => {
        // Error handling
        this.commercialOrganicTotal = '0';
      }
    );
    this.summaryService.getSummaryCountAgrochem().subscribe(response => {
        this.agrochemTotal = response.total;
      },
      error => {
        // Error handling
        this.agrochemTotal = '0';
      }
    );
  }



}
