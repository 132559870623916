import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'leachabilityColor'
})
export class LeachabilityColorPipe implements PipeTransform {

  transform(value: number): string {
    if (value > 2.8){
      return 'red darken-3';
    }else if (value >= 1.8 && value <= 2.8 ){
      return 'yellow darken-2';
    }else if (value < 1.8 ){
      return 'green darken-1';
    }
    return ;
  }

}
