<mat-toolbar  color=""  class="fixed-header">
  <mat-toolbar-row>
    <span class="fill-remaining-space"></span>

    <span class="mini-menu-button" routerLink="about-us">About</span>
    <span class="mini-menu-button" routerLink="how-to">How to use</span>
    <span class="mini-menu-button" [matMenuTriggerFor]="menu" aria-label="Resources">
      Resources
    </span>
    <mat-menu #menu="matMenu">
      <span mat-menu-item>
         <a class="dropdown-item waves-light" mdbWavesEffect routerLink="/media">Media</a>
<!--        <span class="mini-menu-button">Media</span>-->
      </span>
      <span mat-menu-item>
         <a class="dropdown-item waves-light" mdbWavesEffect routerLink="/downloads">Downloads</a>
<!--        <span class="mini-menu-button">Downloads</span>-->
      </span>
    </mat-menu>
    <span class="mini-menu-button"><a  href="https://www.koan.co.ke/" target="_blank">KOAN</a></span>
    <span class="mini-menu-button"><a  href="https://ke.boell.org/" target="_blank">HBS</a></span>


  </mat-toolbar-row>
</mat-toolbar>


<mdb-navbar SideClass="navbar navbar-expand-lg navbar-light  rgba-white-slight" [containerInside]="false">
  <mdb-navbar-brand>
    <a class="logo navbar-brand" href="#"><span><img src="../assets/koan-LOGO.png"  alt="Logo" style="width:80px;"/></span></a>
  </mdb-navbar-brand>
  <links>
    <ul class="navbar-nav mr-auto main-menu">
      <li class="nav-item waves-light" mdbWavesEffect>
        <a class="nav-link" routerLink="/">Home <span class="sr-only">(current)</span></a>
      </li>
      <li class="nav-item dropdown" dropdown>
        <a dropdownToggle mdbWavesEffect type="button" class="nav-link pests dropdown-toggle waves-light" matTooltip="Common Pests, Diseases and Weeds">
          Pests<span class="caret"></span></a>
        <div *dropdownMenu class="dropdown-menu dropdown dropdown-primary" role="menu">
          <a class="dropdown-item waves-light" mdbWavesEffect routerLink="/pdw">All</a>
          <a class="dropdown-item waves-light" mdbWavesEffect routerLink="/pdw/type/pest">Common Pests</a>
          <a class="dropdown-item waves-light" mdbWavesEffect routerLink="/pdw/type/disease">Common Diseases</a>
          <a class="dropdown-item waves-light" mdbWavesEffect routerLink="/pdw/type/weed">Common Weeds</a>
        </div>
      </li>
      <li class="nav-item dropdown" dropdown>
        <a dropdownToggle mdbWavesEffect type="button" class="nav-link gap dropdown-toggle waves-light" matTooltip="Good Agricultural Practices">
          GAP<span class="caret"></span></a>
        <div *dropdownMenu class="dropdown-menu dropdown dropdown-primary" role="menu">
          <a class="dropdown-item waves-light" mdbWavesEffect routerLink="/gap">All</a>
          <a class="dropdown-item waves-light" mdbWavesEffect routerLink="/gap/category/cultural">Cultural</a>
          <a class="dropdown-item waves-light" mdbWavesEffect routerLink="/gap/category/physical">Physical</a>
          <a class="dropdown-item waves-light" mdbWavesEffect routerLink="/gap/category/prevention">Prevention</a>
        </div>
      </li>
      <li class="nav-item waves-light" mdbWavesEffect>
        <a class="nav-link homemade-organic" routerLink="/homemade-organic">Homemade Solutions</a>
      </li>
      <li class="nav-item dropdown" dropdown>
        <a dropdownToggle mdbWavesEffect type="button" class="nav-link control-methods dropdown-toggle waves-light">
          Organic Control Methods<span class="caret"></span></a>
        <div *dropdownMenu class="dropdown-menu dropdown dropdown-primary" role="menu">
          <a class="dropdown-item waves-light" mdbWavesEffect routerLink="/control-methods">All</a>
          <a class="dropdown-item waves-light biocontrol" mdbWavesEffect routerLink="/control-methods/biocontrol">Biocontrol</a>
          <a class="dropdown-item waves-light biopesticide" mdbWavesEffect routerLink="/control-methods/biopesticide">Biopesticide</a>
        </div>
      </li>
      <li class="nav-item waves-light" mdbWavesEffect>
        <a class="nav-link commercial-organic" routerLink="/commercial-organic">Offshelf Organic</a>
      </li>


<!--      <li class="nav-item dropdown" dropdown>-->
<!--        <a dropdownToggle mdbWavesEffect type="button" class="nav-link dropdown-toggle waves-light" matTooltip="Agrochem Producte">-->
<!--          Agrochem Products<span class="caret"></span></a>-->
<!--        <div *dropdownMenu class="dropdown-menu dropdown dropdown-primary" role="menu">-->
<!--          <a class="dropdown-item waves-light" routerLink="/agrochem">All</a>-->
<!--          <a class="dropdown-item waves-light non-toxic-agrochem" matTooltip="Less toxic agrochemicals" routerLink="/agrochem/nontoxic">Less Toxic Agrochemicals</a>-->
<!--          <a class="dropdown-item waves-light toxic-agrochem" matTooltip="Agrochemicals with Toxic Active Ingredients" routerLink="/agrochem/toxic">Agrochemicals</a>-->
<!--        </div>-->
<!--      </li>-->


<!--      <li class="nav-item waves-light" mdbWavesEffect>-->
<!--        <a class="nav-link non-toxic-agrochem" matTooltip="Less toxic agrochemicals" routerLink="/agrochem/nontoxic">Less Toxic Agrochemicals</a>-->
<!--      </li>-->
<!--      <li class="nav-item waves-light" mdbWavesEffect>-->
<!--        <a class="nav-link toxic-agrochem" matTooltip="Agrochemicals with Toxic Active Ingredients" routerLink="/agrochem/toxic">Agrochemicals</a>-->
<!--      </li>-->
      <li class="nav-item waves-light" mdbWavesEffect>
        <a class="nav-link" routerLink="/active-ingredients">Active Ingredients</a>
      </li>
      <li class="nav-item waves-light" mdbWavesEffect>
        <a class="nav-link" routerLink="/crops">Crops</a>
      </li>
    </ul>

<!--    <ul class="fill-remaining-space"></ul>-->

    <!-- Search form -->
    <div class="form-inline md-form form-sm">
      <mdb-icon fas icon="search" aria-hidden="true"></mdb-icon>
      <input class="form-control form-control-sm ml-3 w-75" type="text" [formControl]="searchValue" (keyup.enter)="searchGlobal()" placeholder="Search" aria-label="Search" mdbInput/>
    </div>

<!--    <ul class="navbar-nav nav-flex-icons">-->
<!--      <li class="nav-item waves-light" mdbWavesEffect>-->
<!--        <a class="nav-link"><mdb-icon fab icon="facebook-f"></mdb-icon></a>-->
<!--      </li>-->
<!--      <li class="nav-item waves-light" mdbWavesEffect>-->
<!--        <a class="nav-link"><mdb-icon fab icon="twitter"></mdb-icon></a>-->
<!--      </li>-->
<!--      <li class="nav-item waves-light" mdbWavesEffect>-->
<!--        <a class="nav-link"><mdb-icon fab icon="instagram"></mdb-icon></a>-->
<!--      </li>-->
<!--    </ul>-->
  </links>
</mdb-navbar>







<!--Navbar-->
<!--<mdb-navbar SideClass="navbar navbar-expand-lg navbar-light">-->

<!--  &lt;!&ndash; Navbar brand &ndash;&gt;-->
<!--    <mdb-navbar-brand>-->
<!--      <a class="logo navbar-brand" href="#"><span><img src="../assets/koan-LOGO.png"  alt="Logo" style="width:50px;"/></span></a>-->
<!--    </mdb-navbar-brand>-->

<!--  &lt;!&ndash; Collapsible content &ndash;&gt;-->
<!--  <links>-->

<!--    <ul class="fill-remaining-space"></ul>-->
<!--    &lt;!&ndash; Links &ndash;&gt;-->
<!--    <ul class="navbar-nav mr-auto">-->
<!--      <li class="nav-item waves-light" mdbWavesEffect>-->
<!--        <a class="nav-link" routerLink="/">Home <span class="sr-only">(current)</span></a>-->
<!--      </li>-->
<!--      <li class="nav-item waves-light" mdbWavesEffect>-->
<!--        <a class="nav-link" routerLink="/pdw/type/pest">Pests, Diseases, Weeds</a>-->
<!--      </li>-->
<!--      <li class="nav-item waves-light" mdbWavesEffect>-->
<!--        <a class="nav-link" routerLink="/agrochem">Agrochem Products</a>-->
<!--      </li>-->
<!--      <li class="nav-item waves-light" mdbWavesEffect>-->
<!--        <a class="nav-link" routerLink="/active-ingredients">Active Ingredients</a>-->
<!--      </li>-->
<!--      <li class="nav-item waves-light" mdbWavesEffect>-->
<!--        <a class="nav-link" routerLink="/commercial-organic">Commercial Organic</a>-->
<!--      </li>-->
<!--      <li class="nav-item waves-light" mdbWavesEffect>-->
<!--        <a class="nav-link"routerLink="/crops">Crops</a>-->
<!--      </li>-->

<!--      &lt;!&ndash; Dropdown &ndash;&gt;-->
<!--      <li class="nav-item dropdown" dropdown>-->
<!--        <a dropdownToggle mdbWavesEffect type="button" class="nav-link dropdown-toggle waves-light" mdbWavesEffect>-->
<!--          Basic dropdown<span class="caret"></span></a>-->
<!--        <div *dropdownMenu class="dropdown-menu dropdown dropdown-primary" role="menu">-->
<!--          <a class="dropdown-item waves-light" mdbWavesEffect href="#">Action</a>-->
<!--          <a class="dropdown-item waves-light" mdbWavesEffect href="#">Another action</a>-->
<!--          <a class="dropdown-item waves-light" mdbWavesEffect href="#">Something else here</a>-->
<!--          <div class="divider dropdown-divider"></div>-->
<!--          <a class="dropdown-item waves-light" mdbWavesEffect href="#">Separated link</a>-->
<!--        </div>-->
<!--      </li>-->

<!--    </ul>-->
<!--    &lt;!&ndash; Links &ndash;&gt;-->

<!--    &lt;!&ndash; Search form &ndash;&gt;-->
<!--&lt;!&ndash;    <form class="form-inline waves-light" mdbWavesEffect>&ndash;&gt;-->
<!--&lt;!&ndash;      <div class="md-form my-0">&ndash;&gt;-->
<!--&lt;!&ndash;        <input class="form-control mr-sm-2" type="text" placeholder="Search">&ndash;&gt;-->
<!--&lt;!&ndash;      </div>&ndash;&gt;-->
<!--&lt;!&ndash;    </form>&ndash;&gt;-->
<!--  </links>-->
<!--  &lt;!&ndash; Collapsible content &ndash;&gt;-->

<!--</mdb-navbar>-->
<!--/.Navbar-->


<app-message></app-message>

<!-- Main -->
<main>
  <div style="min-height:100vh; padding:0; margin:0;">
    <router-outlet></router-outlet>
  </div>
</main>
<!-- /.Main -->





<!-- Footer -->
<footer class="page-footer font-small stylish-color-dark pt-4">

  <!-- Footer Links -->
  <div class="container text-left text-md-left">

    <!-- Grid row -->
    <div class="row">

      <!-- Grid column -->
      <!-- Content -->
      <div class="col-md-4 mx-auto">
        <h5 class="font-weight-bold text-uppercase mt-3 mb-4">Kenya Organic Agriculture Network (KOAN)</h5>
        <div class="media">
          <img src="../assets/koan-LOGO-bw.png" class="align-self-center mr-3" style="width:60px;" alt="Koan Logo Grayscale">
          <div class="media-body">
            <h5 class="mt-0"></h5>
            <p>0728 772 805 | 0731 772 805 <br>
              info@koan.co.ke <br>
              <a href="https://www.koan.co.ke" target="_blank">www.koan.co.ke</a>
            </p>
          </div>
        </div>
      </div>
      <!-- Grid column -->

      <hr class="clearfix w-100 d-md-none">

      <!-- Grid column -->
      <div class="col-md-4 mx-auto">

        <!-- Links -->
        <h5 class="font-weight-bold text-uppercase mt-3 mb-4">Partners</h5>

        <div class="media">
          <img src="../assets/ecotrac.png" class="align-self-center mr-3" style="width:60px;" alt="ECOTRAC Logo Grayscale">
          <div class="media-body">
            <h5 class="mt-0"></h5>
            <p><strong>ECOTRAC Consulting</strong><br>
              <a href="http://www.eco-trac.de/" target="_blank">http://www.eco-trac.de/</a>
            </p>
          </div>
        </div>
        <div class="media">
          <img src="../assets/hbs-bw.png" class="align-self-center mr-3" style="width:60px;" alt="HBS Logo Grayscale">
          <div class="media-body">
            <h5 class="mt-0"></h5>
            <p><strong>Heinrich Boll Foundation</strong><br>
              <a href="https://ke.boell.org/" target="_blank">https://ke.boell.org/</a>
            </p>
          </div>
        </div>
        <div class="media">
          <img src="../assets/rtfi-bw.jpg" class="align-self-center mr-3" style="width:60px;" alt="RTFI Logo Grayscale">
          <div class="media-body">
            <h5 class="mt-0"></h5>
            <p><strong>RoutetoFood Initiative</strong><br>
              <a href="https://www.routetofood.org/" target="_blank">https://www.routetofood.org/</a>
            </p>
          </div>
        </div>
        <br><br>



        <h5 class="font-weight-bold text-uppercase mt-3 mb-4">Information Repositories</h5>

        <div class="media">
          <img src="../assets/infonet-bw.png" class="align-self-center mr-3" style="width:60px;" alt="Infonet Biovision Logo Grayscale">
          <div class="media-body">
            <h5 class="mt-0"></h5>
            <p><strong>Infonet Biovision</strong> <br>
              <a href="https://www.infonet-biovision.org/" target="_blank">https://www.infonet-biovision.org/</a>
            </p>
          </div>
        </div>
        <div class="media">
          <img src="../assets/oisat-bw.jpg" class="align-self-center mr-3" style="width:60px;" alt="OISAT Logo Grayscale">
          <div class="media-body">
            <h5 class="mt-0"></h5>
            <p><strong>OISAT.org</strong> <br>
              <a href="http://www.oisat.org/" target="_blank">http://www.oisat.org/</a>
            </p>
          </div>
        </div>
        <div class="media">
          <img src="../assets/cabi-bw.png" class="align-self-center mr-3" style="width:60px;" alt="CABI Logo Grayscale">
          <div class="media-body">
            <h5 class="mt-0"></h5>
            <p><strong>CABI</strong> <br>
              <a href="https://bioprotectionportal.com/" target="_blank">https://bioprotectionportal.com/</a>
            </p>
          </div>
        </div>

      </div>
      <!-- Grid column -->

      <hr class="clearfix w-100 d-md-none">

      <!-- Grid column -->
      <div class="col-md-4 mx-auto">

        <!-- Links -->
        <h5 class="font-weight-bold text-uppercase mt-3 mb-4">Menu</h5>

        <ul class="list-unstyled">
          <li>
            <a routerLink="/">Home</a>
          </li>
          <li>
            <a routerLink="/pdw/type/pest">Common Pests</a>
          </li>
          <li>
            <a routerLink="/pdw/type/disease">Common Diseases</a>
          </li>
          <li>
            <a routerLink="/pdw/type/weed">Common Weeds</a>
          </li>
          <li>
            <a routerLink="/gap/category/physical">Physical & Mechanical Methods (GAP)</a>
          </li>
          <li>
            <a routerLink="/gap/category/cultural">Cultural Methods(GAP)</a>
          </li>
          <li>
            <a routerLink="/gap/category/prevention">Prevention Methods (GAP)</a>
          </li>
          <li>
            <a routerLink="/homemade-organic">Homemade Solutions</a>
          </li>
          <li>
            <a routerLink="/commercial-organic">Offshelf Organic</a>
          </li>
          <li>
            <a routerLink="/control-methods/biopesticide">Biopesticide Control Methods</a>
          </li>
          <li>
            <a routerLink="/control-methods/biocontrol">Biocontrol Control Methods</a>
          </li>
<!--          <li>-->
<!--            <a routerLink="/agrochem/nontoxic">Less Toxic Agrochemicals</a>-->
<!--          </li>-->
<!--          <li>-->
<!--            <a routerLink="/agrochem/toxic">Agrochemicals</a>-->
<!--          </li>-->
          <li>
            <a routerLink="/active-ingredients">Active Ingredients</a>
          </li>
        </ul>

      </div>
      <!-- Grid column -->

      <hr class="clearfix w-100 d-md-none">

    </div>
    <!-- Grid row -->

  </div>
  <!-- Footer Links -->

  <hr>

  <!-- Call to action -->
  <ul class="list-unstyled list-inline text-center py-2">
    <li class="">
      <h5 class="mb-1">Safer Inputs Project</h5>
    </li>
    <li class="list-inline-item">
      <a id="changePreferences" mdbBtn flat="true" size="sm" color="special-color" mdbWavesEffect>Change Privacy Preferences</a>
      <a mdbBtn flat="true" size="sm" color="special-color" mdbWavesEffect routerLink="/disclaimer">Disclaimer</a>
    </li>
  </ul>
  <!-- Call to action -->

  <hr>

  <!-- Social buttons -->
<!--  <ul class="list-unstyled list-inline text-center">-->
<!--    <li class="list-inline-item">-->
<!--      <a mdbBtn floating="true" color="fb" class="mx-1" mdbWavesEffect>-->
<!--        <mdb-icon fab icon="facebook"></mdb-icon>-->
<!--      </a>-->
<!--    </li>-->
<!--    <li class="list-inline-item">-->
<!--      <a mdbBtn floating="true" color="tw" class="mx-1" mdbWavesEffect>-->
<!--        <mdb-icon fab icon="twitter"></mdb-icon>-->
<!--      </a>-->
<!--    </li>-->
<!--    <li class="list-inline-item">-->
<!--      <a mdbBtn floating="true" color="gplus" class="mx-1" mdbWavesEffect>-->
<!--        <mdb-icon fab fab icon="google-plus"></mdb-icon>-->
<!--      </a>-->
<!--    </li>-->
<!--    <li class="list-inline-item">-->
<!--      <a mdbBtn floating="true" color="li" class="mx-1" mdbWavesEffect>-->
<!--        <mdb-icon fab icon="linkedin"></mdb-icon>-->
<!--      </a>-->
<!--    </li>-->
<!--    <li class="list-inline-item">-->
<!--      <a mdbBtn floating="true" color="dribbble" class="mx-1" mdbWavesEffect>-->
<!--        <mdb-icon fab icon="dribbble"></mdb-icon>-->
<!--      </a>-->
<!--    </li>-->
<!--  </ul>-->
  <!-- Social buttons -->

  <!-- Copyright -->
  <div class="footer-copyright text-center py-3">© {{year}} Copyright:
    <a href="https://www.koan.co.ke/" target="_blank">KOAN</a>
  </div>
  <!-- Copyright -->
</footer>
<!-- Footer -->
