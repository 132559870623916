import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'activeIngredientsDescription'
})
export class ActiveIngredientsDescriptionPipe implements PipeTransform {

  transform(value: string): string {
    switch (value) {
      case 'carcinogenicity':
        return 'This is a substance that promotes the formation of cancer.';
      case 'mutagenicity':
        return 'Ability to damage the genetic information within a cell causing mutations. ';
      case 'edc':
        return 'This is a substance that interferes with the normal function of your body’s endocrine/ hormone system. EDCs have been suspected to eb associated with altered reproductive function in men and women, increased incidences of breast cancer, abnormal growth patterns and neurodevelopmental delays in children as well as changes in immune function.';
      case 'reproduction':
        return 'Affecting the reproductive system. Reduces the ability to conceive and bear children.';
      case 'neurotoxicant':
        return 'Poisonous substance that acts on the nervous system and disrupts the normal function of nerve cells.';
      default:
        return '';
    }
  }

}
